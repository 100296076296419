import React from "react";
import "./Input.css";
function Input(props) {
  let inputElement = null;
  let inputClasses = ["input"];
  let textAreaClasses = ["textarea"];
  let selectClasses = ["select"];
  if (props.invalid && props.touched) {
    inputClasses.push("invalid");
    textAreaClasses.push("invalid");
    selectClasses.push("invalid");
  }
  switch (props.elementtype) {
    case "input":
      inputElement = (
        <input
          className={inputClasses.join(" ")}
          onChange={props.changed}
          {...props.elementconfig}
        />
      );

      break;
    case "textarea":
      inputElement = (
        <textarea
          className={textAreaClasses.join(" ")}
          {...props.elementconfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={selectClasses.join(" ")}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementconfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses.join(" ")}
          onChange={props.changed}
          {...props.elementconfig}
        />
      );
      break;
  }
  return <>{inputElement}</>;
}

export default Input;
