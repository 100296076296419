// export const MAHARASHTRA = "Maharashtra";
// export const UTTARPRADESH = "Uttar Pradesh";
// export const stateList = [UTTARPRADESH, MAHARASHTRA].map((item) => ({
//   label: item,
//   value: item,
// }));

// export const stateList = [UTTARPRADESH, MAHARASHTRA].map((item) => ({
//   label: item,
//   value: item,
// }));

//shramik DD
export const Dropdown1 = [
  { value: "Age", label: "Age" },
  { value: "Gender", label: "Gender" },
  { value: "Salary", label: "Salary" },
  { value: "Experience", label: "Experience" },
];

export const Dropdown2 = [
  { value: "Agriculture", label: "Agriculture" },
  { value: "Household", label: "Household" },
  { value: "Industry", label: "Industry" },
  { value: "Mining", label: "Mining" },
];

export const Dropdown3 = [
  {
    value: "Auto and Auto Components",
    link: "Industry",
    label: "Auto and Auto Components",
  },
  {
    value: "Building and Construction",
    link: "Industry",
    label: "Building and Construction",
  },
  {
    value: "Furniture and Furnishing",
    link: "Industry",
    label: "Furniture and Furnishing",
  },
  {
    value: "Gems and Jewellery",
    link: "Industry",
    label: "Gems and Jewellery",
  },
  {
    value: "IT & Computers",
    link: "Industry",
    label: "IT & Computers",
  },
  {
    value: "Leather and Leather Goods",
    link: "Industry",
    label: "Leather and Leather Goods",
  },
  {
    value: "Organized and UnOrganized Retail",
    link: "Industry",
    label: "Organized and UnOrganized Retail",
  },
  {
    value: "Textile and Clothing",
    link: "Industry",
    label: "Textile and Clothing",
  },
  {
    value: "Tourism and Hospitality",
    link: "Industry",
    label: "Tourism and Hospitality",
  },

  {
    value: "Agriculture Industry",
    link: "Agriculture",
    label: "Agriculture Industry",
  },

  { value: "Mining Industry", link: "Mining", label: "Mining Industry" },

  {
    value: "",
    link: "Household",
    label: "",
  },
];

export const Dropdown4 = [
  {
    value: "Powerloom Operator",
    link: "Textile and Clothing",
    label: "Powerloom Operator",
  },
  {
    value: "Apparel Manufacturing",
    link: "Textile and Clothing",
    label: "Apparel Manufacturing",
  },
  {
    value: "Tailoring",
    link: "Textile and Clothing",
    label: "Tailoring",
  },
  {
    value: "Knitwear Manufacturing",
    link: "Textile and Clothing",
    label: "Knitwear Manufacturing",
  },
  {
    value: "Sewing Machine",
    link: "Textile and Clothing",
    label: "Sewing Machine",
  },
  {
    value: "Dyeing and Washing",
    link: "Textile and Clothing",
    label: "Dyeing and Washing",
  },
  {
    value: "Crane Operator",
    link: "Building and Construction",
    label: "Crane Operator",
  },
  {
    value: "Electrician",
    link: "Building and Construction",
    label: "Electrician",
  },
  {
    value: "Welder",
    link: "Building and Construction",
    label: "Welder",
  },
  {
    value: "Mason",
    link: "Building and Construction",
    label: "Mason",
  },
  {
    value: "Carpenter",
    link: "Building and Construction",
    label: "Carpenter",
  },
  {
    value: "Plumber",
    link: "Building and Construction",
    label: "Plumber",
  },
  {
    value: "Tile Worker",
    link: "Building and Construction",
    label: "Tile Worker",
  },
  {
    value: "Porter/Security Guard",
    link: "Building and Construction",
    label: "Porter/Security Guard",
  },
  { value: "Others", link: "Building and Construction", label: "Others" },
  { value: "Driver", link: "Auto and Auto Components", label: "Driver" },
  {
    value: "Auto Component",
    link: "Auto and Auto Components",
    label: "Auto Component",
  },
  { value: "Servicing", link: "Auto and Auto Components", label: "Servicing" },
  {
    value: "Repair",
    link: "Auto and Auto Components",
    label: "Repair",
  },
  {
    value: "Sales",
    link: "Auto and Auto Components",
    label: "Sales",
  },
  {
    value: "Insurance",
    link: "Auto and Auto Components",
    label: "Insurance",
  },
  {
    value: "Porter/Security Guard",
    link: "Auto and Auto Components",
    label: "Porter/Security Guard",
  },
  {
    value: "Shop Floor",
    link: "Organized and UnOrganized Retail",
    label: "Shop Floor",
  },
  {
    value: "Back Store",
    link: "Organized and UnOrganized Retail",
    label: "Back Store",
  },
  {
    value: "Merchandising",
    link: "Organized and UnOrganized Retail",
    label: "Merchandising",
  },
  {
    value: "Porter/Security Guard",
    link: "Organized and UnOrganized Retail",
    label: "Porter/Security Guard",
  },
  {
    value: "Hawker",
    link: "Organized and UnOrganized Retail",
    label: "Hawker",
  },
  {
    value: "Barber",
    link: "Organized and UnOrganized Retail",
    label: "Barber",
  },
  {
    value: "Fabrication",
    link: "Gems and Jewellery",
    label: "Fabrication",
  },
  {
    value: "Polishing",
    link: "Gems and Jewellery",
    label: "Polishing",
  },
  {
    value: "Grading",
    link: "Gems and Jewellery",
    label: "Grading",
  },
  {
    value: "Cutting",
    link: "Gems and Jewellery",
    label: "Cutting",
  },
  {
    value: "Faceting",
    link: "Gems and Jewellery",
    label: "Faceting",
  },

  {
    value: "Electronics Repair",
    link: "IT & Computers",
    label: "Electronics Repair",
  },
  {
    value: "Computer Center",
    link: "IT & Computers",
    label: "Computer Center",
  },
  {
    value: "Sales",
    link: "IT & Computers",
    label: "Sales",
  },
  {
    value: "Hardware Manufacturing Worker",
    link: "IT & Computers",
    label: "Hardware Manufacturing Worker",
  },
  {
    value: "Hardware Networking",
    link: "IT & Computers",
    label: "Hardware Networking",
  },
  {
    value: "Software",
    link: "IT & Computers",
    label: "Software",
  },
  {
    value: "Office Boy/Security Guard",
    link: "IT & Computers",
    label: "Office Boy/Security Guard",
  },
  { value: "Carpenter", link: "Furniture and Furnishing", label: "Carpenter" },
  { value: "Stitching", link: "Furniture and Furnishing", label: "Stitching" },
  { value: "Sewing", link: "Furniture and Furnishing", label: "Sewing" },
  { value: "Stuffing", link: "Furniture and Furnishing", label: "Stuffing" },
  { value: "Porter", link: "Furniture and Furnishing", label: "Porter" },
  { value: "Tanning", link: "Leather and Leather Goods", label: "Tanning" },
  { value: "Cutting", link: "Leather and Leather Goods", label: "Cutting" },
  { value: "Clicking", link: "Leather and Leather Goods", label: "Clicking" },
  { value: "Stitching", link: "Leather and Leather Goods", label: "Stitching" },
  {
    value: "Lasting & Finishing",
    link: "Leather and Leather Goods",
    label: "Lasting & Finishing",
  },
  {
    value: "Porter/Security Guard",
    link: "Leather and Leather Goods",
    label: "Porter/Security Guard",
  },

  {
    value: "Front Office and Waiter",
    link: "Tourism and Hospitality",
    label: "Front Office and Waiter",
  },
  {
    value: "F&B- Cooking",
    link: "Tourism and Hospitality",
    label: "F&B- Cooking",
  },
  {
    value: "Kitchen Duties",
    link: "Tourism and Hospitality",
    label: "Kitchen Duties",
  },
  {
    value: "Housekeeping",
    link: "Tourism and Hospitality",
    label: "Housekeeping",
  },
  { value: "Ticketing", link: "Tourism and Hospitality", label: "Ticketing" },
  { value: "Tour Guide", link: "Tourism and Hospitality", label: "Tour Guide" },
  { value: "Sales", link: "Tourism and Hospitality", label: "Sales" },
  {
    value: "Security Guard",
    link: "Tourism and Hospitality",
    label: "Security Guard",
  },
  {
    value: "Direct Sales Agent",
    link: "Banking and Finance",
    label: "Direct Sales Agent",
  },
  {
    value: "Service- NBFC and MF",
    link: "Banking and Finance",
    label: "Service- NBFC and MF",
  },
  {
    value: "Security Guard",
    link: "Banking and Finance",
    label: "Security Guard",
  },

  {
    value: "Cultivation",
    link: "Agriculture",
    label: "Cultivation",
  },
  {
    value: "Manual Labor",
    link: "Agriculture",
    label: "Manual Labor",
  },
  {
    value: "Livestock rearing",
    link: "Agriculture",
    label: "Livestock Rearing",
  },

  {
    value: "Machine Operator",
    link: "Mining",
    label: "Machine Operator",
  },
  { value: "Laborer", link: "Mining", label: "Laborer" },

  { value: "Maid", link: "Household", label: "Maid" },
  { value: "Cook", link: "Household", label: "Cook" },
  {
    value: "Personal Errands",
    link: "Household",
    label: "Personal Errands",
  },
  { value: "Security", link: "Household", label: "Security" },
  { value: "Nurse", link: "Household", label: "Nurse" },
  { value: "Others", link: "Household", label: "Others" },
];

//industry
export const mapping = [
  { value: "Agriculture", key: 6 },
  { value: "Auto and Auto Components", key: 3 },
  { value: "BFSI", key: 7 },
  { value: "Education and Consulting", key: 12 },
  { value: "Electronics", key: 9 },
  { value: "IT, ITes & Deep Tech", key: 5 },
  { value: "Logistics", key: 11 },
  { value: "Manufacturing- Others", key: 10 },
  { value: "Real Estate and Infra", key: 2 },
  { value: "Retail(All Types)", key: 4 },
  { value: "Textile and Clothing", key: 1 },
  { value: "Tourism, Travel and Hospitality", key: 8 },
  { value: "Others", key: 13 },
];

export const IndustryDD1 = [
  { value: 6, label: "Agriculture" },
  { value: 3, label: "Auto and Auto Components" },
  { value: 7, label: "BFSI" },
  { value: 12, label: "Education and Consulting" },
  { value: 9, label: "Electronics" },
  { value: 5, label: "IT, ITes & Deep Tech" },
  { value: 11, label: "Logistics" },
  { value: 10, label: "Manufacturing- Others" },
  { value: 2, label: "Real Estate and Infra" },
  { value: 4, label: "Retail(All Types)" },
  { value: 1, label: "Textile and Clothing" },
  { value: 8, label: "Tourism, Travel and Hospitality" },
  { value: 13, label: "Others" },
];

export const IndustryDD2 = [
  { value: "Immediate", label: "Immediate" },
  { value: "Next 6 Months", label: "Next 6 Months" },
  { value: ">6 Months", label: ">6 Months" },
];

//skill match & skill gap DD
export const skill = [
  { label: "Tailoring", value: "Tailoring" },
  { label: "Dyeing & Washing", value: "Dyeing & Washing" },
  {
    label: "Machinery Operations- Textiles",
    value: "Machinery Operations- Textiles",
  },
  { label: "Electrical Works", value: "Electrical Works" },
  { label: "Plumbing", value: "Plumbing" },
  { label: "Carpentry", value: "Carpentry" },
  { label: "Driving", value: "Driving" },
  { label: "Repair of Motor Vehicles", value: "Repair of Motor Vehicles" },
  { label: "Trade of Motor Vehicles", value: "Trade of Motor Vehicles" },
  { label: "Hair Cutting", value: "Hair Cutting" },
  { label: "Commission Sales", value: "Commission Sales" },
  { label: "Wholesale Trade", value: "Wholesale Trade" },
  { label: "Retail Sales & Service", value: "Retail Sales & Service" },
  { label: "Gem & Jewellery Work", value: "Gem & Jewellery Work" },
  { label: "Electronics Repair", value: "Electronics Repair" },
  { label: "Hardware Networking", value: "Hardware Networking" },
  { label: "Software & Data Work", value: "Software & Data Work" },
  { label: "Furniture Design", value: "Furniture Design" },
  { label: "Leather Tanning", value: "Leather Tanning" },
  { label: "Leather Works", value: "Leather Works" },
  { label: "Cooking", value: "Cooking" },
  { label: "Housekeeping", value: "Housekeeping" },
  { label: "Cultivation", value: "Cultivation" },
  { label: "Livestock Rearing", value: "Livestock Rearing" },
  {
    label: "Machinery Operations- Mining",
    value: "Machinery Operations- Mining",
  },
  { label: "Manual Labor", value: "Manual Labor" },
  { label: "Caregiving/Nursing", value: "Caregiving/Nursing" },
  { label: "Security and Office Work", value: "Security and Office Work" },
];

//Dropdown styling
export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    borderRadius: "20px",
    border: "0.1px solid black",
    width: state.selectProps.width,
  }),

  control: (base, { isDisabled }) => ({
    ...base,
    fontWeight: "bold",
    background: "whitesmoke",
    borderColor: "whitesmoke",
    borderRadius: "20px",
    overflow: "auto",
    pointerEvents: "auto",
    cursor: isDisabled ? "not-allowed" : "pointer",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: "20px 20px 20px 20px",
    hyphens: "auto",
    marginTop: "0",
    textAlign: "left",
    color: "#20314d",
    overflowY: "auto",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: "bold",
    fontSize: "15px",
    color: "#20314d",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    fontWeight: "bold",
    fontSize: "15px",
    color: "#20314d",
  }),
  menuList: (base) => ({
    ...base,
    background: "whitesmoke",
    borderRadius: "20px",
    padding: 0,
    // overflowY:'auto'
  }),
  option: (provided) => ({
    ...provided,
    borderRadius: "20px",
  }),
};
