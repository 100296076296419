import React from "react";
import "./Table.css";
const Table = ({ data }) => {
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="th1">Name</th>
            <th className="th1">Mobile</th>
            <th className="th1">District</th>
            <th className="th1">State</th>
            <th className="th1">Skill</th>
            <th className="th1">Company</th>
          </tr>
        </thead>
        <tbody>
          {data.map((ele) => (
            <tr className="t-body">
              <th>{ele.Name}</th>
              <th>{ele.Mobile}</th>
              <th>{ele.District}</th>
              <th>{ele.State}</th>
              <th>{ele.Skill}</th>
              <th>{ele.Company}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
