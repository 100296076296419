import React from 'react';
import { useHistory } from "react-router-dom";

import './LoadingSpinner.css';

const LoadingSpinner = props => {
  const history = useHistory();
  return (
    <div>
      <div className="wrapper">
        <div className={`${props.asOverlay && 'loading-spinner__overlay'}`}>
          <div className="lds-dual-ring"></div>
        </div>
      </div>
      {history.location.pathname === '/' ? null :
        <div className="wrapper">
          {props.text}
        </div>
      }
    </div>
  );
};

export default LoadingSpinner;