export const checkValidity = (value, rules, checked, type, name) => {
  let isValid = false;
  let errorMessage = "";
  if (rules.required) {
    isValid = value.trim() !== "";
    errorMessage = `${name} is required!`;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    errorMessage = `${name} should be greater than ${rules.minLength} `;
  }
  if (rules.pattern) {
    isValid = rules.pattern.test(value) === true && isValid;
    errorMessage = `${name} should be valid `;
  }
  if (type === "register" || type === "changePassword") {
    if (rules.confirmed) {
      isValid = checked && isValid;
      errorMessage = `${name} should be matched`;
    }
  }
  return { isValid, errorMessage };
};
