import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Select from "react-select";
import csv from "../../assets/fonts/data.xlsx";
import axios from "axios";
import { AllData } from "./data";
import download from "../../assets/img/nouse/icons8-download-32 (1).png";
import Table from "./Table";
import SideDrawer from "../../shared/components/Navbar/SideDrawer/SideDrawer";
import { Paper, Typography } from "@material-ui/core";
import { customStyles } from "../../dropdownConst";
const Dashboard = () => {
  const [state, setState] = useState({
    label: "All States",
    value: "All",
  });
  const [companyData, setCompanyData] = useState({
    label: "All Companies",
    value: "All",
  });
  const [stateData, setStateData] = useState([]);
  const [data, setdata] = useState(AllData);
  const AllState = [
    {
      label: "All States",
      value: "All",
    },
    ...stateData,
  ];
  const company = [
    {
      label: "All Companies",
      value: "All",
    },
    {
      label: "Tata Project",
      value: "Tata Project",
    },
    {
      label: "Fast Despatch logistics",
      value: "Fast Despatch logistics",
    },
    {
      label: "Sodexo",
      value: "Sodexo",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const styles = {
    control: (base) => ({
      ...base,
      width: "200px",
      border: "1px solid black",
      borderRadius: "25px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  useEffect(() => {
    const response = axios
      .post(`https://api.sakshamtifac.org/State`)
      .then((res) => setStateData(res.data));
  }, []);
  console.log(state);

  useEffect(() => {
    if (state.value !== "All" && companyData.value === "All") {
      const res = AllData.filter((ele) => state.value === ele.State);
      console.log(res);
      setdata(res);
    } else if (state.value === "All" && companyData.value !== "All") {
      setdata(AllData.filter((ele) => companyData.value === ele.Company));
    } else if (state.value !== "All" && companyData.value !== "All") {
      setdata(
        AllData.filter(
          (ele) =>
            companyData.value === ele.Company && state.value === ele.State
        )
      );
    } else {
      setdata(AllData);
    }
  }, [state, companyData]);
  console.log(data);
  return (
    <div className="main-container">
      <SideDrawer>
        {/* <div className="Link"></div> */}
        <div className="container">
          <div className="state">
            <Select
              // placeholder="Select State"
              styles={customStyles}
              value={state}
              options={AllState}
              onChange={(e) => setState(e)}
              width="200px"
            />
            {/* <button
            onClick={() => {
              setState({ label: null, value: null });
            }}
          >
            x
          </button> */}
          </div>
          <div className="company">
            <Select
              // placeholder="Select Company"
              styles={customStyles}
              value={companyData}
              options={company}
              onChange={(e) => setCompanyData(e)}
              width="200px"
            />
          </div>
          {/* <div className="kpi">
            <h4>Total Shramik Connected :</h4>
            <h2>{data.length}</h2>
          </div>
          <div className="kpi1">
            <a className="a" href={csv} download="Shramik Data.xlsx">
              Download All Data
            </a>
            <img className="download" src={download} />
          </div> */}
          <div className="kpi">
            <Paper elevation={2} className="paper">
              <Typography variant="h4" align="center">
                {data.length}
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ fontSize: "1.3vw" }}
                noWrap
              >
                Shramiks Connected
              </Typography>
            </Paper>
          </div>
          <div className="kpi">
            <Paper elevation={2} className="paper">
              <Typography
                variant="subtitle1"
                align="center"
                style={{ fontSize: "1.3vw" }}
                noWrap
              >
                <img className="download" src={download} />
              </Typography>
              <Typography
                variant="h4"
                align="center"
                style={{ fontSize: "1.3vw" }}
              >
                <a className="a" href={csv} download="Shramik Data.xlsx">
                  Download All Data
                </a>
              </Typography>
            </Paper>
          </div>
        </div>
        {data.length !== 0 && (
          <div className="AllTable">
            <Table data={data} />
          </div>
        )}
      </SideDrawer>
    </div>
  );
};

export default Dashboard;
