const INITIAL_STATE = {
  selectedCountry: "India",
  selectedState: "",
  selectedCity: "",
  cityList: [],
};

const NavbarSelection = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_COUNTRY":
      return {
        ...state,
        selectedCountry: action.payload,
      };

    case "SET_STATE":
      return {
        ...state,
        selectedState: action.payload,
      };

    case "SET_CITY":
      return {
        ...state,
        selectedCity: action.payload,
      };

    case "SET_CITYLIST":
      return {
        ...state,
        cityList: action.payload,
      };

    case "REMOVE_STATE":
      return {
        ...state,
        selectedState: "",
      };

    case "REMOVE_CITY":
      return {
        ...state,
        selectedCity: "",
      };

    case "REMOVE_CITYLIST":
      return {
        ...state,
        cityList: [],
      };

    case "REMOVE_ALL":
      return {
        ...state,
        selectedCountry: "",
        selectedState: "",
        selectedCity: "",
      };

    default:
      return state;
  }
};

export default NavbarSelection;
