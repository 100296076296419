import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./shared/utils/PrivateRoute";
import Navbar from "./shared/components/Navbar/Navbar";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import "./App.css";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Reports/Dashboard.jsx";
// import JobPostings from "./Pages/JobPostings/JobPostings";
const Shramik = React.lazy(() => import("./Pages/Shramik/Shramik.jsx"));
const Industry = React.lazy(() => import("./Pages/Industry/Industry.jsx"));
const SkillGap = React.lazy(() => import("./Pages/SkillGap/Skillgap.jsx"));
const SkillDevelopment = React.lazy(() =>
  import("./Pages/SkillDevelopment/SkillDevelopment.jsx")
);
const Mis = React.lazy(() => import("./Pages/MIS/Mis.jsx"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="content">
          <Suspense
            fallback={
              <div>
                <LoadingSpinner asOverlay />
              </div>
            }
          >
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute exact path="/mis" component={Mis} />
              <PrivateRoute exact path="/shramik" component={Shramik} />
              <PrivateRoute path="/industry" component={Industry} />
              <PrivateRoute path="/skillgap" component={SkillGap} />
              <PrivateRoute
                path="/skilldevelopment"
                component={SkillDevelopment}
              />
              {/* <PrivateRoute
                path="/job-postings"
                component={JobPostings}
              /> */}
              <PrivateRoute path="/shramiks-connected" component={Dashboard} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
