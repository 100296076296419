import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

import "./Login.css";
import logo from "../../assets/img/header.svg";
import { loginForm } from "../../shared/utils/formInput";
import { checkValidity } from "../../shared/utils/validation";
import Input from "../../shared/components/FormElements/Input/Input";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import { MailOutlineRounded } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { setCountry } from "../../redux/Navbar-Selection/navbarSelection.actions";
import { setUserSession } from "../../shared/utils/Common";

const Login = (props) => {
  const { setCountry } = props;
  const history = useHistory();
  const [form, setForm] = useState(loginForm.loginForm);
  const [formValid, setFormValid] = useState(false);
  const [inputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const inputhandler = (event, indentifier) => {
    const updatedFormData = {
      ...form,
    };
    const formElement = { ...updatedFormData[indentifier] };
    formElement.value = event.target.value;
    formElement.touched = true;
    updatedFormData[indentifier] = formElement;
    let validityChecked = checkValidity(
      formElement.value,
      formElement.validation,
      "",
      "Login",
      formElement.name
    );
    formElement.valid = validityChecked.isValid;
    formElement.error = validityChecked.errorMessage;
    let formValid = true;
    for (let indentifier in updatedFormData) {
      formValid = updatedFormData[indentifier].valid && formValid;
    }
    const updatedUpdatedFormdata = { ...updatedFormData };
    setForm(updatedUpdatedFormdata);
    setFormValid(formValid);
  };
  const submithandler = () => {
    let updatedFormData = { ...form };
    let formData = {};
    for (let elementdata in updatedFormData) {
      formData[elementdata] = updatedFormData[elementdata].value;
    }
    let data = {
      email: formData.email,
      password: formData.password,
    };
    setError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/admin-login`, {
        username: data.email,
        password: data.password,
      })
      .then((res) => {
        setUserSession(res.data, data.email);
        setCountry("India");
        //setNavbarState("India");
        setLoading(false);
        history.push("/mis");
      })
      .catch((err) => {
        console.log(err.response);
        // alert("Invalid creadentials");
        if (err.response.request.status === 404) {
          //alert("Invalid credentials");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid credentials",
            confirmButtonColor: "#20314d",
          });
        }
        setLoading(false);
      });
  };
  let formToArray = [];
  for (let key in form) {
    formToArray.push({
      id: key,
      config: form[key],
    });
  }

  return (
    <>
      {loading && <LoadingSpinner asOverlay />}
      <main className="main-login">
        <div className="centered">
          <div className="center_content">
            <div className="Logo">
              <img
                src={logo}
                alt="Logo"
                style={{
                  marginBottom: "40px",
                  width: "750px",
                  height: "190px",
                }}
              />
            </div>
            <div className="container login-container">
              <div className="col ">
                <div className="col-md-12">
                  <p className="title-1">Saksham</p>
                  <p className="sub-heading">
                    Welcome back! Please sign in to your account
                  </p>

                  <div
                    className="form-container "
                    onKeyPress={(e) => {
                      e.key === "Enter" && submithandler();
                    }}
                  >
                    {formToArray.map((element, index) => (
                      <div className="flex-login" key={index}>
                        {element.id === "email" ? (
                          <span className="flex-icon">
                            <MailOutlineRounded
                              style={{ fill: "darkslategray" }}
                            />
                          </span>
                        ) : (
                          <span className="flex-icon">
                            <LockOutlinedIcon
                              style={{ fill: "darkslategray" }}
                            />
                          </span>
                        )}
                        <Input
                          key={element.id}
                          elementtype={element.config.elementType}
                          elementconfig={element.config.elementConfig}
                          reference={(input) => (inputs[element.id] = input)}
                          value={element.config.value}
                          invalid={!element.config.valid}
                          touched={element.config.touched}
                          error={element.config.error}
                          name={element.config.name}
                          changed={(event) => inputhandler(event, element.id)}
                        />
                      </div>
                    ))}
                    {error && (
                      <>
                        <small style={{ color: "red" }}>{error}</small>
                        <br />
                      </>
                    )}
                    <br />
                    {!loading && (
                      <button
                        className="primary-button box-shadow-effect login-button pointer"
                        disabled={!formValid}
                        value={loading ? "Loading..." : "Login"}
                        onClick={() => submithandler()}
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCountry: (value) => dispatch(setCountry(value)),
});

export default React.memo(connect(null, mapDispatchToProps)(Login));
