export const AllData = 
  [
 {
  "District": "Kamrup",
  "Name": "sofiqul islam",
  "Mobile": 917005285311,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul khalek sk",
  "Mobile": 917002797924,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul malek",
  "Mobile": 919706799098,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "abhijit roy",
  "Mobile": 916900508044,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abidul islam",
  "Mobile": 916003755346,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "abidur ali",
  "Mobile": 918822319462,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ajanta  mandal",
  "Mobile": 916001147722,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ajay kumar ray",
  "Mobile": 919577322539,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "akibur rahman",
  "Mobile": 916003945606,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "amit das",
  "Mobile": 916382073136,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "arnest bodra",
  "Mobile": 919365946853,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "ashok namasudra",
  "Mobile": 919127347838,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "ashraful islam",
  "Mobile": 919101503268,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "NAGAON",
  "Name": "atabur rahman",
  "Mobile": 919864484656,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "benudhar saikia",
  "Mobile": 919101288466,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bhaskar kumar",
  "Mobile": 917663013539,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "bikash sarkar",
  "Mobile": 917002435991,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bimal das",
  "Mobile": 917086624476,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "binay kumar pathak",
  "Mobile": 919957814073,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "biplab sonowal",
  "Mobile": 916001029682,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "bipul boro",
  "Mobile": 918811921990,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KOKRAJHAR",
  "Name": "bipul boro",
  "Mobile": 918822548711,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "biren hazarika",
  "Mobile": 918919730396,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "bitupon kataki",
  "Mobile": 919365540796,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "chandra kanta das",
  "Mobile": 919127542649,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "danez ali",
  "Mobile": 917638027275,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "debajit das",
  "Mobile": 919954659906,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "deben saikia",
  "Mobile": 918805833865,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "dharmendra ray",
  "Mobile": 919797412866,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "dipak",
  "Mobile": 919401593540,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "dipen gogoi",
  "Mobile": 916000361211,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "goldar hussain",
  "Mobile": 919019405559,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "goutam bharali",
  "Mobile": 919706291593,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "guljar",
  "Mobile": 918134806446,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "heremba rajbonshi",
  "Mobile": 917578878017,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "imaradul hoque",
  "Mobile": 919678460017,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "jakir hussain choudhury",
  "Mobile": 916001635657,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jayanta deka",
  "Mobile": 916002602195,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jhonmani kalita",
  "Mobile": 918752979454,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jiaur rahman",
  "Mobile": 917002823209,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "jitaram sonowal",
  "Mobile": 917635983563,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jitu gogoi",
  "Mobile": 919954635674,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jitu moni kalita",
  "Mobile": 919864498989,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "joychandra chutia",
  "Mobile": 916238398304,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jugal kakati",
  "Mobile": 916002104245,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "junab ali paramanik",
  "Mobile": 919678565108,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other",
  "Column9": "`"
 },
 {
  "District": "Tinsukia",
  "Name": "kamal gohain",
  "Mobile": 917896465601,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "kandarpa ray",
  "Mobile": 919864001301,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "karna dhar roy",
  "Mobile": 919863039207,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "khanindra chelleng",
  "Mobile": 917086966886,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "krihna kanta panging",
  "Mobile": 917204557580,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "luknath  gorh",
  "Mobile": 918011281907,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "mahadev das",
  "Mobile": 919365910029,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "malek uddin sk",
  "Mobile": 917896438056,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "manik sarkar",
  "Mobile": 919957547919,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "manoj prasad",
  "Mobile": 919119859415,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "merajulali",
  "Mobile": 917085780622,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "midu das",
  "Mobile": 918135060686,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "mogbul hussain",
  "Mobile": 918837279286,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "mojakkir ali",
  "Mobile": 917086644294,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "mukul gogoi",
  "Mobile": 919864159696,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "nakul das",
  "Mobile": 9101435090,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "nipen nath",
  "Mobile": 918471996179,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "nitu gohain",
  "Mobile": 916001829719,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "nobin",
  "Mobile": 919365630791,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "ok",
  "Mobile": 919613031559,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "pankaj roy",
  "Mobile": 917399426331,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "papukonwar",
  "Mobile": 916002403065,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "paresh das",
  "Mobile": 917002204752,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "pradip borah",
  "Mobile": 919707272683,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "pradip lalung",
  "Mobile": 916000582708,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "rabindra nath ray",
  "Mobile": 918134090642,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rafik ahmed",
  "Mobile": 919365230035,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rafiqul islam",
  "Mobile": 917638857997,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "raju das",
  "Mobile": 917099180899,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "raju saikia",
  "Mobile": 916002841181,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "ramesh biswakarma",
  "Mobile": 919945224364,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "ranjan nath",
  "Mobile": 917975503433,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjeetlimbu",
  "Mobile": 919790311662,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "ranjit goyary",
  "Mobile": 919324828355,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "ranjit mahato",
  "Mobile": 918812956432,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "reba kumar pegu",
  "Mobile": 919365480554,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rejaul haque",
  "Mobile": 916000732139,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "rintu borah",
  "Mobile": 916001738044,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rituraj neog",
  "Mobile": 916003623819,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "riyajul ali",
  "Mobile": 919613981535,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ruhul amin",
  "Mobile": 917896035531,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "rupali",
  "Mobile": 918404052043,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "saidul",
  "Mobile": 917099441608,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "sanjit barman",
  "Mobile": 916901959763,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sanjit barman",
  "Mobile": 917559041363,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sanowar   hossain",
  "Mobile": 916003012760,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "santosh",
  "Mobile": 917896339363,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "satya jit lahon",
  "Mobile": 919365172140,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shahidur rahman",
  "Mobile": 917002483547,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "shirazul hoque",
  "Mobile": 919707637807,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "shyam chawra",
  "Mobile": 916003249715,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "Name": "abdul ali",
  "Mobile": 916901761475,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sominur rahman",
  "Mobile": 919284976510,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sri horen hazarika",
  "Mobile": 919398705738,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sumi chutia",
  "Mobile": 916901511314,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "tafazzul hoque",
  "Mobile": 918822636138,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "tapan bora",
  "Mobile": 916000989572,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "tapan das",
  "Mobile": 918011403281,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "tilak deka",
  "Mobile": 6000641273,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "toizul islam",
  "Mobile": 916000292847,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "trinayan kachari",
  "Mobile": 917896628061,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "utpal deka",
  "Mobile": 916001561426,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "zakir hussain",
  "Mobile": 918135084383,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "आबुसामा सेख",
  "Mobile": 919365047456,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "इद्रिस अली",
  "Mobile": 918295839038,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "जीबन ज्योति दिहिंगिया",
  "Mobile": 917896589870,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "दूलाल राय",
  "Mobile": 919854810534,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "धन्वाद मेरा नाम रूपधन कुमार है",
  "Mobile": 916000583244,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "समुद्र बोरो",
  "Mobile": 916361409356,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Banka",
  "Name": "dinbandhu kapri",
  "Mobile": 9608897701,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Purnia",
  "Name": "guriya kumari",
  "Mobile": 918102771186,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Aurangabad",
  "Name": "sumit kumar",
  "Mobile": 918809038104,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Patna",
  "Name": "शुभम कुमार",
  "Mobile": 919113316191,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Srinagar",
  "Name": "rajib das",
  "Mobile": 919613148949,
  "State": "Jammu And Kashmir",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baramulla",
  "Name": "sonmoni rabha",
  "Mobile": 6900774196,
  "State": "Jammu And Kashmir",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Vijayapura",
  "Name": "shankar loku rathod",
  "Mobile": 916363967693,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Jalna",
  "Name": "अरुण भीमराव शेळके",
  "Mobile": 917709714778,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "West Garo Hills",
  "Name": "gofur ali",
  "Mobile": 918638727538,
  "State": "Meghalaya",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sikar",
  "Name": "mahendra singh",
  "Mobile": 918135832809,
  "State": "Rajasthan",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jalore",
  "Name": "प्रवीण वैष्णव",
  "Mobile": 919509118650,
  "State": "Rajasthan",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "ARUN KUMAR MISHR",
  "Mobile": 8090918399,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ASHISH KUMAR SINGH",
  "Mobile": 7318377370,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Auraiya",
  "Name": "avinash soni",
  "Mobile": 919634900238,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Ghazipur",
  "Name": "chandrajit rajbhar",
  "Mobile": 918808075996,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "lalit kumar",
  "Mobile": 8057169901,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MUNNA LAL MAURYA",
  "Mobile": 9648501039,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "S R panday",
  "Mobile": 9260971704,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Unnao",
  "Name": "shivam sharma",
  "Mobile": 918275116539,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "banavathu prasada rao",
  "Mobile": 919000968051,
  "State": "Andhra Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "bharothu ramesh",
  "Mobile": 9618044218,
  "State": "Andhra Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "abdul jolil",
  "Mobile": 916001562701,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abdul khaleque ali",
  "Mobile": 919707630446,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abdul mutalib",
  "Mobile": 919577042151,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "abdus salam",
  "Mobile": 916002427328,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "abdus salam",
  "Mobile": 918099545187,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "DHUBRI",
  "Name": "abu taleb sk",
  "Mobile": 916002327175,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abul hussain",
  "Mobile": 919126564696,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "abul kalam azad",
  "Mobile": 9957026911,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "aehsanul karim choudhury",
  "Mobile": 917099992647,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "afjalur rahman",
  "Mobile": 918129501805,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ahashan habib",
  "Mobile": 918471816646,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "ajit kr nath",
  "Mobile": 916000706595,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ajoy rojok",
  "Mobile": 917002654906,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "amanur islam",
  "Mobile": 919101240941,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "anarul islam",
  "Mobile": 919678416668,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "anima gowala",
  "Mobile": 919380802994,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "anjani gogoi borah",
  "Mobile": 917086217397,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "arjun barman",
  "Mobile": 918472924813,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "MARIGAON",
  "Name": "arshad mullah",
  "Mobile": 919707524478,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "ashok kumar nath",
  "Mobile": 919864714938,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "baharul islam",
  "Mobile": 917896881204,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "bappa kar",
  "Mobile": 919863495208,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "bhairab gogoi",
  "Mobile": 919365901191,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "bharat raw",
  "Mobile": 919613226049,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "bhuban lama",
  "Mobile": 919954831694,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "bikash borah",
  "Mobile": 916003777714,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bikash sarkar",
  "Mobile": 919832848167,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "biplu roy",
  "Mobile": 919854947199,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "biswajit mandal",
  "Mobile": 917896014583,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "biswajit of saikia",
  "Mobile": 919859357637,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "bitu sahu",
  "Mobile": 918638229633,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "bitupon mohan",
  "Mobile": 917002482131,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "britenjoy boro",
  "Mobile": 919401910504,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "briz bath lohar",
  "Mobile": 919365282055,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "chautyanya saikia",
  "Mobile": 918822633910,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "chintu gogoi",
  "Mobile": 6381560240,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dadul das",
  "Mobile": 919365773493,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "debajit patar",
  "Mobile": 919037016185,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "dengkw basumatary",
  "Mobile": 919957369477,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "denish kumar boro",
  "Mobile": 917002495665,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "devraj sudan talukdar",
  "Mobile": 918433820538,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "dhaneswar sarania",
  "Mobile": 919606178876,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "dhrubajyoti huzuri",
  "Mobile": 919085772737,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "dibakar brahma",
  "Mobile": 919365015791,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "dibya jyoti boruah",
  "Mobile": 917099430805,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "dimpi begum",
  "Mobile": 917099353619,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "dinku borah",
  "Mobile": 917638815243,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "dipak kr roy",
  "Mobile": 919365037296,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "dipak saikia",
  "Mobile": 919789075148,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "dipika bhuyan",
  "Mobile": 916913615303,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "dipika sonowal",
  "Mobile": 918638336995,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "dokmoka",
  "Mobile": 916001481459,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "gayatri handique",
  "Mobile": 918136093084,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "great britain doley",
  "Mobile": 919365033783,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "gunajit das",
  "Mobile": 919957512044,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "habibur rahman",
  "Mobile": 919864431843,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "hari chandra nath",
  "Mobile": 917638897216,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "haridev sarma",
  "Mobile": 918486745811,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hasen ali",
  "Mobile": 919101875910,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "hii",
  "Mobile": 917994721992,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "hs pass",
  "Mobile": 919101258580,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "humen patir",
  "Mobile": 918939659972,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "ibrahim khalilullah",
  "Mobile": 917002866178,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "indra kanta das",
  "Mobile": 918811820043,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jadu mani kalita",
  "Mobile": 919613257785,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "jairul haque",
  "Mobile": 918638396109,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jakir hussain",
  "Mobile": 919101113807,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "jakir hussain barbhuiya",
  "Mobile": 916001525386,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "jamini saikia",
  "Mobile": 917896211297,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "january",
  "Mobile": 916002201151,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "jerina gogoi",
  "Mobile": 916000706921,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jewal ali ahmed faruquee",
  "Mobile": 916002821450,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jiten das",
  "Mobile": 916003663502,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "jiyaul haque",
  "Mobile": 917628973068,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "joharul islam",
  "Mobile": 918208835626,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "joynal",
  "Mobile": 918925163120,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "junti laskar",
  "Mobile": 919678258448,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jyoti prasad saharia",
  "Mobile": 919365641094,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "kachu basumatary",
  "Mobile": 918011309799,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "kalpana begum",
  "Mobile": 916002566178,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "kamal chandra nath",
  "Mobile": 918486754938,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "karabi kalila",
  "Mobile": 6001031162,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "karishma das",
  "Mobile": 916001373421,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "keshab sarkar",
  "Mobile": 919707269434,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "krishna kalita",
  "Mobile": 918474064396,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "m r barbhuiya",
  "Mobile": 916900341815,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "mahmudul hassan",
  "Mobile": 917399804621,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "manash pratim biswas",
  "Mobile": 917578972263,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "maneswar mazumdar",
  "Mobile": 8638693867,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "manindra",
  "Mobile": 916002054663,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "manjit kumar boro",
  "Mobile": 917002579511,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "manoj ray",
  "Mobile": 919707676665,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "manoranjan kakati",
  "Mobile": 916026263350,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "mantaj ali",
  "Mobile": 917662955873,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "md amir ali",
  "Mobile": 916001373200,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md eftikar hussain",
  "Mobile": 919678799548,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "md jiaul hoque",
  "Mobile": 919101073257,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KOKRAJHAR",
  "Name": "md rofiqul eslam",
  "Mobile": 917002508345,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "menajes swargiary",
  "Mobile": 9864530061,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "miss kamakhya mohanta",
  "Mobile": 918011255460,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "miss porishmita gogoi",
  "Mobile": 916000787730,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "mizanur rahman kazi",
  "Mobile": 918638641512,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "mohesh chungkrang",
  "Mobile": 919740419489,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "mohidul islam",
  "Mobile": 919365962658,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "monalisa saikia",
  "Mobile": 918011906954,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "monowar hussain",
  "Mobile": 919854582745,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "mridul kalita",
  "Mobile": 918472957635,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "mrs deepali baruah",
  "Mobile": 919954629117,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mufazzal khandakar",
  "Mobile": 9365372978,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "mustafijul hoque",
  "Mobile": 916003949075,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "nabajitdas",
  "Mobile": 916302341237,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "nabajyoti sarma",
  "Mobile": 918638920811,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "nilakhi borah",
  "Mobile": 917576946523,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "nural hoque",
  "Mobile": 917002419257,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "nurmohammad",
  "Mobile": 919365152462,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "nurul amin",
  "Mobile": 918812910010,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "pankaj kalita",
  "Mobile": 918724889887,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "pankaj pratim gogoi",
  "Mobile": 917012714209,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "pankaj sharma",
  "Mobile": 918638657339,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "papu moni deka",
  "Mobile": 919365333096,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "parvez musaraf",
  "Mobile": 916002821927,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "poresh chetia",
  "Mobile": 917306377003,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "poresh taye",
  "Mobile": 918256999214,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "pradip borah",
  "Mobile": 917099169074,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "prafulla nath",
  "Mobile": 918638724121,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "pranabjyoti das",
  "Mobile": 917498743061,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "prasanjit bty",
  "Mobile": 918431602922,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "prem kt bora",
  "Mobile": 917896076324,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "priyam chutia",
  "Mobile": 918822408246,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "progya gogoi",
  "Mobile": 919101511861,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "putu das",
  "Mobile": 919957868750,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "rabbul ikram",
  "Mobile": 917002082958,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rabichan mandal",
  "Mobile": 8011059858,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "rabindra",
  "Mobile": 918011777797,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rafiqul islam",
  "Mobile": 919659551715,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "raja",
  "Mobile": 919707120447,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "rajen das",
  "Mobile": 918638080317,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rajita bhuyan",
  "Mobile": 917086128543,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "raju sangrola",
  "Mobile": 919706719672,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "ramjan begum",
  "Mobile": 917002163628,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranjit das",
  "Mobile": 917767856191,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ranjit kumar nath",
  "Mobile": 917578803667,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rashidul islam",
  "Mobile": 917896384496,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rekha das",
  "Mobile": 919706185259,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "riju phukan",
  "Mobile": 916002855835,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rijul ali",
  "Mobile": 917002404491,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rinku ali",
  "Mobile": 918876755287,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "ritupan saikia",
  "Mobile": 918011141005,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "riya",
  "Mobile": 916003261048,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "sachin hondique",
  "Mobile": 916355957307,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "saddam hussain",
  "Mobile": 919957385039,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "saidul islam",
  "Mobile": 919101525136,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "saiful islam",
  "Mobile": 918474003167,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "saiful islam",
  "Mobile": 918822424357,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "salganga",
  "Mobile": 916003053188,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "samson marak",
  "Mobile": 916003318048,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "samudra boruah",
  "Mobile": 6238872954,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sanatan das",
  "Mobile": 918876263487,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "sanidul",
  "Mobile": 917356024537,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sanjib bora",
  "Mobile": 918721927565,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sanjib boro",
  "Mobile": 918638496997,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjib boro",
  "Mobile": 919743197496,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjip chetry",
  "Mobile": 919501568777,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sarat chutia",
  "Mobile": 919954359874,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sarphul iswary",
  "Mobile": 918811897390,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "satyajit basumatary",
  "Mobile": 919957849427,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "satyandra roy",
  "Mobile": 919706526692,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "NAGAON",
  "Name": "shahidul alam laskar",
  "Mobile": 918778489176,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shajamal hoque",
  "Mobile": 919101733699,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "shri sapan gogoi",
  "Mobile": 918638383413,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shyamal",
  "Mobile": 917099497434,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shyamanta bania",
  "Mobile": 918011198667,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sibraj sharma",
  "Mobile": 917002969748,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "siddikun nehar",
  "Mobile": 918822398908,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sofikul islam",
  "Mobile": 917086019087,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "sri anupam rabha",
  "Mobile": 918822024157,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri bitu das",
  "Mobile": 916000303655,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "sri bubu das",
  "Mobile": 919365705952,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sri dhan bahadur lakai",
  "Mobile": 918011476295,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sri hirak kardong",
  "Mobile": 918822954981,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri noren gogoi",
  "Mobile": 919127325919,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri pankaj kumar",
  "Mobile": 919957350198,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "sri pradip gohain",
  "Mobile": 918812052043,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "subhrajit debnath",
  "Mobile": 919954252901,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sumita das",
  "Mobile": 919365109890,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sunil engti",
  "Mobile": 916026421480,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunil saikia",
  "Mobile": 917397406458,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "surajit roy",
  "Mobile": 918486696707,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "swapna biswas",
  "Mobile": 919365229511,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "uday singh",
  "Mobile": 919127389027,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ummehani begum",
  "Mobile": 916001215177,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "wasim akram",
  "Mobile": 919954532890,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "जनता बसूमतारी",
  "Mobile": 8248738216,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "গৌতম নাথ",
  "Mobile": 919864228935,
  "State": "Assam",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Nalanda",
  "Name": "akash kumar",
  "Mobile": 919801440861,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Siwan",
  "Name": "sujit",
  "Mobile": 919801205713,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Gopalganj",
  "Name": "शंभू कुमार",
  "Mobile": 919931219770,
  "State": "Bihar",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Korba",
  "Name": "prashant sinha",
  "Mobile": 917747005771,
  "State": "Chhattisgarh",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Surat",
  "Name": "roshni",
  "Mobile": 9725518675,
  "State": "Gujarat",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bagalkote",
  "Name": "hii",
  "Mobile": 9482855834,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Mandya",
  "Name": "jnanendra hs",
  "Mobile": 917338276516,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Yadgir",
  "Name": "jummappa",
  "Mobile": 919945816943,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "kavya shree g",
  "Mobile": 919380183207,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Raichur",
  "Name": "mahesh",
  "Mobile": 919964691793,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Yadgir",
  "Name": "manisha das",
  "Mobile": 916001183133,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Belagavi",
  "Name": "raibag",
  "Mobile": 918105611381,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bidar",
  "Name": "ramshetty",
  "Mobile": 918125074312,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Ramanagara",
  "Name": "shankaramurthy",
  "Mobile": 919535630012,
  "State": "Karnataka",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "KOLLAM",
  "Name": "haiii",
  "Mobile": 917025507383,
  "State": "Kerala",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "THIRUVANANTHAPURAM",
  "Name": "radhakrishnan k",
  "Mobile": 919605056823,
  "State": "Kerala",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Rewa",
  "Name": "vinod",
  "Mobile": 916268173706,
  "State": "Madhya pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Akola",
  "Name": "bhagwaan namadeo bahakar",
  "Mobile": 919561901555,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Pune",
  "Name": "jahidul",
  "Mobile": 919834331174,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "kishor",
  "Mobile": 917385298299,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dhule",
  "Name": "satish",
  "Mobile": 919175375501,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "yogesh misal",
  "Mobile": 919766337605,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Beed",
  "Name": "संजय भास्कर तांबडे",
  "Mobile": 919637375402,
  "State": "Maharashtra",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "East Jaintia Hills",
  "Name": "leo dkhar",
  "Mobile": 919862600001,
  "State": "Meghalaya",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "North Garo Hills",
  "Name": "mukundo rabha",
  "Mobile": 9101365658,
  "State": "Meghalaya",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Nagaur",
  "Name": "dungar ram",
  "Mobile": 916367017802,
  "State": "Rajasthan",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Bikaner",
  "Name": "omsingh",
  "Mobile": 919680992381,
  "State": "Rajasthan",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Alwar",
  "Name": "rajendra singh",
  "Mobile": 918003987875,
  "State": "Rajasthan",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Virudhunagar",
  "Name": "jeyaganesh",
  "Mobile": 918122647255,
  "State": "Tamil nadu",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dindigul",
  "Name": "kesavadhanabalan",
  "Mobile": 918438160094,
  "State": "Tamil nadu",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Dharmapuri",
  "Name": "rajesh",
  "Mobile": 919092923738,
  "State": "Tamil nadu",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Kanchipuram",
  "Name": "vignesh m",
  "Mobile": 917867076586,
  "State": "Tamil nadu",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Madurai",
  "Name": "vigneshkumar",
  "Mobile": 919487889986,
  "State": "Tamil nadu",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Tiruchirappalli",
  "Name": "vijayakumar",
  "Mobile": 9842329271,
  "State": "Tamil nadu",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "kuree sadhana",
  "Mobile": 918019552278,
  "State": "Telangana",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Nagarkurnool",
  "Name": "shekarsabavath",
  "Mobile": 917815863142,
  "State": "Telangana",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Amroha",
  "Name": "arun kumar",
  "Mobile": 919622447594,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Banda",
  "Name": "arvind kumar",
  "Mobile": 919005065137,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "peer mohammad",
  "Mobile": 917302153109,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ram preet yadav",
  "Mobile": 919993396631,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Uttpal Ujjain Singh",
  "Mobile": 9653185940,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "विजय कुमार",
  "Mobile": 918707820649,
  "State": "Uttar pradesh",
  "Skill": "Mason- Plastering",
  "Company": "Tata Project"
 },
 {
  "District": "Alipurduar",
  "Name": "binoy roy",
  "Mobile": 919883897373,
  "State": "West bengal",
  "Skill": "Mason- Plastering",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "abdul ali",
  "Mobile": 916901761475,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul khalek sk",
  "Mobile": 917002797924,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul malek",
  "Mobile": 919706799098,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abhijit roy",
  "Mobile": 916900508044,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abidul islam",
  "Mobile": 916003755346,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "abidur ali",
  "Mobile": 918822319462,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ajanta  mandal",
  "Mobile": 916001147722,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "ajay kumar ray",
  "Mobile": 919577322539,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "akibur rahman",
  "Mobile": 916003945606,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "amit das",
  "Mobile": 916382073136,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "arnest bodra",
  "Mobile": 919365946853,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "ashok namasudra",
  "Mobile": 919127347838,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ashraful islam",
  "Mobile": 919101503268,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "atabur rahman",
  "Mobile": 919864484656,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "benudhar saikia",
  "Mobile": 919101288466,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "bhaskar kumar",
  "Mobile": 917663013539,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "bikash sarkar",
  "Mobile": 917002435991,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bimal das",
  "Mobile": 917086624476,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "binay kumar pathak",
  "Mobile": 919957814073,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "biplab sonowal",
  "Mobile": 916001029682,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "bipul boro",
  "Mobile": 918811921990,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KOKRAJHAR",
  "Name": "bipul boro",
  "Mobile": 918822548711,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "biren hazarika",
  "Mobile": 918919730396,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "bitupon kataki",
  "Mobile": 919365540796,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "chandra kanta das",
  "Mobile": 919127542649,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "danez ali",
  "Mobile": 917638027275,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "debajit das",
  "Mobile": 919954659906,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "deben saikia",
  "Mobile": 918805833865,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "dharmendra ray",
  "Mobile": 919797412866,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "dipak",
  "Mobile": 919401593540,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "dipen gogoi",
  "Mobile": 916000361211,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "goldar hussain",
  "Mobile": 919019405559,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "goutam bharali",
  "Mobile": 919706291593,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "guljar",
  "Mobile": 918134806446,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "heremba rajbonshi",
  "Mobile": 917578878017,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "imaradul hoque",
  "Mobile": 919678460017,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "jakir hussain choudhury",
  "Mobile": 916001635657,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jayanta deka",
  "Mobile": 916002602195,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jhonmani kalita",
  "Mobile": 918752979454,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jiaur rahman",
  "Mobile": 917002823209,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "jitaram sonowal",
  "Mobile": 917635983563,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jitu gogoi",
  "Mobile": 919954635674,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jitu moni kalita",
  "Mobile": 919864498989,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "joychandra chutia",
  "Mobile": 916238398304,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jugal kakati",
  "Mobile": 916002104245,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "junab ali paramanik",
  "Mobile": 919678565108,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "kamal gohain",
  "Mobile": 917896465601,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "kandarpa ray",
  "Mobile": 919864001301,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "karna dhar roy",
  "Mobile": 919863039207,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "khanindra chelleng",
  "Mobile": 917086966886,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "krihna kanta panging",
  "Mobile": 917204557580,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "luknath  gorh",
  "Mobile": 918011281907,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "mahadev das",
  "Mobile": 919365910029,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "malek uddin sk",
  "Mobile": 917896438056,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "manik sarkar",
  "Mobile": 919957547919,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "manoj prasad",
  "Mobile": 919119859415,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "merajulali",
  "Mobile": 917085780622,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "midu das",
  "Mobile": 918135060686,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "mogbul hussain",
  "Mobile": 918837279286,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "mojakkir ali",
  "Mobile": 917086644294,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "mukul gogoi",
  "Mobile": 919864159696,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "nakul das",
  "Mobile": 9101435090,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "nipen nath",
  "Mobile": 918471996179,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "nitu gohain",
  "Mobile": 916001829719,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "nobin",
  "Mobile": 919365630791,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ok",
  "Mobile": 919613031559,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "pankaj roy",
  "Mobile": 917399426331,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "papukonwar",
  "Mobile": 916002403065,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "paresh das",
  "Mobile": 917002204752,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "pradip borah",
  "Mobile": 919707272683,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "pradip lalung",
  "Mobile": 916000582708,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rabindra nath ray",
  "Mobile": 918134090642,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rafik ahmed",
  "Mobile": 919365230035,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rafiqul islam",
  "Mobile": 917638857997,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "raju das",
  "Mobile": 917099180899,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "raju saikia",
  "Mobile": 916002841181,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "ramesh biswakarma",
  "Mobile": 919945224364,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "ranjan nath",
  "Mobile": 917975503433,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjeetlimbu",
  "Mobile": 919790311662,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "ranjit goyary",
  "Mobile": 919324828355,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "ranjit mahato",
  "Mobile": 918812956432,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "reba kumar pegu",
  "Mobile": 919365480554,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rejaul haque",
  "Mobile": 916000732139,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "rintu borah",
  "Mobile": 916001738044,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rituraj neog",
  "Mobile": 916003623819,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "riyajul ali",
  "Mobile": 919613981535,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ruhul amin",
  "Mobile": 917896035531,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "rupali",
  "Mobile": 918404052043,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "saidul",
  "Mobile": 917099441608,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "sanjit barman",
  "Mobile": 916901959763,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sanjit barman",
  "Mobile": 917559041363,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sanowar   hossain",
  "Mobile": 916003012760,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "santosh",
  "Mobile": 917896339363,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "satya jit lahon",
  "Mobile": 919365172140,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shahidur rahman",
  "Mobile": 917002483547,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "shirazul hoque",
  "Mobile": 919707637807,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "shyam chawra",
  "Mobile": 916003249715,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sofiqul islam",
  "Mobile": 917005285311,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sominur rahman",
  "Mobile": 919284976510,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sri horen hazarika",
  "Mobile": 919398705738,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sumi chutia",
  "Mobile": 916901511314,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "tafazzul hoque",
  "Mobile": 918822636138,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "tapan bora",
  "Mobile": 916000989572,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "tapan das",
  "Mobile": 918011403281,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "tilak deka",
  "Mobile": 6000641273,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "toizul islam",
  "Mobile": 916000292847,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "trinayan kachari",
  "Mobile": 917896628061,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "utpal deka",
  "Mobile": 916001561426,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "zakir hussain",
  "Mobile": 918135084383,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "आबुसामा सेख",
  "Mobile": 919365047456,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "इद्रिस अली",
  "Mobile": 918295839038,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "जीबन ज्योति दिहिंगिया",
  "Mobile": 917896589870,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "दूलाल राय",
  "Mobile": 919854810534,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "धन्वाद मेरा नाम रूपधन कुमार है",
  "Mobile": 916000583244,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "समुद्र बोरो",
  "Mobile": 916361409356,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Banka",
  "Name": "dinbandhu kapri",
  "Mobile": 9608897701,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Purnia",
  "Name": "guriya kumari",
  "Mobile": 918102771186,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Aurangabad",
  "Name": "sumit kumar",
  "Mobile": 918809038104,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Patna",
  "Name": "शुभम कुमार",
  "Mobile": 919113316191,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Srinagar",
  "Name": "rajib das",
  "Mobile": 919613148949,
  "State": "Jammu And Kashmir",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baramulla",
  "Name": "sonmoni rabha",
  "Mobile": 6900774196,
  "State": "Jammu And Kashmir",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Vijayapura",
  "Name": "shankar loku rathod",
  "Mobile": 916363967693,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jalna",
  "Name": "अरुण भीमराव शेळके",
  "Mobile": 917709714778,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "West Garo Hills",
  "Name": "gofur ali",
  "Mobile": 918638727538,
  "State": "Meghalaya",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sikar",
  "Name": "mahendra singh",
  "Mobile": 918135832809,
  "State": "Rajasthan",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jalore",
  "Name": "प्रवीण वैष्णव",
  "Mobile": 919509118650,
  "State": "Rajasthan",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ARUN KUMAR MISHR",
  "Mobile": 8090918399,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ASHISH KUMAR SINGH",
  "Mobile": 7318377370,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Auraiya",
  "Name": "avinash soni",
  "Mobile": 919634900238,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Ghazipur",
  "Name": "chandrajit rajbhar",
  "Mobile": 918808075996,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "lalit kumar",
  "Mobile": 8057169901,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MUNNA LAL MAURYA",
  "Mobile": 9648501039,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "S R panday",
  "Mobile": 9260971704,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Unnao",
  "Name": "shivam sharma",
  "Mobile": 918275116539,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "banavathu prasada rao",
  "Mobile": 919000968051,
  "State": "Andhra Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "bharothu ramesh",
  "Mobile": 9618044218,
  "State": "Andhra Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "abdul jolil",
  "Mobile": 916001562701,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abdul khaleque ali",
  "Mobile": 919707630446,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "abdul mutalib",
  "Mobile": 919577042151,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "abdus salam",
  "Mobile": 916002427328,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "abdus salam",
  "Mobile": 918099545187,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "abu taleb sk",
  "Mobile": 916002327175,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abul hussain",
  "Mobile": 919126564696,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abul kalam azad",
  "Mobile": 9957026911,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "aehsanul karim choudhury",
  "Mobile": 917099992647,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "afjalur rahman",
  "Mobile": 918129501805,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ahashan habib",
  "Mobile": 918471816646,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "ajit kr nath",
  "Mobile": 916000706595,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ajoy rojok",
  "Mobile": 917002654906,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "amanur islam",
  "Mobile": 919101240941,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "anarul islam",
  "Mobile": 919678416668,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "anima gowala",
  "Mobile": 919380802994,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "anjani gogoi borah",
  "Mobile": 917086217397,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "biswajit of saikia",
  "Mobile": 919859357637,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "bitu sahu",
  "Mobile": 918638229633,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "bitupon mohan",
  "Mobile": 917002482131,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "britenjoy boro",
  "Mobile": 919401910504,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "briz bath lohar",
  "Mobile": 919365282055,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "chautyanya saikia",
  "Mobile": 918822633910,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "chintu gogoi",
  "Mobile": 6381560240,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dadul das",
  "Mobile": 919365773493,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "debajit patar",
  "Mobile": 919037016185,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "dengkw basumatary",
  "Mobile": 919957369477,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "denish kumar boro",
  "Mobile": 917002495665,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "devraj sudan talukdar",
  "Mobile": 918433820538,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "dhaneswar sarania",
  "Mobile": 919606178876,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "dhrubajyoti huzuri",
  "Mobile": 919085772737,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "dibakar brahma",
  "Mobile": 919365015791,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "dibya jyoti boruah",
  "Mobile": 917099430805,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "dimpi begum",
  "Mobile": 917099353619,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "dinku borah",
  "Mobile": 917638815243,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "dipak kr roy",
  "Mobile": 919365037296,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "dipak saikia",
  "Mobile": 919789075148,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "dipika bhuyan",
  "Mobile": 916913615303,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "dipika sonowal",
  "Mobile": 918638336995,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "dokmoka",
  "Mobile": 916001481459,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "gayatri handique",
  "Mobile": 918136093084,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "great britain doley",
  "Mobile": 919365033783,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "gunajit das",
  "Mobile": 919957512044,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "habibur rahman",
  "Mobile": 919864431843,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "hari chandra nath",
  "Mobile": 917638897216,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "haridev sarma",
  "Mobile": 918486745811,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "hasen ali",
  "Mobile": 919101875910,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "hii",
  "Mobile": 917994721992,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "hs pass",
  "Mobile": 919101258580,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "humen patir",
  "Mobile": 918939659972,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ibrahim khalilullah",
  "Mobile": 917002866178,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "indra kanta das",
  "Mobile": 918811820043,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jadu mani kalita",
  "Mobile": 919613257785,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "jairul haque",
  "Mobile": 918638396109,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jakir hussain",
  "Mobile": 919101113807,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "jakir hussain barbhuiya",
  "Mobile": 916001525386,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "jamini saikia",
  "Mobile": 917896211297,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "january",
  "Mobile": 916002201151,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jerina gogoi",
  "Mobile": 916000706921,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jewal ali ahmed faruquee",
  "Mobile": 916002821450,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jiten das",
  "Mobile": 916003663502,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "jiyaul haque",
  "Mobile": 917628973068,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "joharul islam",
  "Mobile": 918208835626,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "joynal",
  "Mobile": 918925163120,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "junti laskar",
  "Mobile": 919678258448,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jyoti prasad saharia",
  "Mobile": 919365641094,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "kachu basumatary",
  "Mobile": 918011309799,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "kalpana begum",
  "Mobile": 916002566178,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "kamal chandra nath",
  "Mobile": 918486754938,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "karabi kalila",
  "Mobile": 6001031162,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "karishma das",
  "Mobile": 916001373421,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "keshab sarkar",
  "Mobile": 919707269434,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "krishna kalita",
  "Mobile": 918474064396,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "m r barbhuiya",
  "Mobile": 916900341815,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "mahmudul hassan",
  "Mobile": 917399804621,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "manash pratim biswas",
  "Mobile": 917578972263,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "maneswar mazumdar",
  "Mobile": 8638693867,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "manindra",
  "Mobile": 916002054663,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "manjit kumar boro",
  "Mobile": 917002579511,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "manoj ray",
  "Mobile": 919707676665,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "manoranjan kakati",
  "Mobile": 916026263350,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "mantaj ali",
  "Mobile": 917662955873,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "md amir ali",
  "Mobile": 916001373200,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md eftikar hussain",
  "Mobile": 919678799548,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "md jiaul hoque",
  "Mobile": 919101073257,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KOKRAJHAR",
  "Name": "md rofiqul eslam",
  "Mobile": 917002508345,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "menajes swargiary",
  "Mobile": 9864530061,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "miss kamakhya mohanta",
  "Mobile": 918011255460,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "miss porishmita gogoi",
  "Mobile": 916000787730,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "mizanur rahman kazi",
  "Mobile": 918638641512,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "mohesh chungkrang",
  "Mobile": 919740419489,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "mohidul islam",
  "Mobile": 919365962658,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "monalisa saikia",
  "Mobile": 918011906954,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "monowar hussain",
  "Mobile": 919854582745,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "mridul kalita",
  "Mobile": 918472957635,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "mrs deepali baruah",
  "Mobile": 919954629117,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mufazzal khandakar",
  "Mobile": 9365372978,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "mustafijul hoque",
  "Mobile": 916003949075,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "nabajitdas",
  "Mobile": 916302341237,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "nabajyoti sarma",
  "Mobile": 918638920811,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "nilakhi borah",
  "Mobile": 917576946523,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "nural hoque",
  "Mobile": 917002419257,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "DHUBRI",
  "Name": "nurmohammad",
  "Mobile": 919365152462,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "nurul amin",
  "Mobile": 918812910010,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "pankaj kalita",
  "Mobile": 918724889887,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "pankaj pratim gogoi",
  "Mobile": 917012714209,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "pankaj sharma",
  "Mobile": 918638657339,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "papu moni deka",
  "Mobile": 919365333096,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "parvez musaraf",
  "Mobile": 916002821927,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "poresh chetia",
  "Mobile": 917306377003,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "poresh taye",
  "Mobile": 918256999214,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "pradip borah",
  "Mobile": 917099169074,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "prafulla nath",
  "Mobile": 918638724121,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "pranabjyoti das",
  "Mobile": 917498743061,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "BONGAIGAON",
  "Name": "prasanjit bty",
  "Mobile": 918431602922,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "prem kt bora",
  "Mobile": 917896076324,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "priyam chutia",
  "Mobile": 918822408246,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "progya gogoi",
  "Mobile": 919101511861,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "putu das",
  "Mobile": 919957868750,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "rabbul ikram",
  "Mobile": 917002082958,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rabichan mandal",
  "Mobile": 8011059858,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "rabindra",
  "Mobile": 918011777797,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rafiqul islam",
  "Mobile": 919659551715,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "raja",
  "Mobile": 919707120447,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "rajen das",
  "Mobile": 918638080317,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rajita bhuyan",
  "Mobile": 917086128543,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "raju sangrola",
  "Mobile": 919706719672,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ramjan begum",
  "Mobile": 917002163628,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranjit das",
  "Mobile": 917767856191,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ranjit kumar nath",
  "Mobile": 917578803667,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rashidul islam",
  "Mobile": 917896384496,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rekha das",
  "Mobile": 919706185259,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "riju phukan",
  "Mobile": 916002855835,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rijul ali",
  "Mobile": 917002404491,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rinku ali",
  "Mobile": 918876755287,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "ritupan saikia",
  "Mobile": 918011141005,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "riya",
  "Mobile": 916003261048,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "sachin hondique",
  "Mobile": 916355957307,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "saddam hussain",
  "Mobile": 919957385039,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "saidul islam",
  "Mobile": 919101525136,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "saiful islam",
  "Mobile": 918474003167,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "saiful islam",
  "Mobile": 918822424357,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "CACHAR",
  "Name": "salganga",
  "Mobile": 916003053188,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "samson marak",
  "Mobile": 916003318048,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "samudra boruah",
  "Mobile": 6238872954,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sanatan das",
  "Mobile": 918876263487,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "sanidul",
  "Mobile": 917356024537,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sanjib bora",
  "Mobile": 918721927565,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sanjib boro",
  "Mobile": 918638496997,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjib boro",
  "Mobile": 919743197496,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "Prateek Katkar",
  "Mobile": 8097975903,
  "State": "Maharashtra",
  "Skill": "Computer Operator",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjip chetry",
  "Mobile": 919501568777,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sarat chutia",
  "Mobile": 919954359874,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "sarphul iswary",
  "Mobile": 918811897390,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "satyajit basumatary",
  "Mobile": 919957849427,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "satyandra roy",
  "Mobile": 919706526692,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "shahidul alam laskar",
  "Mobile": 918778489176,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shajamal hoque",
  "Mobile": 919101733699,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "shri sapan gogoi",
  "Mobile": 918638383413,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shyamal",
  "Mobile": 917099497434,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shyamanta bania",
  "Mobile": 918011198667,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "sibraj sharma",
  "Mobile": 917002969748,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "siddikun nehar",
  "Mobile": 918822398908,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sofikul islam",
  "Mobile": 917086019087,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "sri anupam rabha",
  "Mobile": 918822024157,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri bitu das",
  "Mobile": 916000303655,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "sri bubu das",
  "Mobile": 919365705952,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sri dhan bahadur lakai",
  "Mobile": 918011476295,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sri hirak kardong",
  "Mobile": 918822954981,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri noren gogoi",
  "Mobile": 919127325919,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri pankaj kumar",
  "Mobile": 919957350198,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sri pradip gohain",
  "Mobile": 918812052043,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "subhrajit debnath",
  "Mobile": 919954252901,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sumita das",
  "Mobile": 919365109890,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sunil engti",
  "Mobile": 916026421480,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunil saikia",
  "Mobile": 917397406458,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "surajit roy",
  "Mobile": 918486696707,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "swapna biswas",
  "Mobile": 919365229511,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "uday singh",
  "Mobile": 919127389027,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "ummehani begum",
  "Mobile": 916001215177,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "wasim akram",
  "Mobile": 919954532890,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "जनता बसूमतारी",
  "Mobile": 8248738216,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "গৌতম নাথ",
  "Mobile": 919864228935,
  "State": "Assam",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nalanda",
  "Name": "akash kumar",
  "Mobile": 919801440861,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Siwan",
  "Name": "sujit",
  "Mobile": 919801205713,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Gopalganj",
  "Name": "शंभू कुमार",
  "Mobile": 919931219770,
  "State": "Bihar",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Korba",
  "Name": "prashant sinha",
  "Mobile": 917747005771,
  "State": "Chhattisgarh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Surat",
  "Name": "roshni",
  "Mobile": 9725518675,
  "State": "Gujarat",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bagalkote",
  "Name": "hii",
  "Mobile": 9482855834,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Mandya",
  "Name": "jnanendra hs",
  "Mobile": 917338276516,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Yadgir",
  "Name": "jummappa",
  "Mobile": 919945816943,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "kavya shree g",
  "Mobile": 919380183207,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Raichur",
  "Name": "mahesh",
  "Mobile": 919964691793,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Yadgir",
  "Name": "manisha das",
  "Mobile": 916001183133,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Belagavi",
  "Name": "raibag",
  "Mobile": 918105611381,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Bidar",
  "Name": "ramshetty",
  "Mobile": 918125074312,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Ramanagara",
  "Name": "shankaramurthy",
  "Mobile": 919535630012,
  "State": "Karnataka",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "KOLLAM",
  "Name": "haiii",
  "Mobile": 917025507383,
  "State": "Kerala",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "THIRUVANANTHAPURAM",
  "Name": "radhakrishnan k",
  "Mobile": 919605056823,
  "State": "Kerala",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Rewa",
  "Name": "vinod",
  "Mobile": 916268173706,
  "State": "Madhya pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Akola",
  "Name": "bhagwaan namadeo bahakar",
  "Mobile": 919561901555,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "jahidul",
  "Mobile": 919834331174,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "kishor",
  "Mobile": 917385298299,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhule",
  "Name": "satish",
  "Mobile": 919175375501,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Other"
 },
 {
  "District": "Pune",
  "Name": "yogesh misal",
  "Mobile": 919766337605,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Beed",
  "Name": "संजय भास्कर तांबडे",
  "Mobile": 919637375402,
  "State": "Maharashtra",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "East Jaintia Hills",
  "Name": "leo dkhar",
  "Mobile": 919862600001,
  "State": "Meghalaya",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "North Garo Hills",
  "Name": "mukundo rabha",
  "Mobile": 9101365658,
  "State": "Meghalaya",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaur",
  "Name": "dungar ram",
  "Mobile": 916367017802,
  "State": "Rajasthan",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Bikaner",
  "Name": "omsingh",
  "Mobile": 919680992381,
  "State": "Rajasthan",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Alwar",
  "Name": "rajendra singh",
  "Mobile": 918003987875,
  "State": "Rajasthan",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Virudhunagar",
  "Name": "jeyaganesh",
  "Mobile": 918122647255,
  "State": "Tamil nadu",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dindigul",
  "Name": "kesavadhanabalan",
  "Mobile": 918438160094,
  "State": "Tamil nadu",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dharmapuri",
  "Name": "rajesh",
  "Mobile": 919092923738,
  "State": "Tamil nadu",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Kanchipuram",
  "Name": "vignesh m",
  "Mobile": 917867076586,
  "State": "Tamil nadu",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Madurai",
  "Name": "vigneshkumar",
  "Mobile": 919487889986,
  "State": "Tamil nadu",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Tiruchirappalli",
  "Name": "vijayakumar",
  "Mobile": 9842329271,
  "State": "Tamil nadu",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "kuree sadhana",
  "Mobile": 918019552278,
  "State": "Telangana",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Nagarkurnool",
  "Name": "shekarsabavath",
  "Mobile": 917815863142,
  "State": "Telangana",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Amroha",
  "Name": "arun kumar",
  "Mobile": 919622447594,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Banda",
  "Name": "arvind kumar",
  "Mobile": 919005065137,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "peer mohammad",
  "Mobile": 917302153109,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ram preet yadav",
  "Mobile": 919993396631,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Uttpal Ujjain Singh",
  "Mobile": 9653185940,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "विजय कुमार",
  "Mobile": 918707820649,
  "State": "Uttar pradesh",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Alipurduar",
  "Name": "binoy roy",
  "Mobile": 919883897373,
  "State": "West bengal",
  "Skill": "Mason- Concrete",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul khalek sk",
  "Mobile": 917002797924,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul malek",
  "Mobile": 919706799098,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abhijit roy",
  "Mobile": 916900508044,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abidul islam",
  "Mobile": 916003755346,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "abidur ali",
  "Mobile": 918822319462,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ajanta  mandal",
  "Mobile": 916001147722,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ajay kumar ray",
  "Mobile": 919577322539,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "akibur rahman",
  "Mobile": 916003945606,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "arnest bodra",
  "Mobile": 919365946853,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "ashok namasudra",
  "Mobile": 919127347838,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ashraful islam",
  "Mobile": 919101503268,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "atabur rahman",
  "Mobile": 919864484656,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "benudhar saikia",
  "Mobile": 919101288466,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bhaskar kumar",
  "Mobile": 917663013539,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "bikash sarkar",
  "Mobile": 917002435991,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bimal das",
  "Mobile": 917086624476,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "biplab sonowal",
  "Mobile": 916001029682,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "biren hazarika",
  "Mobile": 918919730396,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "bitupon kataki",
  "Mobile": 919365540796,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "danez ali",
  "Mobile": 917638027275,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "deben saikia",
  "Mobile": 918805833865,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "dipak",
  "Mobile": 919401593540,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "dipen gogoi",
  "Mobile": 916000361211,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "goldar hussain",
  "Mobile": 919019405559,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "guljar",
  "Mobile": 918134806446,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "heremba rajbonshi",
  "Mobile": 917578878017,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "imaradul hoque",
  "Mobile": 919678460017,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "jakir hussain choudhury",
  "Mobile": 916001635657,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "jayanta deka",
  "Mobile": 916002602195,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jhonmani kalita",
  "Mobile": 918752979454,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jiaur rahman",
  "Mobile": 917002823209,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "jitaram sonowal",
  "Mobile": 917635983563,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jitu gogoi",
  "Mobile": 919954635674,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "joychandra chutia",
  "Mobile": 916238398304,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "junab ali paramanik",
  "Mobile": 919678565108,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "kandarpa ray",
  "Mobile": 919864001301,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "karna dhar roy",
  "Mobile": 919863039207,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "krihna kanta panging",
  "Mobile": 917204557580,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "mahadev das",
  "Mobile": 919365910029,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "malek uddin sk",
  "Mobile": 917896438056,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "manik sarkar",
  "Mobile": 919957547919,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "manoj prasad",
  "Mobile": 919119859415,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "merajulali",
  "Mobile": 917085780622,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "midu das",
  "Mobile": 918135060686,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "mojakkir ali",
  "Mobile": 917086644294,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "mukul gogoi",
  "Mobile": 919864159696,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "nakul das",
  "Mobile": 9101435090,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "nipen nath",
  "Mobile": 918471996179,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "nitu gohain",
  "Mobile": 916001829719,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "nobin",
  "Mobile": 919365630791,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ok",
  "Mobile": 919613031559,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "pankaj roy",
  "Mobile": 917399426331,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "papukonwar",
  "Mobile": 916002403065,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "paresh das",
  "Mobile": 917002204752,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "pradip lalung",
  "Mobile": 916000582708,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rabindra nath ray",
  "Mobile": 918134090642,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rafik ahmed",
  "Mobile": 919365230035,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rafiqul islam",
  "Mobile": 917638857997,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "raju das",
  "Mobile": 917099180899,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "raju saikia",
  "Mobile": 916002841181,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "ramesh biswakarma",
  "Mobile": 919945224364,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "ranjan nath",
  "Mobile": 917975503433,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjeetlimbu",
  "Mobile": 919790311662,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "ranjit goyary",
  "Mobile": 919324828355,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "ranjit mahato",
  "Mobile": 918812956432,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "reba kumar pegu",
  "Mobile": 919365480554,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rejaul haque",
  "Mobile": 916000732139,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "rintu borah",
  "Mobile": 916001738044,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rituraj neog",
  "Mobile": 916003623819,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "riyajul ali",
  "Mobile": 919613981535,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ruhul amin",
  "Mobile": 917896035531,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "rupali",
  "Mobile": 918404052043,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "sanjit barman",
  "Mobile": 916901959763,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "sanjit barman",
  "Mobile": 917559041363,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sanowar   hossain",
  "Mobile": 916003012760,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "santosh",
  "Mobile": 917896339363,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "satya jit lahon",
  "Mobile": 919365172140,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shahidur rahman",
  "Mobile": 917002483547,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "shirazul hoque",
  "Mobile": 919707637807,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "shyam chawra",
  "Mobile": 916003249715,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sofiqul islam",
  "Mobile": 917005285311,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sominur rahman",
  "Mobile": 919284976510,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sri horen hazarika",
  "Mobile": 919398705738,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "tafazzul hoque",
  "Mobile": 918822636138,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "tapan bora",
  "Mobile": 916000989572,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "tapan das",
  "Mobile": 918011403281,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "tilak deka",
  "Mobile": 6000641273,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "toizul islam",
  "Mobile": 916000292847,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "zakir hussain",
  "Mobile": 918135084383,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "जीबन ज्योति दिहिंगिया",
  "Mobile": 917896589870,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "दूलाल राय",
  "Mobile": 919854810534,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "धन्वाद मेरा नाम रूपधन कुमार है",
  "Mobile": 916000583244,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "समुद्र बोरो",
  "Mobile": 916361409356,
  "State": "Assam",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Banka",
  "Name": "dinbandhu kapri",
  "Mobile": 9608897701,
  "State": "Bihar",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Purnia",
  "Name": "guriya kumari",
  "Mobile": 918102771186,
  "State": "Bihar",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Aurangabad",
  "Name": "sumit kumar",
  "Mobile": 918809038104,
  "State": "Bihar",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Patna",
  "Name": "शुभम कुमार",
  "Mobile": 919113316191,
  "State": "Bihar",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Srinagar",
  "Name": "rajib das",
  "Mobile": 919613148949,
  "State": "Jammu And Kashmir",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Vijayapura",
  "Name": "shankar loku rathod",
  "Mobile": 916363967693,
  "State": "Karnataka",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Jalna",
  "Name": "अरुण भीमराव शेळके",
  "Mobile": 917709714778,
  "State": "Maharashtra",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "West Garo Hills",
  "Name": "gofur ali",
  "Mobile": 918638727538,
  "State": "Meghalaya",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Sikar",
  "Name": "mahendra singh",
  "Mobile": 918135832809,
  "State": "Rajasthan",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Jalore",
  "Name": "प्रवीण वैष्णव",
  "Mobile": 919509118650,
  "State": "Rajasthan",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ARUN KUMAR MISHR",
  "Mobile": 8090918399,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ASHISH KUMAR SINGH",
  "Mobile": 7318377370,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Auraiya",
  "Name": "avinash soni",
  "Mobile": 919634900238,
  "State": "Uttar pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "lalit kumar",
  "Mobile": 8057169901,
  "State": "Uttar pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "MUNNA LAL MAURYA",
  "Mobile": 9648501039,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "S R panday",
  "Mobile": 9260971704,
  "State": "Uttar Pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Tata Project"
 },
 {
  "District": "Unnao",
  "Name": "shivam sharma",
  "Mobile": 918275116539,
  "State": "Uttar pradesh",
  "Skill": "Mason- Flooring",
  "Company": "Other"
 },
 {
  "District": "Visakhapatanam",
  "Name": "krishna rao",
  "Mobile": 919010484033,
  "State": "Andhra Pradesh",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Krishna",
  "Name": "u rajeshkumar",
  "Mobile": 919177108496,
  "State": "Andhra Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Prakasam",
  "Name": "venkata kiran chikkudu",
  "Mobile": 917569773041,
  "State": "Andhra Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Upper Subansiri",
  "Name": "ranjan phukan",
  "Mobile": 919612667628,
  "State": "Arunachal Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abdul ajij",
  "Mobile": 916003292219,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul hannan hague",
  "Mobile": 917576823498,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul sajid",
  "Mobile": 919738182242,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "abdul salam sk",
  "Mobile": 917002894796,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "abhijit rajbongshi",
  "Mobile": 916000473305,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abul hussain",
  "Mobile": 917002333320,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ajay borman",
  "Mobile": 916002006289,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "al imran sheikh",
  "Mobile": 918822658126,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "animesh haloi",
  "Mobile": 919577173169,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Ratnagiri",
  "Name": "Vijay Sravan",
  "Mobile": 8108308265,
  "State": "Maharashtra",
  "Skill": "Manufacturing",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "anowar ali",
  "Mobile": 916000807391,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "anowar hussain",
  "Mobile": 916026564765,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ansarul islam",
  "Mobile": 916300623495,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "ashadulla",
  "Mobile": 918638669435,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "ashibul hoque",
  "Mobile": 919101192094,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "asif jaman",
  "Mobile": 9957596702,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "atibar rahman",
  "Mobile": 916001062770,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "aynul mir",
  "Mobile": 919365067877,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "babul baishya",
  "Mobile": 916001632955,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bachan nayak",
  "Mobile": 918348885487,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "banobashi das",
  "Mobile": 916001562804,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "baten ali",
  "Mobile": 916001777631,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bidyut boruah",
  "Mobile": 7896219692,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "bipul senapoti",
  "Mobile": 917569997127,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bitu urang",
  "Mobile": 918825474790,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "dhanjit mandal",
  "Mobile": 919954595329,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "diganta boruah",
  "Mobile": 919085348930,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "dipankar gogoi",
  "Mobile": 918639055525,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dudul moni deka",
  "Mobile": 916002490356,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "fulbar rahman",
  "Mobile": 9678792546,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "gaurab chakraborty",
  "Mobile": 916001890858,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "gautom laskar",
  "Mobile": 916002978430,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "gonesh chetry",
  "Mobile": 919366128431,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "guddu thakur",
  "Mobile": 919706239777,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "imran hussain laskar",
  "Mobile": 916002085572,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jagnnath kumar das",
  "Mobile": 916002181201,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jahurul islam",
  "Mobile": 919957584822,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "jiadur rahman khan",
  "Mobile": 916001825715,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "jiaur islam",
  "Mobile": 919645685178,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jibeswar das",
  "Mobile": 919707445892,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jinnat ali",
  "Mobile": 919101871355,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "keshab ch sarma baruah",
  "Mobile": 919613063803,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "khanjal nath",
  "Mobile": 918360068384,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "kushal kumar ray",
  "Mobile": 918474072247,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakshya gogoi",
  "Mobile": 916000839194,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "liton saha",
  "Mobile": 7086449693,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "manoj chakraborty",
  "Mobile": 917896047810,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "mayan ali",
  "Mobile": 917002042860,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "milan rajbongshi",
  "Mobile": 919613614805,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "mintu",
  "Mobile": 918134012755,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "mizanur rahman",
  "Mobile": 919678209826,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "mohibuddin ansari",
  "Mobile": 919072271009,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "mon mohan koch",
  "Mobile": 6900639302,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "muktar hussain",
  "Mobile": 918471899832,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mukunda das",
  "Mobile": 916000042013,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "my name is manir ali",
  "Mobile": 918822354304,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "nabiur rahman",
  "Mobile": 917002101425,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "nikunja sarkar",
  "Mobile": 919954435495,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "nur jamal",
  "Mobile": 918761803346,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "paban mandal",
  "Mobile": 919085565406,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "palash chetia",
  "Mobile": 918472915334,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "pijush kanti gogoi",
  "Mobile": 918136042408,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "pinku deka",
  "Mobile": 919101581669,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "piyanka",
  "Mobile": 918134993841,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta ch roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta chandra roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rafiqul islam",
  "Mobile": 9022483574,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "rahul gurung",
  "Mobile": 916001093491,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "rajib bordoloi",
  "Mobile": 916238793053,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "rajib mili",
  "Mobile": 919957029050,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "ram krishna malo",
  "Mobile": 918723990010,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjanjyoti gogoi",
  "Mobile": 918134977553,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ranjit kumar das",
  "Mobile": 916002877753,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ritu das",
  "Mobile": 918978076812,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rituparna borah",
  "Mobile": 918011700850,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "rokibul hoque",
  "Mobile": 918134021904,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "sahar",
  "Mobile": 919707532505,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "saidur rahman",
  "Mobile": 916000499025,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saiful",
  "Mobile": 919707358015,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "samar chandra karmakar",
  "Mobile": 916003210372,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "sanidul ahmed",
  "Mobile": 918876055420,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shahibul hoque",
  "Mobile": 917896201650,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shukla biswas",
  "Mobile": 6901103517,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "simanta bora",
  "Mobile": 6000308174,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sir babul biswas",
  "Mobile": 918638669533,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sobidul hoque bilasipara",
  "Mobile": 918876209136,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sudhan",
  "Mobile": 916000082992,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sukla",
  "Mobile": 916901103517,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "surajit talukdar",
  "Mobile": 919101602087,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "surendra sharma",
  "Mobile": 919591098701,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "taizuddin",
  "Mobile": 919365453651,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "thunu gogoi",
  "Mobile": 919360027950,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tulan panging",
  "Mobile": 916002102802,
  "State": "Assam",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Begusarai",
  "Name": "bipin kumar sharma",
  "Mobile": 917280994871,
  "State": "Bihar",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "New Delhi",
  "Name": "akhilesh",
  "Mobile": 7289925395,
  "State": "Delhi",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "saddam saifi",
  "Mobile": 919927456292,
  "State": "Delhi",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Mandi",
  "Name": "tirth ram",
  "Mobile": 919816617658,
  "State": "Himachal pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Ballari",
  "Name": "ali",
  "Mobile": 918553778986,
  "State": "Karnataka",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Uttara Kannada",
  "Name": "eshwar",
  "Mobile": 916362071932,
  "State": "Karnataka",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "jugal das",
  "Mobile": 916362197168,
  "State": "Karnataka",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "ashil k r",
  "Mobile": 919744733785,
  "State": "Kerala",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Malappuram",
  "Name": "bharathan",
  "Mobile": 919747292875,
  "State": "Kerala",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "krishna",
  "Mobile": 918011867325,
  "State": "Kerala",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Washim",
  "Name": "shankarlal",
  "Mobile": 918407970978,
  "State": "Maharashtra",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhule",
  "Name": "किरण भाऊ लोहार",
  "Mobile": 917741885956,
  "State": "Maharashtra",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "महेंद्र देवासी",
  "Mobile": 919373185732,
  "State": "Maharashtra",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Churu",
  "Name": "anil kumar soni",
  "Mobile": 917737735697,
  "State": "Rajasthan",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "KANYAKUMARI",
  "Name": "rajesh",
  "Mobile": 916369037584,
  "State": "Tamil Nadu",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "KANCHIPURAM",
  "Name": "saksham",
  "Mobile": 918883734639,
  "State": "Tamil Nadu",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Karimnagar",
  "Name": "prasad kotagiri",
  "Mobile": 918106102386,
  "State": "Telangana",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "tinku dutta",
  "Mobile": 918498842540,
  "State": "Telangana",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "ajay",
  "Mobile": 919990250020,
  "State": "Uttar pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Azamgarh",
  "Name": "alpesh saroj",
  "Mobile": 918377810548,
  "State": "Uttar pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ANIL KUMAR SINGH",
  "Mobile": 8601315862,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ARJUN RAM",
  "Mobile": 9452268733,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "BASHEER HAIDAR",
  "Mobile": 9026619704,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Dhaneshwar gupta",
  "Mobile": 6393706891,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "mohd javed",
  "Mobile": 9451579719,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Mukund Sharma",
  "Mobile": 8188834521,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "PANNALAL YADAV",
  "Mobile": 9129226399,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rajendra prasad",
  "Mobile": 8601447391,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "raju rathore",
  "Mobile": 917060991075,
  "State": "Uttar pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAKESH GIRI",
  "Mobile": 9450816530,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh singh",
  "Mobile": 9935289443,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh tiwari",
  "Mobile": 9695326169,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramlakhan",
  "Mobile": 9580517757,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramnagina patel",
  "Mobile": 9305125780,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravi Shankar shukla",
  "Mobile": 7839142788,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAVINDRA NATH TIWARI",
  "Mobile": 9336908205,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravish srivastav",
  "Mobile": 7651912751,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Sanjay Gupta",
  "Mobile": 8569865425,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY VISHWAKARMA",
  "Mobile": 9506383322,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJIV SINGH",
  "Mobile": 8115305484,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "shailesh",
  "Mobile": 919561439110,
  "State": "Uttar pradesh",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "shailesh kumar mishra",
  "Mobile": 8756148070,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shubham Pandey",
  "Mobile": 9548675283,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Vineet",
  "Mobile": 9015291893,
  "State": "Uttar Pradesh",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Alipurduar",
  "Name": "bishnu",
  "Mobile": 917477738268,
  "State": "West bengal",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Jalpaiguri",
  "Name": "niranjan sarkar",
  "Mobile": 919064011649,
  "State": "West bengal",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Purulia",
  "Name": "pradip das mahanta",
  "Mobile": 918327005447,
  "State": "West bengal",
  "Skill": "Barbenders",
  "Company": "Other"
 },
 {
  "District": "Coochbehar",
  "Name": "rajeswar barman",
  "Mobile": 9085841235,
  "State": "West bengal",
  "Skill": "Barbenders",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "krishna rao",
  "Mobile": 919010484033,
  "State": "Andhra Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "u rajeshkumar",
  "Mobile": 919177108496,
  "State": "Andhra Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Prakasam",
  "Name": "venkata kiran chikkudu",
  "Mobile": 917569773041,
  "State": "Andhra Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Upper Subansiri",
  "Name": "ranjan phukan",
  "Mobile": 919612667628,
  "State": "Arunachal Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abdul ajij",
  "Mobile": 916003292219,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul hannan hague",
  "Mobile": 917576823498,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul sajid",
  "Mobile": 919738182242,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "abdul salam sk",
  "Mobile": 917002894796,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "abhijit rajbongshi",
  "Mobile": 916000473305,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "abul hussain",
  "Mobile": 917002333320,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ajay borman",
  "Mobile": 916002006289,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "al imran sheikh",
  "Mobile": 918822658126,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "ananta saikia",
  "Mobile": 916001630180,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "animesh haloi",
  "Mobile": 919577173169,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anowar ali",
  "Mobile": 916000807391,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "anowar hussain",
  "Mobile": 916026564765,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ansarul islam",
  "Mobile": 916300623495,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "ashadulla",
  "Mobile": 918638669435,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "ashibul hoque",
  "Mobile": 919101192094,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "asif jaman",
  "Mobile": 9957596702,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "atibar rahman",
  "Mobile": 916001062770,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "aynul mir",
  "Mobile": 919365067877,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "babul baishya",
  "Mobile": 916001632955,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "bacha bordoloi",
  "Mobile": 916901482652,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bachan nayak",
  "Mobile": 918348885487,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "banobashi das",
  "Mobile": 916001562804,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "baten ali",
  "Mobile": 916001777631,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bidyut boruah",
  "Mobile": 7896219692,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bijoy das",
  "Mobile": 918876138399,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "bipul senapoti",
  "Mobile": 917569997127,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bitu urang",
  "Mobile": 918825474790,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "chandan gogoi",
  "Mobile": 918011543035,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "debojit chelleng",
  "Mobile": 918427883761,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "dhanjit mandal",
  "Mobile": 919954595329,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "dhorma majhi",
  "Mobile": 916003892459,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "dibya sonowal",
  "Mobile": 919101408913,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "diganta boruah",
  "Mobile": 919085348930,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "dipankar gogoi",
  "Mobile": 918639055525,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dudul moni deka",
  "Mobile": 916002490356,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "fulbar rahman",
  "Mobile": 9678792546,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "gaurab chakraborty",
  "Mobile": 916001890858,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "gautom laskar",
  "Mobile": 916002978430,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "gonesh chetry",
  "Mobile": 919366128431,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "guddu thakur",
  "Mobile": 919706239777,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "hemanta khanikar",
  "Mobile": 919101554415,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "imran hussain laskar",
  "Mobile": 916002085572,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jagnnath kumar das",
  "Mobile": 916002181201,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jahurul islam",
  "Mobile": 919957584822,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "jiadur rahman khan",
  "Mobile": 916001825715,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jiaur islam",
  "Mobile": 919645685178,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jibeswar das",
  "Mobile": 919707445892,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jinnat ali",
  "Mobile": 919101871355,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "john moni kalita",
  "Mobile": 9365988561,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "NALBARI",
  "Name": "jwngsar boro",
  "Mobile": 919954479233,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "keshab ch sarma baruah",
  "Mobile": 919613063803,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "khanjal nath",
  "Mobile": 918360068384,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "kushal kumar ray",
  "Mobile": 918474072247,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakshya gogoi",
  "Mobile": 916000839194,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "liton saha",
  "Mobile": 7086449693,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "manoj chakraborty",
  "Mobile": 917896047810,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "mayan ali",
  "Mobile": 917002042860,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "milan rajbongshi",
  "Mobile": 919613614805,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "mintu",
  "Mobile": 918134012755,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "mizanur rahman",
  "Mobile": 919678209826,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "mohibuddin ansari",
  "Mobile": 919072271009,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "mon mohan koch",
  "Mobile": 6900639302,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "muktar hussain",
  "Mobile": 918471899832,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mukunda das",
  "Mobile": 916000042013,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "my name is manir ali",
  "Mobile": 918822354304,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "nabiur rahman",
  "Mobile": 917002101425,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "nikunja sarkar",
  "Mobile": 919954435495,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "nur jamal",
  "Mobile": 918761803346,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "paban mandal",
  "Mobile": 919085565406,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "palash chetia",
  "Mobile": 918472915334,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "pankaj das",
  "Mobile": 919085210626,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "pijush kanti gogoi",
  "Mobile": 918136042408,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "pinku deka",
  "Mobile": 919101581669,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "piyanka",
  "Mobile": 918134993841,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta ch roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta chandra roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "prodip kr adhikary",
  "Mobile": 919127515878,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "pulak sonowal",
  "Mobile": 919101568405,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rafiqul islam",
  "Mobile": 9022483574,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "rahul das",
  "Mobile": 918638269754,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "rahul gurung",
  "Mobile": 916001093491,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "rajib bordoloi",
  "Mobile": 916238793053,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "rajib mili",
  "Mobile": 919957029050,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rajib saikia",
  "Mobile": 916002330152,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "ram krishna malo",
  "Mobile": 918723990010,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjanjyoti gogoi",
  "Mobile": 918134977553,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ranjit kumar das",
  "Mobile": 916002877753,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ritu das",
  "Mobile": 918978076812,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "rituparna borah",
  "Mobile": 918011700850,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "rohan nayak",
  "Mobile": 918099650411,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rokibul hoque",
  "Mobile": 918134021904,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "sahar",
  "Mobile": 919707532505,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "saidur rahman",
  "Mobile": 916000499025,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saiful",
  "Mobile": 919707358015,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "samar chandra karmakar",
  "Mobile": 916003210372,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sanidul ahmed",
  "Mobile": 918876055420,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shahibul hoque",
  "Mobile": 917896201650,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "shahnaj sultana",
  "Mobile": 917577882069,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "shree dibyojyoti chakraborty",
  "Mobile": 918876991427,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shukla biswas",
  "Mobile": 6901103517,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "simanta bora",
  "Mobile": 6000308174,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sir babul biswas",
  "Mobile": 918638669533,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sobidul hoque bilasipara",
  "Mobile": 918876209136,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "sudhan",
  "Mobile": 916000082992,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sukla",
  "Mobile": 916901103517,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "surajit talukdar",
  "Mobile": 919101602087,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "surendra sharma",
  "Mobile": 919591098701,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "taizuddin",
  "Mobile": 919365453651,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "thunu gogoi",
  "Mobile": 919360027950,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tulan panging",
  "Mobile": 916002102802,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "मनरनजन ब्रह्म",
  "Mobile": 917636839402,
  "State": "Assam",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Begusarai",
  "Name": "bipin kumar sharma",
  "Mobile": 917280994871,
  "State": "Bihar",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Durg",
  "Name": "harish kumar",
  "Mobile": 917697321807,
  "State": "Chhattisgarh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "New Delhi",
  "Name": "akhilesh",
  "Mobile": 7289925395,
  "State": "Delhi",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "saddam saifi",
  "Mobile": 919927456292,
  "State": "Delhi",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Mandi",
  "Name": "tirth ram",
  "Mobile": 919816617658,
  "State": "Himachal pradesh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Ballari",
  "Name": "ali",
  "Mobile": 918553778986,
  "State": "Karnataka",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Uttara Kannada",
  "Name": "eshwar",
  "Mobile": 916362071932,
  "State": "Karnataka",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "jugal das",
  "Mobile": 916362197168,
  "State": "Karnataka",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "ashil k r",
  "Mobile": 919744733785,
  "State": "Kerala",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Malappuram",
  "Name": "bharathan",
  "Mobile": 919747292875,
  "State": "Kerala",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "krishna",
  "Mobile": 918011867325,
  "State": "Kerala",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Nanded",
  "Name": "md akhil",
  "Mobile": 919730506534,
  "State": "Maharashtra",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Washim",
  "Name": "shankarlal",
  "Mobile": 918407970978,
  "State": "Maharashtra",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Dhule",
  "Name": "किरण भाऊ लोहार",
  "Mobile": 917741885956,
  "State": "Maharashtra",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Chandrapur",
  "Name": "चेतन प्रकाश वनकर",
  "Mobile": 919767003354,
  "State": "Maharashtra",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "महेंद्र देवासी",
  "Mobile": 919373185732,
  "State": "Maharashtra",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Firozepur",
  "Name": "ranjeet singh",
  "Mobile": 919463301732,
  "State": "Punjab",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Churu",
  "Name": "anil kumar soni",
  "Mobile": 917737735697,
  "State": "Rajasthan",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Churu",
  "Name": "rohitash",
  "Mobile": 919549674652,
  "State": "Rajasthan",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "KANYAKUMARI",
  "Name": "rajesh",
  "Mobile": 916369037584,
  "State": "Tamil Nadu",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "KANCHIPURAM",
  "Name": "saksham",
  "Mobile": 918883734639,
  "State": "Tamil Nadu",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Karimnagar",
  "Name": "prasad kotagiri",
  "Mobile": 918106102386,
  "State": "Telangana",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "tinku dutta",
  "Mobile": 918498842540,
  "State": "Telangana",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "ajay",
  "Mobile": 919990250020,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Azamgarh",
  "Name": "alpesh saroj",
  "Mobile": 918377810548,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "ANIL KUMAR SINGH",
  "Mobile": 8601315862,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ARJUN RAM",
  "Mobile": 9452268733,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "BASHEER HAIDAR",
  "Mobile": 9026619704,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Dhaneshwar gupta",
  "Mobile": 6393706891,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Saharanpur",
  "Name": "mohan",
  "Mobile": 919627296145,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "mohd javed",
  "Mobile": 9451579719,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Mukund Sharma",
  "Mobile": 8188834521,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "PANNALAL YADAV",
  "Mobile": 9129226399,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rajendra prasad",
  "Mobile": 8601447391,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "raju rathore",
  "Mobile": 917060991075,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAKESH GIRI",
  "Mobile": 9450816530,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh singh",
  "Mobile": 9935289443,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh tiwari",
  "Mobile": 9695326169,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramlakhan",
  "Mobile": 9580517757,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ramnagina patel",
  "Mobile": 9305125780,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ravi Shankar shukla",
  "Mobile": 7839142788,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAVINDRA NATH TIWARI",
  "Mobile": 9336908205,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravish srivastav",
  "Mobile": 7651912751,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Sanjay Gupta",
  "Mobile": 8569865425,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY VISHWAKARMA",
  "Mobile": 9506383322,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJIV SINGH",
  "Mobile": 8115305484,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "shailesh",
  "Mobile": 919561439110,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shailesh kumar mishra",
  "Mobile": 8756148070,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Pratapgarh",
  "Name": "shiv lohar",
  "Mobile": 918081098857,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shubham Pandey",
  "Mobile": 9548675283,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Vineet",
  "Mobile": 9015291893,
  "State": "Uttar Pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Muzaffarnagar",
  "Name": "आकाश",
  "Mobile": 919368395566,
  "State": "Uttar pradesh",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Alipurduar",
  "Name": "bishnu",
  "Mobile": 917477738268,
  "State": "West bengal",
  "Skill": "Carpenters",
  "Company": "Other"
 },
 {
  "District": "Jalpaiguri",
  "Name": "niranjan sarkar",
  "Mobile": 919064011649,
  "State": "West bengal",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Purulia",
  "Name": "pradip das mahanta",
  "Mobile": 918327005447,
  "State": "West bengal",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Coochbehar",
  "Name": "rajeswar barman",
  "Mobile": 9085841235,
  "State": "West bengal",
  "Skill": "Carpenters",
  "Company": "Tata Project"
 },
 {
  "District": "Vizianagaram",
  "Name": "dola kartheek kumar",
  "Mobile": 917729867032,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "ANANTHAPUR",
  "Name": "g veeresh",
  "Mobile": 918897799428,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Y.S.R.",
  "Name": "madhavanarayana",
  "Mobile": 919966924166,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "tandrothu suresh",
  "Mobile": 918096344694,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Namsai",
  "Name": "sunil",
  "Mobile": 918822775647,
  "State": "Arunachal Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "abedur rahman",
  "Mobile": 918822102123,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "abhay das",
  "Mobile": 7576044510,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "abhijit saha",
  "Mobile": 917099994425,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "absadur rahman",
  "Mobile": 919706598027,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "abul kalam azad",
  "Mobile": 919101535691,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "adiya fara",
  "Mobile": 917338925891,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "afiya fara",
  "Mobile": 919957251526,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "aftar hussain",
  "Mobile": 917576921177,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "ajay ray",
  "Mobile": 919127316814,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ajijul hoqe",
  "Mobile": 916000672852,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ajit barman",
  "Mobile": 917002934513,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "akash biswas",
  "Mobile": 918474047104,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ali akbor",
  "Mobile": 918838880738,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "amal basumatary",
  "Mobile": 916282853741,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "amarjyoti bhuyan",
  "Mobile": 918822197208,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "aminul",
  "Mobile": 917002631928,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "amir khan ali",
  "Mobile": 919101688878,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "amor hazarika",
  "Mobile": 918474079590,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anisur rahman",
  "Mobile": 917306244802,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "ankur saikia",
  "Mobile": 8011515058,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "apurba kalita",
  "Mobile": 916002983590,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "arbisan islary",
  "Mobile": 916000143383,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "arman ansari",
  "Mobile": 919613937054,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "arunav buragohain",
  "Mobile": 916000719118,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "ashok kr chao",
  "Mobile": 919678845320,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "assamese teacher",
  "Mobile": 919706966294,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "astam das",
  "Mobile": 917972616287,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "avilash saikia",
  "Mobile": 918721930918,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "aybur khan",
  "Mobile": 6000481343,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "azmal hoque",
  "Mobile": 919954955241,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "basab dey",
  "Mobile": 919707053070,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bhabani",
  "Mobile": 919707186964,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bhaben deka",
  "Mobile": 919365023510,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "bhagaban barman",
  "Mobile": 918135978266,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "bharat saikia",
  "Mobile": 916001620233,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bhaskar das",
  "Mobile": 917002939122,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "bhaskar jyoti borah",
  "Mobile": 916003997450,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bhaskar sharma",
  "Mobile": 917002918059,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "bhupenchdeka",
  "Mobile": 917635973539,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bibek",
  "Mobile": 919365468438,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "bibek majumdar",
  "Mobile": 9957861149,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "bijit singh",
  "Mobile": 917002566536,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bikash das",
  "Mobile": 917002293183,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "bikash ranjan gogoi",
  "Mobile": 917636982275,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "binod das",
  "Mobile": 919080695968,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "bipulbordoloi",
  "Mobile": 919864943978,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "bishnu borah",
  "Mobile": 917086377408,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "biswajit baishya",
  "Mobile": 919101882213,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "biswajit das",
  "Mobile": 917578832155,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bitupan gohain",
  "Mobile": 916002976497,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bogadhor sonowal",
  "Mobile": 917099865370,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "buban das",
  "Mobile": 918402924092,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "chandan dutta",
  "Mobile": 919101616820,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "chandan roy",
  "Mobile": 919707095829,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "debajit gogoi",
  "Mobile": 918134981086,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "denhasar boro",
  "Mobile": 918011051459,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "dergaon",
  "Mobile": 918011040423,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "dhiraj gogoi",
  "Mobile": 917002013273,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "digas",
  "Mobile": 916000625158,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dildar hussain",
  "Mobile": 916002090518,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dildar sharif",
  "Mobile": 919365101606,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "dilip dash",
  "Mobile": 919365435008,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "dipak bora",
  "Mobile": 919365862825,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "dipak prasad gupta",
  "Mobile": 918876041776,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "dipankar das",
  "Mobile": 918822257297,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dipu saikia",
  "Mobile": 919101995100,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "dipurajak",
  "Mobile": 917896165929,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "durlabh sahu",
  "Mobile": 916003844593,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "dwipjyoti kalita",
  "Mobile": 918638219338,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "farukh ali",
  "Mobile": 919365591115,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "fatik das",
  "Mobile": 918638986606,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "fulu lama laskar",
  "Mobile": 918876963669,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "gajen hazarika",
  "Mobile": 916900526670,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "gajiur rahman",
  "Mobile": 917635941193,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "gautam baruah",
  "Mobile": 916000206323,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "gautam chakraborty",
  "Mobile": 917637058377,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "giyas uddin",
  "Mobile": 7907308623,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "gokul das",
  "Mobile": 919365450502,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "govine",
  "Mobile": 918076263125,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "hajarat ali",
  "Mobile": 916000065884,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "hakimul islam",
  "Mobile": 917662811407,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "hemen das",
  "Mobile": 918638479546,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "hemolata sonowal",
  "Mobile": 8404092195,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "hirakjyoti das",
  "Mobile": 917002126730,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "hiranya handique",
  "Mobile": 917896698880,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "hiroik",
  "Mobile": 918073455122,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "ibrahim ali",
  "Mobile": 918486574915,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ibrahim ali",
  "Mobile": 918794317263,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "ikramul",
  "Mobile": 917429607656,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "iman ali",
  "Mobile": 919074261511,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "imran hussain",
  "Mobile": 6002403593,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "imran hussain",
  "Mobile": 918812858595,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "islam uddin laskar",
  "Mobile": 916003264788,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "jadu mech",
  "Mobile": 918638256561,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "jadumoni gowala",
  "Mobile": 918134027917,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "jagadish borah",
  "Mobile": 918754930990,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "jainal abedin",
  "Mobile": 916002045321,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jakir ali",
  "Mobile": 6000614434,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jakir hussain",
  "Mobile": 918812950346,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "jay chandra nath",
  "Mobile": 918137092461,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "jayanta haloi",
  "Mobile": 917002191453,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "jeherul islam",
  "Mobile": 916001569891,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "jishnu borgohain",
  "Mobile": 917638070980,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "jitamoni konwar",
  "Mobile": 917896575966,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jiten saikia",
  "Mobile": 919365467062,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "jiyaruddin ahmed",
  "Mobile": 8474848150,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "johir ahmed",
  "Mobile": 918286985669,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "jorhat",
  "Mobile": 919365604153,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "junu sanjay barman",
  "Mobile": 919974390354,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "kanta malakar",
  "Mobile": 918073404526,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "kanu nag",
  "Mobile": 8259068646,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "khanindra barman",
  "Mobile": 9101127618,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "kishor dutta",
  "Mobile": 7002133581,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "kitna bar blna prega",
  "Mobile": 917002190373,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "krishna roy",
  "Mobile": 919864947926,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "kunja mohan das",
  "Mobile": 919435454961,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "kunjan das",
  "Mobile": 919609549895,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "lachit hajong",
  "Mobile": 918891126939,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "lakshi kantha rajbongshi",
  "Mobile": 919066655034,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "lutfor rahman",
  "Mobile": 919365103706,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "madhab kalita",
  "Mobile": 918905378784,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mahar  ali",
  "Mobile": 917096532545,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "mahidul islam",
  "Mobile": 918638746573,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "mahijal hoque",
  "Mobile": 916002754180,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "malay biswas",
  "Mobile": 917896687127,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "manash jyoti das",
  "Mobile": 918486531492,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "NALBARI",
  "Name": "manash jyoti deka",
  "Mobile": 919085665808,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "manosh protim borbora",
  "Mobile": 919613920875,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "md abul kalam azad",
  "Mobile": 917896372128,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "md ajajul ahmed",
  "Mobile": 916003275022,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md saiful islam",
  "Mobile": 918822627441,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "michael brahma",
  "Mobile": 6000962317,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "mintu kalita",
  "Mobile": 917896403473,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mofidul islam",
  "Mobile": 918812912147,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "montu dutta",
  "Mobile": 919678380016,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "mostufa ahmed",
  "Mobile": 919101906397,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "moumita nath",
  "Mobile": 917896304334,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "mrigen sarma",
  "Mobile": 918638793075,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "mujibur rahman laskar",
  "Mobile": 919401696531,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "mukut boro",
  "Mobile": 918011799987,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "muzahirul hoque",
  "Mobile": 918056650532,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "my name is himangshu khamon",
  "Mobile": 919365048364,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "namrahul amin",
  "Mobile": 918638731536,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "noor hossain",
  "Mobile": 917002861797,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "nur alom sk",
  "Mobile": 916000546387,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "nur hussain ali",
  "Mobile": 919326479189,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "omed ali",
  "Mobile": 916003404890,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "paban kardong",
  "Mobile": 916000978785,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "paban kumar roy",
  "Mobile": 917577089267,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "padmadhar das",
  "Mobile": 918473959427,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "padum chetry",
  "Mobile": 916304559198,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "paplu gogoi",
  "Mobile": 919080804042,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "parag bhuyan",
  "Mobile": 918812037306,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "parimal das",
  "Mobile": 918638297877,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "pradip talukdar",
  "Mobile": 918928770951,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "pradyut gogoi",
  "Mobile": 919101561954,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "prallab doley",
  "Mobile": 916002355972,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "prasenjit karmakar",
  "Mobile": 918876670753,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "priyam kalita",
  "Mobile": 917399728613,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "priyom protim borah",
  "Mobile": 918638169887,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "probin doley",
  "Mobile": 917002531855,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rafikul ali",
  "Mobile": 919126591701,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "rahul billung",
  "Mobile": 916901085988,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "raiju islam talukder",
  "Mobile": 919706925248,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "rajesh kar",
  "Mobile": 918721831102,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "rajesh malah",
  "Mobile": 918876089306,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "raju buragohain",
  "Mobile": 916003490355,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "rakesh ali",
  "Mobile": 919577777704,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "ranjan das",
  "Mobile": 916001338089,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "ranjib sarmah",
  "Mobile": 917002352033,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "ranjit",
  "Mobile": 919864847602,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "rashiduel",
  "Mobile": 919074117380,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "rayaan das",
  "Mobile": 918011718551,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rezaul hoque",
  "Mobile": 9365508288,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "ritu",
  "Mobile": 919707805071,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "rituraj dowarah",
  "Mobile": 918761973232,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "robi barman",
  "Mobile": 917086807892,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "romesh phukon",
  "Mobile": 918638771358,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "romjan ali sheikh",
  "Mobile": 919707521350,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "roni",
  "Mobile": 916001276861,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Karbi Anglong",
  "Name": "ronjita gour",
  "Mobile": 918472925494,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rubul ali",
  "Mobile": 919101406178,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ruhin gogoi",
  "Mobile": 916002155984,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rupjyoti das",
  "Mobile": 918638570054,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "rupjyoti tamuli",
  "Mobile": 916001788601,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "s",
  "Mobile": 917035463471,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "sabbir azim",
  "Mobile": 919957241334,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saddamhussain",
  "Mobile": 917002938704,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sadikul islam",
  "Mobile": 6854358625,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "sadique ahmed",
  "Mobile": 919864559687,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "safed ali",
  "Mobile": 919764611649,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "saidur rahman",
  "Mobile": 7002121711,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "saidur rahman",
  "Mobile": 918217877033,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "saiful islam",
  "Mobile": 918638756416,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "saifur rahman",
  "Mobile": 8473043011,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "samir sarkar",
  "Mobile": 916001509650,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "samsul alom",
  "Mobile": 919101751230,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sanchita das ghosh",
  "Mobile": 919864886863,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "CACHAR",
  "Name": "sanjay dev",
  "Mobile": 919284639449,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sashanka kr dey",
  "Mobile": 918723926261,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saurav jyoti borah",
  "Mobile": 916003232338,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sayfur rahman",
  "Mobile": 918811056958,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "sbajamal ali",
  "Mobile": 917002207127,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "shames ali",
  "Mobile": 916000833699,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "shirajul haque",
  "Mobile": 917099103539,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "shri bidyut kalita",
  "Mobile": 919707005081,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "shri shanta kumar brahma",
  "Mobile": 917095009318,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shyam thapa",
  "Mobile": 917339089648,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shyamal singha",
  "Mobile": 916000909008,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sofikul ali",
  "Mobile": 917002143704,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "somed ali sheikh",
  "Mobile": 919365505227,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sonitpur",
  "Mobile": 8638365509,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri arup choudhury",
  "Mobile": 917002398323,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "sri diganta das",
  "Mobile": 916001211267,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "sri dipjyoti das",
  "Mobile": 916002698380,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sri jiku bora",
  "Mobile": 918473980028,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "subhas borah",
  "Mobile": 919146257937,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sujata kundu",
  "Mobile": 918402090490,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sukor ali",
  "Mobile": 919392229480,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sumit dutta",
  "Mobile": 917002727117,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "KARIMGANJ",
  "Name": "sunam uddin",
  "Mobile": 6002586462,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunita das",
  "Mobile": 917086515900,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sunmoni",
  "Mobile": 919127350029,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sutapa mazumder",
  "Mobile": 919401047488,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "swapnali chamuah",
  "Mobile": 916000825960,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "swmdwn boro",
  "Mobile": 916351923299,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "tajmal",
  "Mobile": 6000877807,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "tamal shil",
  "Mobile": 918724803174,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tankewar morang",
  "Mobile": 919678027184,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "tapan saikia",
  "Mobile": 916002090633,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "tazim uddin laskar",
  "Mobile": 919121566914,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "th pronodip singh",
  "Mobile": 918131802424,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "tikamaya devi",
  "Mobile": 919940883416,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "tulika barman",
  "Mobile": 919508638896,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "ucha kamal senapati",
  "Mobile": 8826921891,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "upen das",
  "Mobile": 918472992957,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ushs das",
  "Mobile": 919101986744,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "utpal kumar nath",
  "Mobile": 918638834239,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "utpal sarma",
  "Mobile": 918876042962,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "vipul",
  "Mobile": 918830297229,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "vishnu kumar singh",
  "Mobile": 919401485759,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "yaqub ali",
  "Mobile": 916001865787,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "yehidul islan",
  "Mobile": 916282119231,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "ziabur rahman",
  "Mobile": 919101554431,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "ziaul hoque",
  "Mobile": 918638608701,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Siwan",
  "Name": "shashi ranjan upadhyay",
  "Mobile": 919004570748,
  "State": "Bihar",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Purbi Champaran",
  "Name": "vikash kumar gupta",
  "Mobile": 918709771059,
  "State": "Bihar",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Saran",
  "Name": "vishwajeet kumar",
  "Mobile": 919810954758,
  "State": "Bihar",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Pashchim Champaran",
  "Name": "नरेंद्र",
  "Mobile": 919161939259,
  "State": "Bihar",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "RAJNANDGAON",
  "Name": "dipak kumar",
  "Mobile": 6268153764,
  "State": "Chattisgarh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kondagaon",
  "Name": "ndl",
  "Mobile": 916265718703,
  "State": "Chhattisgarh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "amit pal",
  "Mobile": 919311785070,
  "State": "Delhi",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "New Delhi",
  "Name": "moni borah",
  "Mobile": 919577424534,
  "State": "Delhi",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "South West",
  "Name": "varun kumar sharma",
  "Mobile": 919625386370,
  "State": "Delhi",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "East",
  "Name": "अभिषेक कुमार चौबे",
  "Mobile": 919472213353,
  "State": "Delhi",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Anand",
  "Name": "hasmukhbhai gohel",
  "Mobile": 918849983135,
  "State": "Gujarat",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Narmada",
  "Name": "ram",
  "Mobile": 919390333466,
  "State": "Gujarat",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "FARIDABAD",
  "Name": "hello",
  "Mobile": 918130633079,
  "State": "Haryana",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Rohtak",
  "Name": "jitendr kumar",
  "Mobile": 918813935538,
  "State": "Haryana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kaithal",
  "Name": "suman",
  "Mobile": 919991082213,
  "State": "Haryana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Anantnag",
  "Name": "tafazul",
  "Mobile": 8822797620,
  "State": "Jammu And Kashmir",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Garhwa",
  "Name": "devbali manjhi",
  "Mobile": 918088620186,
  "State": "Jharkhand",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Ranchi",
  "Name": "esha k",
  "Mobile": 8800276001,
  "State": "Jharkhand",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bengaluru Urban",
  "Name": "minti nath",
  "Mobile": 916303354926,
  "State": "Karnataka",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kalaburagi",
  "Name": "nanagouda",
  "Mobile": 919449153674,
  "State": "Karnataka",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Uttara Kannada",
  "Name": "sathish",
  "Mobile": 919741507607,
  "State": "Karnataka",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Ballari",
  "Name": "suriya",
  "Mobile": 8610938127,
  "State": "Karnataka",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Idukki",
  "Name": "arun ps",
  "Mobile": 918547674033,
  "State": "Kerala",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "shijo mathew",
  "Mobile": 919567671044,
  "State": "Kerala",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Thrissur",
  "Name": "thrissur",
  "Mobile": 918089515546,
  "State": "Kerala",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Vidisha",
  "Name": "monika maheshwari",
  "Mobile": 918305627915,
  "State": "Madhya pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "BETUL",
  "Name": "parasram evane",
  "Mobile": 917489161950,
  "State": "Madhya Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Sidhi",
  "Name": "rajendra tripathi",
  "Mobile": 917898051612,
  "State": "Madhya pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Satna",
  "Name": "shailendra singh",
  "Mobile": 919623513748,
  "State": "Madhya pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Thane",
  "Name": "abhishek",
  "Mobile": 918390921611,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Yavatmal",
  "Name": "avinash vidhate",
  "Mobile": 918308348691,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Latur",
  "Name": "dikshit",
  "Mobile": 9356484144,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Mumbai",
  "Name": "mrinal dad",
  "Mobile": 918828176217,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bhandara",
  "Name": "nitesh giripunje",
  "Mobile": 918390647747,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kolhapur",
  "Name": "prathamesh",
  "Mobile": 919552706448,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "नरेश सहनी",
  "Mobile": 919892182866,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Thane",
  "Name": "അനിൽ",
  "Mobile": 918169049235,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "East Garo Hills",
  "Name": "mridul rabha",
  "Mobile": 916000491991,
  "State": "Meghalaya",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Mayurbhanj",
  "Name": "rabindra nath mohanta",
  "Mobile": 917873967448,
  "State": "Odisha",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jaipur",
  "Name": "bajrang",
  "Mobile": 918740922843,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Jaipur",
  "Name": "hiii",
  "Mobile": 916375718815,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Bhilwara",
  "Name": "kushal singh rathore",
  "Mobile": 917689905444,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaur",
  "Name": "lala ram",
  "Mobile": 24106117728,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Rajsamand",
  "Name": "narayan lal kumawat",
  "Mobile": 919602970104,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jaipur",
  "Name": "piyush patel",
  "Mobile": 9928063040,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaur",
  "Name": "premkumer",
  "Mobile": 917851879640,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dausa",
  "Name": "rakesh",
  "Mobile": 919079099731,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cuddalore",
  "Name": "arikrishnan",
  "Mobile": 918825475035,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Namakkal",
  "Name": "chandrasekar",
  "Mobile": 918489753239,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Pudukkottai",
  "Name": "madhavan g",
  "Mobile": 919043907417,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Virudhunagar",
  "Name": "maheshkumar",
  "Mobile": 919786888978,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Chengalpattu",
  "Name": "naresh kumar",
  "Mobile": 918056492715,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dindigul",
  "Name": "premkumar",
  "Mobile": 918526518272,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cuddalore",
  "Name": "saksham",
  "Mobile": 8220837852,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tirunelveli",
  "Name": "sheik mohammed",
  "Mobile": 916381544245,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "THANJAVUR",
  "Name": "singaravadivel m",
  "Mobile": 919345420905,
  "State": "Tamil Nadu",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Tirunelveli",
  "Name": "vijayakumar",
  "Mobile": 919600932355,
  "State": "Tamil nadu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Rajanna Sircilla",
  "Name": "anil",
  "Mobile": 919100210029,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Yadadri Bhuvanagiri",
  "Name": "boya vijay kumar",
  "Mobile": 919542442066,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "buddha lavanya",
  "Mobile": 919391260033,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Peddapalli",
  "Name": "burra sumalatha",
  "Mobile": 918106727814,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "chintu",
  "Mobile": 919640517484,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalgonda",
  "Name": "d balunaik",
  "Mobile": 919849747003,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "eluka shashi kumar",
  "Mobile": 919704589758,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bhadradri Kothagudem",
  "Name": "gummala purnachander",
  "Mobile": 919494323046,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalgonda",
  "Name": "im",
  "Mobile": 919014908377,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Mancherial",
  "Name": "karthik",
  "Mobile": 8519967584,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalgonda",
  "Name": "n jithendar reddy",
  "Mobile": 919963139077,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "pidugu shravan",
  "Mobile": 919014039416,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Peddapalli",
  "Name": "raj kumar",
  "Mobile": 918919571092,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "rajender",
  "Mobile": 918888384268,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jayashankar Bhupalapally",
  "Name": "rakesh banala",
  "Mobile": 919948255514,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Khammam",
  "Name": "shaik afroz",
  "Mobile": 919912514101,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Ranga Reddy",
  "Name": "srikanth",
  "Mobile": 917207391676,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "srikanth",
  "Mobile": 919391235339,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bhadradri Kothagudem",
  "Name": "tejavath bhaskar",
  "Mobile": 919704729220,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Daman",
  "Name": "bill gates raj kapoor amitabh dilip kumar",
  "Mobile": 919622831933,
  "State": "The Dadra And Nagar Haveli And Daman And Diu",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dadra And Nagar Haveli",
  "Name": "xxx",
  "Mobile": 916389260640,
  "State": "The Dadra And Nagar Haveli And Daman And Diu",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "alam",
  "Mobile": 919354022169,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Avanendra dubay",
  "Mobile": 8707850541,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Dipesh",
  "Mobile": 9935694681,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "divyam singh",
  "Mobile": 7983372460,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Fakruddin Ansari",
  "Mobile": 9936598512,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Farman ilahi",
  "Mobile": 9580818599,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Gopal hi jaiswal",
  "Mobile": 9628180970,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "GOPAL UPADHYAY",
  "Mobile": 9450615076,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Hemraj khujwar",
  "Mobile": 9199744076,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Fatehpur",
  "Name": "i",
  "Mobile": 918601563582,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "INDU PRAKASH",
  "Mobile": 9919322533,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "JITENDRA SINGH",
  "Mobile": 9415810744,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Bijnor",
  "Name": "jogendra singh",
  "Mobile": 918920402812,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "JUGANU PATEL",
  "Mobile": 8840759976,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "KRISHNA KUMAR TIWARI",
  "Mobile": 9454053564,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MAHENDRA UPADHYAY",
  "Mobile": 9621879739,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MANOJ GUPTA",
  "Mobile": 9934971212,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MANOJ KUMAR SIGH",
  "Mobile": 9559219999,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MOKHTAR YADAV",
  "Mobile": 8840953933,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MUNNA YADAV",
  "Mobile": 8004251867,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Munna yadav",
  "Mobile": 8090607856,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "PREM BHADUR THAPA",
  "Mobile": 9118438225,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RADHESHYAM SHARMA",
  "Mobile": 9936240145,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Raj kumar ram",
  "Mobile": 9006117962,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJENDRA KUMAR",
  "Mobile": 8083005211,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJENDRA PRASAD PATEL",
  "Mobile": 8299459772,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravindra verma",
  "Mobile": 9838297479,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SANJIV MISHRA",
  "Mobile": 9415812519,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANTOSH KR MISHRA",
  "Mobile": 9336156524,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANTOSH KUMAR GUPTA",
  "Mobile": 9935408674,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SAURABH KUMAR SINGH",
  "Mobile": 9452513305,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shahdulha ansari",
  "Mobile": 7897464858,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Moradabad",
  "Name": "shanu khan",
  "Mobile": 966573232510,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SHIVA KANT UPADHYAY",
  "Mobile": 6386431096,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SHYAM BHAHADUR VERMA",
  "Mobile": 8808995494,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SIDDHESHWAR PRASAD",
  "Mobile": 9450245454,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SIDDIKA BANO",
  "Mobile": 9118153679,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hamirpur",
  "Name": "sonu",
  "Mobile": 918081774502,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SUNIL SONKAR",
  "Mobile": 8707667161,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SURENDRA GUPTA",
  "Mobile": 7523861858,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SURENDRA MOHAN PANDEY",
  "Mobile": 8562928931,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "UMESH SINGH",
  "Mobile": 9935660751,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "VIJAY PAL",
  "Mobile": 7275643494,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "अमित",
  "Mobile": 918869907358,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "विपिन कुमार",
  "Mobile": 918859358020,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "संजय माहौर",
  "Mobile": 917078446098,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Almora",
  "Name": "pooran chandra sati",
  "Mobile": 919411703332,
  "State": "Uttarakhand",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Paschim Bardhaman",
  "Name": "ashish bauri",
  "Mobile": 918158803194,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Paschim Bardhaman",
  "Name": "kunal",
  "Mobile": 918972567458,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Jalpaiguri",
  "Name": "kushal bara",
  "Mobile": 918637040838,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "24 Paraganas North",
  "Name": "rajesh paul",
  "Mobile": 918961684841,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "krishna rao",
  "Mobile": 919010484033,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "u rajeshkumar",
  "Mobile": 919177108496,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Prakasam",
  "Name": "venkata kiran chikkudu",
  "Mobile": 917569773041,
  "State": "Andhra Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul hannan hague",
  "Mobile": 917576823498,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul sajid",
  "Mobile": 919738182242,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "abdul salam sk",
  "Mobile": 917002894796,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anowar ali",
  "Mobile": 916000807391,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "anowar hussain",
  "Mobile": 916026564765,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ansarul islam",
  "Mobile": 916300623495,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "ashadulla",
  "Mobile": 918638669435,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "atibar rahman",
  "Mobile": 916001062770,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "aynul mir",
  "Mobile": 919365067877,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "babul baishya",
  "Mobile": 916001632955,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bachan nayak",
  "Mobile": 918348885487,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "banobashi das",
  "Mobile": 916001562804,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bidyut boruah",
  "Mobile": 7896219692,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "bipul senapoti",
  "Mobile": 917569997127,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "bitu urang",
  "Mobile": 918825474790,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "dhanjit mandal",
  "Mobile": 919954595329,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "diganta boruah",
  "Mobile": 919085348930,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "dipankar gogoi",
  "Mobile": 918639055525,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "gaurab chakraborty",
  "Mobile": 916001890858,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "gonesh chetry",
  "Mobile": 919366128431,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "guddu thakur",
  "Mobile": 919706239777,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "imran hussain laskar",
  "Mobile": 916002085572,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jagnnath kumar das",
  "Mobile": 916002181201,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "jiadur rahman khan",
  "Mobile": 916001825715,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakshya gogoi",
  "Mobile": 916000839194,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "manoj chakraborty",
  "Mobile": 917896047810,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "mayan ali",
  "Mobile": 917002042860,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "milan rajbongshi",
  "Mobile": 919613614805,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "mizanur rahman",
  "Mobile": 919678209826,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "mohibuddin ansari",
  "Mobile": 919072271009,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "mon mohan koch",
  "Mobile": 6900639302,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "muktar hussain",
  "Mobile": 918471899832,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mukunda das",
  "Mobile": 916000042013,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "nabiur rahman",
  "Mobile": 917002101425,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "nikunja sarkar",
  "Mobile": 919954435495,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "paban mandal",
  "Mobile": 919085565406,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "pijush kanti gogoi",
  "Mobile": 918136042408,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "pinku deka",
  "Mobile": 919101581669,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "piyanka",
  "Mobile": 918134993841,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta chandra roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rafiqul islam",
  "Mobile": 9022483574,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "rahul gurung",
  "Mobile": 916001093491,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "JORHAT",
  "Name": "rajib mili",
  "Mobile": 919957029050,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "ram krishna malo",
  "Mobile": 918723990010,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjanjyoti gogoi",
  "Mobile": 918134977553,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ritu das",
  "Mobile": 918978076812,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rituparna borah",
  "Mobile": 918011700850,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rokibul hoque",
  "Mobile": 918134021904,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saiful",
  "Mobile": 919707358015,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "samar chandra karmakar",
  "Mobile": 916003210372,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sanidul ahmed",
  "Mobile": 918876055420,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shukla biswas",
  "Mobile": 6901103517,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "simanta bora",
  "Mobile": 6000308174,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sir babul biswas",
  "Mobile": 918638669533,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sobidul hoque bilasipara",
  "Mobile": 918876209136,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sudhan",
  "Mobile": 916000082992,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sukla",
  "Mobile": 916901103517,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "surajit talukdar",
  "Mobile": 919101602087,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "thunu gogoi",
  "Mobile": 919360027950,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tulan panging",
  "Mobile": 916002102802,
  "State": "Assam",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Begusarai",
  "Name": "bipin kumar sharma",
  "Mobile": 917280994871,
  "State": "Bihar",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "saddam saifi",
  "Mobile": 919927456292,
  "State": "Delhi",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Uttara Kannada",
  "Name": "eshwar",
  "Mobile": 916362071932,
  "State": "Karnataka",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "ashil k r",
  "Mobile": 919744733785,
  "State": "Kerala",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Malappuram",
  "Name": "bharathan",
  "Mobile": 919747292875,
  "State": "Kerala",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "krishna",
  "Mobile": 918011867325,
  "State": "Kerala",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhule",
  "Name": "किरण भाऊ लोहार",
  "Mobile": 917741885956,
  "State": "Maharashtra",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Churu",
  "Name": "anil kumar soni",
  "Mobile": 917737735697,
  "State": "Rajasthan",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimnagar",
  "Name": "prasad kotagiri",
  "Mobile": 918106102386,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "tinku dutta",
  "Mobile": 918498842540,
  "State": "Telangana",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Azamgarh",
  "Name": "alpesh saroj",
  "Mobile": 918377810548,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ANIL KUMAR SINGH",
  "Mobile": 8601315862,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Dhaneshwar gupta",
  "Mobile": 6393706891,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "mohd javed",
  "Mobile": 9451579719,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rajendra prasad",
  "Mobile": 8601447391,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "raju rathore",
  "Mobile": 917060991075,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAKESH GIRI",
  "Mobile": 9450816530,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh singh",
  "Mobile": 9935289443,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh tiwari",
  "Mobile": 9695326169,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramlakhan",
  "Mobile": 9580517757,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ramnagina patel",
  "Mobile": 9305125780,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravi Shankar shukla",
  "Mobile": 7839142788,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravish srivastav",
  "Mobile": 7651912751,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Sanjay Gupta",
  "Mobile": 8569865425,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY VISHWAKARMA",
  "Mobile": 9506383322,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SANJIV SINGH",
  "Mobile": 8115305484,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "shailesh",
  "Mobile": 919561439110,
  "State": "Uttar pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Vineet",
  "Mobile": 9015291893,
  "State": "Uttar Pradesh",
  "Skill": "Fitter",
  "Company": "Other"
 },
 {
  "District": "Alipurduar",
  "Name": "bishnu",
  "Mobile": 917477738268,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jalpaiguri",
  "Name": "niranjan sarkar",
  "Mobile": 919064011649,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Purulia",
  "Name": "pradip das mahanta",
  "Mobile": 918327005447,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Coochbehar",
  "Name": "rajeswar barman",
  "Mobile": 9085841235,
  "State": "West bengal",
  "Skill": "Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Vizianagaram",
  "Name": "dola kartheek kumar",
  "Mobile": 917729867032,
  "State": "Andhra Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "ANANTHAPUR",
  "Name": "g veeresh",
  "Mobile": 918897799428,
  "State": "Andhra Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Y.S.R.",
  "Name": "madhavanarayana",
  "Mobile": 919966924166,
  "State": "Andhra Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "tandrothu suresh",
  "Mobile": 918096344694,
  "State": "Andhra Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Namsai",
  "Name": "sunil",
  "Mobile": 918822775647,
  "State": "Arunachal Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "abedur rahman",
  "Mobile": 918822102123,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "abhay das",
  "Mobile": 7576044510,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "abhijit saha",
  "Mobile": 917099994425,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abhinov dowarah",
  "Mobile": 919101871659,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "absadur rahman",
  "Mobile": 919706598027,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "abul kalam azad",
  "Mobile": 919101535691,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "adiya fara",
  "Mobile": 917338925891,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "afiya fara",
  "Mobile": 919957251526,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "aftar hussain",
  "Mobile": 917576921177,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "ajay ray",
  "Mobile": 919127316814,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ajijul hoqe",
  "Mobile": 916000672852,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "ajit barman",
  "Mobile": 917002934513,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "akash biswas",
  "Mobile": 918474047104,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ali akbor",
  "Mobile": 918838880738,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "amal basumatary",
  "Mobile": 916282853741,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "amarjyoti bhuyan",
  "Mobile": 918822197208,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "aminul",
  "Mobile": 917002631928,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "amir khan ali",
  "Mobile": 919101688878,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "amor hazarika",
  "Mobile": 918474079590,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "anamika boruah",
  "Mobile": 918876763279,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "anil saha",
  "Mobile": 917638064261,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anisur rahman",
  "Mobile": 917306244802,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "ankur saikia",
  "Mobile": 8011515058,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "anowar hussain",
  "Mobile": 918399078899,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "anud gogoi",
  "Mobile": 917896194398,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "apurba kalita",
  "Mobile": 916002983590,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "arbisan islary",
  "Mobile": 916000143383,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "arman ansari",
  "Mobile": 919613937054,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "arunav buragohain",
  "Mobile": 916000719118,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "arup das",
  "Mobile": 918724037146,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "arup jyoti saikia",
  "Mobile": 918638203856,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "ashok kr chao",
  "Mobile": 919678845320,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "ashraf ali",
  "Mobile": 918638314213,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "assamese teacher",
  "Mobile": 919706966294,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "astam das",
  "Mobile": 917972616287,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "avilash saikia",
  "Mobile": 918721930918,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "aybur khan",
  "Mobile": 6000481343,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "azmal hoque",
  "Mobile": 919954955241,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "basab dey",
  "Mobile": 919707053070,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "benudhar gogoi",
  "Mobile": 917637807467,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bhabani",
  "Mobile": 919707186964,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bhaben deka",
  "Mobile": 919365023510,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "bhabesh das",
  "Mobile": 919577366036,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "bhagaban barman",
  "Mobile": 918135978266,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "bharat saikia",
  "Mobile": 916001620233,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bhaskar das",
  "Mobile": 917002939122,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "bhaskar jyoti borah",
  "Mobile": 916003997450,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bhaskar sharma",
  "Mobile": 917002918059,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "bhupenchdeka",
  "Mobile": 917635973539,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bibek",
  "Mobile": 919365468438,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "bibek majumdar",
  "Mobile": 9957861149,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bidyut",
  "Mobile": 918876941234,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "bijit singh",
  "Mobile": 917002566536,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bikash das",
  "Mobile": 917002293183,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "bikash ranjan gogoi",
  "Mobile": 917636982275,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Majuli",
  "Name": "binod das",
  "Mobile": 919080695968,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "bipulbordoloi",
  "Mobile": 919864943978,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bishnu borah",
  "Mobile": 917086377408,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "bishnu boro",
  "Mobile": 919365724209,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "biswajit baishya",
  "Mobile": 919101882213,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "biswajit das",
  "Mobile": 917578832155,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "biswajit gogoi",
  "Mobile": 918638005075,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bitupan gohain",
  "Mobile": 916002976497,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bogadhor sonowal",
  "Mobile": 917099865370,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "buban das",
  "Mobile": 918402924092,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "budhin lahon",
  "Mobile": 917002361985,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "chandan dutta",
  "Mobile": 919101616820,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "chandan roy",
  "Mobile": 919707095829,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "debajit gogoi",
  "Mobile": 918134981086,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "debeswar taid",
  "Mobile": 916000979902,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "denhasar boro",
  "Mobile": 918011051459,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "dergaon",
  "Mobile": 918011040423,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "dharani roy",
  "Mobile": 918638792237,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "dhiraj gogoi",
  "Mobile": 917002013273,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "digas",
  "Mobile": 916000625158,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dildar hussain",
  "Mobile": 916002090518,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dildar sharif",
  "Mobile": 919365101606,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "dilip das",
  "Mobile": 919954377955,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "dilip dash",
  "Mobile": 919365435008,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "dilip suklabaidya",
  "Mobile": 919854794953,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "dipak bora",
  "Mobile": 919365862825,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "dipak prasad gupta",
  "Mobile": 918876041776,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "dipankar das",
  "Mobile": 918822257297,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dipu saikia",
  "Mobile": 919101995100,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "dipurajak",
  "Mobile": 917896165929,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "durlabh sahu",
  "Mobile": 916003844593,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "dwipjyoti kalita",
  "Mobile": 918638219338,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "farukh ali",
  "Mobile": 919365591115,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "fatik das",
  "Mobile": 918638986606,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "fulu lama laskar",
  "Mobile": 918876963669,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "gajen hazarika",
  "Mobile": 916900526670,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "gajiur rahman",
  "Mobile": 917635941193,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "gautam baruah",
  "Mobile": 916000206323,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "gautam chakraborty",
  "Mobile": 917637058377,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "DHEMAJI",
  "Name": "gitumoni sonowal",
  "Mobile": 916000273741,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "giyas uddin",
  "Mobile": 7907308623,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "gokul das",
  "Mobile": 919365450502,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "gopal timsina",
  "Mobile": 96566944757,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "govine",
  "Mobile": 918076263125,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "hadayat ullah",
  "Mobile": 917664909458,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hajarat ali",
  "Mobile": 916000065884,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "hakimul islam",
  "Mobile": 917662811407,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "hasmot ali",
  "Mobile": 917099923905,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "hemen das",
  "Mobile": 918638479546,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "hemjyoti rajbongshi",
  "Mobile": 919678863512,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "hemolata sonowal",
  "Mobile": 8404092195,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "hirakjyoti das",
  "Mobile": 917002126730,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hirannya kalita",
  "Mobile": 916000160115,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "hiranya handique",
  "Mobile": 917896698880,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "hiroik",
  "Mobile": 918073455122,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "ibrahim ali",
  "Mobile": 918486574915,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ibrahim ali",
  "Mobile": 918794317263,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "ikramul",
  "Mobile": 917429607656,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "iman ali",
  "Mobile": 919074261511,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "imran hussain",
  "Mobile": 6002403593,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "imran hussain",
  "Mobile": 918812858595,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "islam uddin laskar",
  "Mobile": 916003264788,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "iyaj ali",
  "Mobile": 919101496023,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "jadu mech",
  "Mobile": 918638256561,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "jadumoni gowala",
  "Mobile": 918134027917,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "jagadish borah",
  "Mobile": 918754930990,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "jainal abedin",
  "Mobile": 916002045321,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jakir ali",
  "Mobile": 6000614434,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "jakir hussain",
  "Mobile": 8130959257,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jakir hussain",
  "Mobile": 918812950346,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "jay chandra nath",
  "Mobile": 918137092461,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jayanta das",
  "Mobile": 919365753477,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "jayanta haloi",
  "Mobile": 917002191453,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Karbi Anglong",
  "Name": "jayanta mech",
  "Mobile": 917099374775,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "jeherul islam",
  "Mobile": 916001569891,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "jishnu borgohain",
  "Mobile": 917638070980,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "jitamoni konwar",
  "Mobile": 917896575966,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jiten saikia",
  "Mobile": 919365467062,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jiyaruddin ahmed",
  "Mobile": 8474848150,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "johir ahmed",
  "Mobile": 918286985669,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "jorhat",
  "Mobile": 919365604153,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "junu sanjay barman",
  "Mobile": 919974390354,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "kanta malakar",
  "Mobile": 918073404526,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "kanu nag",
  "Mobile": 8259068646,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "khanindra barman",
  "Mobile": 9101127618,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "kishor dutta",
  "Mobile": 7002133581,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "kitna bar blna prega",
  "Mobile": 917002190373,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "krishna roy",
  "Mobile": 919864947926,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "kuladip das",
  "Mobile": 916003045624,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "kuldip doley",
  "Mobile": 917636011683,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "kunja mohan das",
  "Mobile": 919435454961,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "kunjan das",
  "Mobile": 919609549895,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "lachit hajong",
  "Mobile": 918891126939,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "lakshi kantha rajbongshi",
  "Mobile": 919066655034,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "lutfor rahman",
  "Mobile": 919365103706,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "madhab kalita",
  "Mobile": 918905378784,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "mafidul islam",
  "Mobile": 917636921131,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mahar  ali",
  "Mobile": 917096532545,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "mahidul islam",
  "Mobile": 918638746573,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "mahijal hoque",
  "Mobile": 916002754180,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "malay biswas",
  "Mobile": 917896687127,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "manash jyoti das",
  "Mobile": 918486531492,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "NALBARI",
  "Name": "manash jyoti deka",
  "Mobile": 919085665808,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "manosh protim borbora",
  "Mobile": 919613920875,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "md abul kalam azad",
  "Mobile": 917896372128,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "md ajajul ahmed",
  "Mobile": 916003275022,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md saiful islam",
  "Mobile": 918822627441,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "michael brahma",
  "Mobile": 6000962317,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "mintu kalita",
  "Mobile": 917896403473,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "miraj",
  "Mobile": 916900242495,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "mofida khatun",
  "Mobile": 918486657291,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mofidul islam",
  "Mobile": 918812912147,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "monika das",
  "Mobile": 916003729544,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "montu dutta",
  "Mobile": 919678380016,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "monuj borah",
  "Mobile": 919132864413,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "mostufa ahmed",
  "Mobile": 919101906397,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "moumita nath",
  "Mobile": 917896304334,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "mriganka ree",
  "Mobile": 9101171889,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mrigen sarma",
  "Mobile": 918638793075,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "mujibur rahman laskar",
  "Mobile": 919401696531,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "mukut boro",
  "Mobile": 918011799987,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "muzahirul hoque",
  "Mobile": 918056650532,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "my name is himangshu khamon",
  "Mobile": 919365048364,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "name buddhadev rabha",
  "Mobile": 916900560596,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "namrahul amin",
  "Mobile": 918638731536,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "nilutpal roy",
  "Mobile": 918553474611,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "nimai",
  "Mobile": 918638985637,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "nitul bora",
  "Mobile": 916002071610,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "noor hossain",
  "Mobile": 917002861797,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "nur alom sk",
  "Mobile": 916000546387,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "nur hussain ali",
  "Mobile": 919326479189,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "omed ali",
  "Mobile": 916003404890,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "paban kardong",
  "Mobile": 916000978785,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "paban kumar roy",
  "Mobile": 917577089267,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "padmadhar das",
  "Mobile": 918473959427,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "padum chetry",
  "Mobile": 916304559198,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "pankaj das",
  "Mobile": 917638814957,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "paplu gogoi",
  "Mobile": 919080804042,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "parag baishya",
  "Mobile": 918638355387,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "parag bhuyan",
  "Mobile": 918812037306,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "parimal das",
  "Mobile": 918638297877,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "pinika deb",
  "Mobile": 918753947987,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "pradip talukdar",
  "Mobile": 918928770951,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "pradyut gogoi",
  "Mobile": 919101561954,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "prafulla",
  "Mobile": 919101394456,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "prakash hira",
  "Mobile": 919476876101,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "prallab doley",
  "Mobile": 916002355972,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "prasenjit karmakar",
  "Mobile": 918876670753,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "pritam suklabaidya",
  "Mobile": 916003876138,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "priyam kalita",
  "Mobile": 917399728613,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "priyanka das",
  "Mobile": 9864847072,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "priyom protim borah",
  "Mobile": 918638169887,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "probin doley",
  "Mobile": 917002531855,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "puja",
  "Mobile": 917636866704,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "puja devi",
  "Mobile": 916002072576,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "puja kumari chhetry",
  "Mobile": 918402902182,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "puspa konch",
  "Mobile": 918137910429,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "rabin",
  "Mobile": 919613730086,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "radhy   shyam  saha",
  "Mobile": 917099620853,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rafikul ali",
  "Mobile": 919126591701,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "rahul billung",
  "Mobile": 916901085988,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rahul ray",
  "Mobile": 916003776980,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "raiju islam talukder",
  "Mobile": 919706925248,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "rajesh kar",
  "Mobile": 918721831102,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "rajesh malah",
  "Mobile": 918876089306,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rajiv talukdar",
  "Mobile": 919365353553,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "raju buragohain",
  "Mobile": 916003490355,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "rakesh ali",
  "Mobile": 919577777704,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "ramesh gowala",
  "Mobile": 919101528557,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "ranjan das",
  "Mobile": 916001338089,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "ranjib sarmah",
  "Mobile": 917002352033,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "ranjit",
  "Mobile": 919864847602,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "rashiduel",
  "Mobile": 919074117380,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "rayaan das",
  "Mobile": 918011718551,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "reshab jyoti borah",
  "Mobile": 919706694947,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rezaul hoque",
  "Mobile": 9365508288,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "rinku baishya",
  "Mobile": 917002555345,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "ritu",
  "Mobile": 919707805071,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "rituraj dowarah",
  "Mobile": 918761973232,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "robi barman",
  "Mobile": 917086807892,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "romesh phukon",
  "Mobile": 918638771358,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "romjan ali sheikh",
  "Mobile": 919707521350,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "roni",
  "Mobile": 916001276861,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "ronjita gour",
  "Mobile": 918472925494,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "roshidul hoque",
  "Mobile": 919476779302,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rubul ali",
  "Mobile": 919101406178,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ruhin gogoi",
  "Mobile": 916002155984,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "rupam jyoti goswami",
  "Mobile": 918472014690,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rupjyoti das",
  "Mobile": 918638570054,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "rupjyoti tamuli",
  "Mobile": 916001788601,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "s",
  "Mobile": 917035463471,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "sabbir azim",
  "Mobile": 919957241334,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saddamhussain",
  "Mobile": 917002938704,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sadikul islam",
  "Mobile": 6854358625,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sadique ahmed",
  "Mobile": 919864559687,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "safed ali",
  "Mobile": 919764611649,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "saidur rahman",
  "Mobile": 7002121711,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "saidur rahman",
  "Mobile": 918217877033,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "saiful islam",
  "Mobile": 918638756416,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "saifur rahman",
  "Mobile": 8473043011,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Ahmednagar",
  "Name": "Pranav Tryambake",
  "Mobile": 8830596215,
  "State": "Maharashtra",
  "Skill": "Finance",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "samir sarkar",
  "Mobile": 916001509650,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "samsul alom",
  "Mobile": 919101751230,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sanchita das ghosh",
  "Mobile": 919864886863,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "sanjay dev",
  "Mobile": 919284639449,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sashanka kr dey",
  "Mobile": 918723926261,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saurav jyoti borah",
  "Mobile": 916003232338,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sayfur rahman",
  "Mobile": 918811056958,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sbajamal ali",
  "Mobile": 917002207127,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sd mirajar rahman",
  "Mobile": 916000751572,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "shames ali",
  "Mobile": 916000833699,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "shashi",
  "Mobile": 918135026526,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "shirajul haque",
  "Mobile": 917099103539,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "shri bidyut kalita",
  "Mobile": 919707005081,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "shri shanta kumar brahma",
  "Mobile": 917095009318,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shyam thapa",
  "Mobile": 917339089648,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shyamal singha",
  "Mobile": 916000909008,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "siva munda",
  "Mobile": 919678988558,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sofikul ali",
  "Mobile": 917002143704,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "somed ali sheikh",
  "Mobile": 919365505227,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sonitpur",
  "Mobile": 8638365509,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri arup choudhury",
  "Mobile": 917002398323,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "sri diganta das",
  "Mobile": 916001211267,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "sri dipjyoti das",
  "Mobile": 916002698380,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sri jiku bora",
  "Mobile": 918473980028,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "subhas borah",
  "Mobile": 919146257937,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sujata kundu",
  "Mobile": 918402090490,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sukhen",
  "Mobile": 917002453269,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sukor ali",
  "Mobile": 919392229480,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sumit dutta",
  "Mobile": 917002727117,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "KARIMGANJ",
  "Name": "sunam uddin",
  "Mobile": 6002586462,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sunil das",
  "Mobile": 917641043209,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunita das",
  "Mobile": 917086515900,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sunmoni",
  "Mobile": 919127350029,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sutapa mazumder",
  "Mobile": 919401047488,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "swapnali chamuah",
  "Mobile": 916000825960,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "swmdwn boro",
  "Mobile": 916351923299,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "tajmal",
  "Mobile": 6000877807,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "tamal shil",
  "Mobile": 918724803174,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "tankewar morang",
  "Mobile": 919678027184,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "tapan saikia",
  "Mobile": 916002090633,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "tazim uddin laskar",
  "Mobile": 919121566914,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "th pronodip singh",
  "Mobile": 918131802424,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "tikamaya devi",
  "Mobile": 919940883416,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "tulika barman",
  "Mobile": 919508638896,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ucha kamal senapati",
  "Mobile": 8826921891,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "upen das",
  "Mobile": 918472992957,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ushs das",
  "Mobile": 919101986744,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "utpal kumar nath",
  "Mobile": 918638834239,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "utpal sarma",
  "Mobile": 918876042962,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "vipul",
  "Mobile": 918830297229,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "vishnu kumar singh",
  "Mobile": 919401485759,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "yaqub ali",
  "Mobile": 916001865787,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "yehidul islan",
  "Mobile": 916282119231,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "ziabur rahman",
  "Mobile": 919101554431,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "ziaul hoque",
  "Mobile": 918638608701,
  "State": "Assam",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Siwan",
  "Name": "shashi ranjan upadhyay",
  "Mobile": 919004570748,
  "State": "Bihar",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Purbi Champaran",
  "Name": "vikash kumar gupta",
  "Mobile": 918709771059,
  "State": "Bihar",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Saran",
  "Name": "vishwajeet kumar",
  "Mobile": 919810954758,
  "State": "Bihar",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Pashchim Champaran",
  "Name": "नरेंद्र",
  "Mobile": 919161939259,
  "State": "Bihar",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "RAJNANDGAON",
  "Name": "dipak kumar",
  "Mobile": 6268153764,
  "State": "Chattisgarh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kondagaon",
  "Name": "ndl",
  "Mobile": 916265718703,
  "State": "Chhattisgarh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Janjgir-Champa",
  "Name": "shriram  suryawanshi",
  "Mobile": 918462890159,
  "State": "Chhattisgarh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "amit pal",
  "Mobile": 919311785070,
  "State": "Delhi",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "New Delhi",
  "Name": "moni borah",
  "Mobile": 919577424534,
  "State": "Delhi",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "rabiya",
  "Mobile": 918595693958,
  "State": "Delhi",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "South West",
  "Name": "varun kumar sharma",
  "Mobile": 919625386370,
  "State": "Delhi",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "East",
  "Name": "अभिषेक कुमार चौबे",
  "Mobile": 919472213353,
  "State": "Delhi",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Anand",
  "Name": "hasmukhbhai gohel",
  "Mobile": 918849983135,
  "State": "Gujarat",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Narmada",
  "Name": "ram",
  "Mobile": 919390333466,
  "State": "Gujarat",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Rajkot",
  "Name": "sahil",
  "Mobile": 916354017531,
  "State": "Gujarat",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "FARIDABAD",
  "Name": "hello",
  "Mobile": 918130633079,
  "State": "Haryana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Rohtak",
  "Name": "jitendr kumar",
  "Mobile": 918813935538,
  "State": "Haryana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kaithal",
  "Name": "suman",
  "Mobile": 919991082213,
  "State": "Haryana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Anantnag",
  "Name": "tafazul",
  "Mobile": 8822797620,
  "State": "Jammu And Kashmir",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Chatra",
  "Name": "babulal haiburu",
  "Mobile": 919801891040,
  "State": "Jharkhand",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Garhwa",
  "Name": "devbali manjhi",
  "Mobile": 918088620186,
  "State": "Jharkhand",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Ranchi",
  "Name": "esha k",
  "Mobile": 8800276001,
  "State": "Jharkhand",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bokaro",
  "Name": "nitish kumar gupta",
  "Mobile": 918789243487,
  "State": "Jharkhand",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Bengaluru Urban",
  "Name": "minti nath",
  "Mobile": 916303354926,
  "State": "Karnataka",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kalaburagi",
  "Name": "nanagouda",
  "Mobile": 919449153674,
  "State": "Karnataka",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Uttara Kannada",
  "Name": "sathish",
  "Mobile": 919741507607,
  "State": "Karnataka",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Ballari",
  "Name": "suriya",
  "Mobile": 8610938127,
  "State": "Karnataka",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Idukki",
  "Name": "arun ps",
  "Mobile": 918547674033,
  "State": "Kerala",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "shijo mathew",
  "Mobile": 919567671044,
  "State": "Kerala",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Thrissur",
  "Name": "thrissur",
  "Mobile": 918089515546,
  "State": "Kerala",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Vidisha",
  "Name": "monika maheshwari",
  "Mobile": 918305627915,
  "State": "Madhya pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "GWALIOR",
  "Name": "my name is kuber shakya",
  "Mobile": 917440531599,
  "State": "Madhya Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "BETUL",
  "Name": "parasram evane",
  "Mobile": 917489161950,
  "State": "Madhya Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Sidhi",
  "Name": "rajendra tripathi",
  "Mobile": 917898051612,
  "State": "Madhya pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Satna",
  "Name": "shailendra singh",
  "Mobile": 919623513748,
  "State": "Madhya pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Thane",
  "Name": "abhishek",
  "Mobile": 918390921611,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Yavatmal",
  "Name": "avinash vidhate",
  "Mobile": 918308348691,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Latur",
  "Name": "dikshit",
  "Mobile": 9356484144,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "mrinal dad",
  "Mobile": 918828176217,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Bhandara",
  "Name": "nitesh giripunje",
  "Mobile": 918390647747,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Kolhapur",
  "Name": "prathamesh",
  "Mobile": 919552706448,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "vivek chaurasia",
  "Mobile": 919833254947,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "नरेश सहनी",
  "Mobile": 919892182866,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Thane",
  "Name": "അനിൽ",
  "Mobile": 918169049235,
  "State": "Maharashtra",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bishnupur",
  "Name": "nongmaithem rishinanda singh",
  "Mobile": 919612660248,
  "State": "Manipur",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "East Garo Hills",
  "Name": "mridul rabha",
  "Mobile": 916000491991,
  "State": "Meghalaya",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Mayurbhanj",
  "Name": "rabindra nath mohanta",
  "Mobile": 917873967448,
  "State": "Odisha",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jaipur",
  "Name": "bajrang",
  "Mobile": 918740922843,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bhilwara",
  "Name": "gopal",
  "Mobile": 918104807929,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jaipur",
  "Name": "hiii",
  "Mobile": 916375718815,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bhilwara",
  "Name": "kushal singh rathore",
  "Mobile": 917689905444,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaur",
  "Name": "lala ram",
  "Mobile": 24106117728,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Rajsamand",
  "Name": "narayan lal kumawat",
  "Mobile": 919602970104,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Jaipur",
  "Name": "piyush patel",
  "Mobile": 9928063040,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaur",
  "Name": "premkumer",
  "Mobile": 917851879640,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Dausa",
  "Name": "rakesh",
  "Mobile": 919079099731,
  "State": "Rajasthan",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Cuddalore",
  "Name": "arikrishnan",
  "Mobile": 918825475035,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Namakkal",
  "Name": "chandrasekar",
  "Mobile": 918489753239,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Pudukkottai",
  "Name": "madhavan g",
  "Mobile": 919043907417,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tenkasi",
  "Name": "mahesh s",
  "Mobile": 919894810167,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Virudhunagar",
  "Name": "maheshkumar",
  "Mobile": 919786888978,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Chengalpattu",
  "Name": "naresh kumar",
  "Mobile": 918056492715,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tuticorin",
  "Name": "petchimuthu m",
  "Mobile": 916374901571,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dindigul",
  "Name": "premkumar",
  "Mobile": 918526518272,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Cuddalore",
  "Name": "saksham",
  "Mobile": 8220837852,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tirunelveli",
  "Name": "sheik mohammed",
  "Mobile": 916381544245,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "THANJAVUR",
  "Name": "singaravadivel m",
  "Mobile": 919345420905,
  "State": "Tamil Nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Tirunelveli",
  "Name": "vijayakumar",
  "Mobile": 919600932355,
  "State": "Tamil nadu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Rajanna Sircilla",
  "Name": "anil",
  "Mobile": 919100210029,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Yadadri Bhuvanagiri",
  "Name": "boya vijay kumar",
  "Mobile": 919542442066,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Peddapalli",
  "Name": "buddha lavanya",
  "Mobile": 919391260033,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "burra sumalatha",
  "Mobile": 918106727814,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "chintu",
  "Mobile": 919640517484,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nalgonda",
  "Name": "d balunaik",
  "Mobile": 919849747003,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "eluka shashi kumar",
  "Mobile": 919704589758,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bhadradri Kothagudem",
  "Name": "gummala purnachander",
  "Mobile": 919494323046,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Nalgonda",
  "Name": "im",
  "Mobile": 919014908377,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Mancherial",
  "Name": "karthik",
  "Mobile": 8519967584,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jagitial",
  "Name": "murali gajjala",
  "Mobile": 919652030956,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Nalgonda",
  "Name": "n jithendar reddy",
  "Mobile": 919963139077,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "pidugu shravan",
  "Mobile": 919014039416,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Warangal Rural",
  "Name": "raj",
  "Mobile": 916001505287,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "raj kumar",
  "Mobile": 918919571092,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "rajender",
  "Mobile": 918888384268,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jayashankar Bhupalapally",
  "Name": "rakesh banala",
  "Mobile": 919948255514,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Khammam",
  "Name": "shaik afroz",
  "Mobile": 919912514101,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Ranga Reddy",
  "Name": "srikanth",
  "Mobile": 917207391676,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "srikanth",
  "Mobile": 919391235339,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Bhadradri Kothagudem",
  "Name": "tejavath bhaskar",
  "Mobile": 919704729220,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "theegala sairam",
  "Mobile": 919398555467,
  "State": "Telangana",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Daman",
  "Name": "bill gates raj kapoor amitabh dilip kumar",
  "Mobile": 919622831933,
  "State": "The Dadra And Nagar Haveli And Daman And Diu",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dadra And Nagar Haveli",
  "Name": "xxx",
  "Mobile": 916389260640,
  "State": "The Dadra And Nagar Haveli And Daman And Diu",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "alam",
  "Mobile": 919354022169,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ASHEET KUMAR",
  "Mobile": 7754842604,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Avanendra dubay",
  "Mobile": 8707850541,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Dipesh",
  "Mobile": 9935694681,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "divyam singh",
  "Mobile": 7983372460,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Fakruddin Ansari",
  "Mobile": 9936598512,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Farman ilahi",
  "Mobile": 9580818599,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Gopal hi jaiswal",
  "Mobile": 9628180970,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "GOPAL UPADHYAY",
  "Mobile": 9450615076,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Hemraj khujwar",
  "Mobile": 9199744076,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Hira lal",
  "Mobile": 7800364876,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Fatehpur",
  "Name": "i",
  "Mobile": 918601563582,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "INDU PRAKASH",
  "Mobile": 9919322533,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "JITENDRA SINGH",
  "Mobile": 9415810744,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Bijnor",
  "Name": "jogendra singh",
  "Mobile": 918920402812,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "JUGANU PATEL",
  "Mobile": 8840759976,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "KRISHNA KUMAR TIWARI",
  "Mobile": 9454053564,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "MAHENDRA UPADHYAY",
  "Mobile": 9621879739,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MANOJ GUPTA",
  "Mobile": 9934971212,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MANOJ KUMAR SIGH",
  "Mobile": 9559219999,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MOKHTAR YADAV",
  "Mobile": 8840953933,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MUNNA YADAV",
  "Mobile": 8004251867,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Munna yadav",
  "Mobile": 8090607856,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "PREM BHADUR THAPA",
  "Mobile": 9118438225,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RADHESHYAM SHARMA",
  "Mobile": 9936240145,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Raj kumar ram",
  "Mobile": 9006117962,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJENDRA KUMAR",
  "Mobile": 8083005211,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJENDRA PRASAD PATEL",
  "Mobile": 8299459772,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravindra verma",
  "Mobile": 9838297479,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Ayodhya",
  "Name": "rohit kumar gupta",
  "Mobile": 918795579899,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SANJIV MISHRA",
  "Mobile": 9415812519,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANTOSH KR MISHRA",
  "Mobile": 9336156524,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANTOSH KUMAR GUPTA",
  "Mobile": 9935408674,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SAURABH KUMAR SINGH",
  "Mobile": 9452513305,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shahdulha ansari",
  "Mobile": 7897464858,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Moradabad",
  "Name": "shanu khan",
  "Mobile": 966573232510,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SHIVA KANT UPADHYAY",
  "Mobile": 6386431096,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SHIVPUJAN",
  "Mobile": 9450425553,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SHYAM BHAHADUR VERMA",
  "Mobile": 8808995494,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SIDDHESHWAR PRASAD",
  "Mobile": 9450245454,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SIDDIKA BANO",
  "Mobile": 9118153679,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Hamirpur",
  "Name": "sonu",
  "Mobile": 918081774502,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SUNIL SONKAR",
  "Mobile": 8707667161,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SURENDRA GUPTA",
  "Mobile": 7523861858,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SURENDRA MOHAN PANDEY",
  "Mobile": 8562928931,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "UMESH SINGH",
  "Mobile": 9935660751,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "VIJAY PAL",
  "Mobile": 7275643494,
  "State": "Uttar Pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "अमित",
  "Mobile": 918869907358,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "विपिन कुमार",
  "Mobile": 918859358020,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "संजय माहौर",
  "Mobile": 917078446098,
  "State": "Uttar pradesh",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Almora",
  "Name": "pooran chandra sati",
  "Mobile": 919411703332,
  "State": "Uttarakhand",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Dehradun",
  "Name": "विवेक सेमवाल",
  "Mobile": 919045507090,
  "State": "Uttarakhand",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Paschim Bardhaman",
  "Name": "ashish bauri",
  "Mobile": 918158803194,
  "State": "West bengal",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Paschim Bardhaman",
  "Name": "kunal",
  "Mobile": 918972567458,
  "State": "West bengal",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Jalpaiguri",
  "Name": "kushal bara",
  "Mobile": 918637040838,
  "State": "West bengal",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "24 Paraganas North",
  "Name": "rajesh paul",
  "Mobile": 918961684841,
  "State": "West bengal",
  "Skill": "Rigger",
  "Company": "Tata Project"
 },
 {
  "District": "Anantapur",
  "Name": "g veeresh",
  "Mobile": 8897799428,
  "State": "Andhra Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Srikakulam",
  "Name": "srikakulam",
  "Mobile": 917095416692,
  "State": "Andhra Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abdul alim",
  "Mobile": 919101583495,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul hanif ahmed",
  "Mobile": 916003829014,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "amio das",
  "Mobile": 919957449432,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "amir hussain",
  "Mobile": 918217037925,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "anamat ali",
  "Mobile": 919365397203,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Chirang",
  "Name": "anowar ali",
  "Mobile": 8638117004,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "anuwar hussain",
  "Mobile": 9365157550,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "arsan ali",
  "Mobile": 916000294677,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "arun gogoi",
  "Mobile": 916001489325,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "ashan ali",
  "Mobile": 919531075149,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "atowar rahman",
  "Mobile": 918256023936,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "basanta kr das",
  "Mobile": 916003128921,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "bhaskar boruah",
  "Mobile": 916002365308,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bijoy roy",
  "Mobile": 917002495576,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "bipul chakraborty",
  "Mobile": 919366063122,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "bipul gogoi",
  "Mobile": 916000596205,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "bishojit",
  "Mobile": 917086088022,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "budhan paul",
  "Mobile": 919741089793,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "bulbul ahmed",
  "Mobile": 917002366101,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "champak barman",
  "Mobile": 918638779014,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "debojit das",
  "Mobile": 916000722504,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "deep jyoti thakuria",
  "Mobile": 919365640994,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "deeplu saikia",
  "Mobile": 918876560814,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "dipen dihingia",
  "Mobile": 918011054889,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "DHEMAJI",
  "Name": "duleswar sonowal",
  "Mobile": 919074550702,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "electicion kanak bora",
  "Mobile": 919365497402,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "emran nazir",
  "Mobile": 6003930244,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "gobindra sonowal",
  "Mobile": 919101259059,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "hemakanta doley",
  "Mobile": 917099716405,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "hirak jyoti das",
  "Mobile": 919101943469,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "jagadish ch borah",
  "Mobile": 918486336852,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jakir",
  "Mobile": 917698311485,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "jayanta baruah",
  "Mobile": 919365096183,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "jiten munda",
  "Mobile": 919313657258,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "jitendra singh",
  "Mobile": 917002123339,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "jitul changmai",
  "Mobile": 919954707980,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "jitul sonowal",
  "Mobile": 919707448452,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "john baghuwar",
  "Mobile": 919101080719,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "joy debnath",
  "Mobile": 917896574874,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "kanak rabha",
  "Mobile": 919079975053,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lilamon changmai",
  "Mobile": 917086110205,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "mafijul islam",
  "Mobile": 919089604706,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "mahidul islam",
  "Mobile": 917002674751,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "mandeep baruah",
  "Mobile": 918876333505,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "manirul islam",
  "Mobile": 918638294960,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "manna das",
  "Mobile": 918638934681,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "mapurna rabha",
  "Mobile": 916001270646,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md inamul haque",
  "Mobile": 918134060334,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "mintu bora",
  "Mobile": 919854426267,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "mintu kachari",
  "Mobile": 919365614198,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "mominur isalm",
  "Mobile": 917636960889,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "mondip kalita",
  "Mobile": 919954077101,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "monjil saikia",
  "Mobile": 919101420350,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "mridu paban chetia",
  "Mobile": 917638836757,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "mriganka phukon",
  "Mobile": 919365922272,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "mukti das",
  "Mobile": 919676107150,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "nayan jyoti das",
  "Mobile": 919678562822,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "nayan moni saikia",
  "Mobile": 8011953851,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "nipu gogoi",
  "Mobile": 918473886232,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "nipun tamuli",
  "Mobile": 919101613393,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "norendra damudor modi",
  "Mobile": 8638977636,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "nurul",
  "Mobile": 918638003737,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "pabitra gogoi",
  "Mobile": 916379968729,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "pallab bora",
  "Mobile": 918822484481,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "pankaj borah",
  "Mobile": 919101493991,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "papu handique",
  "Mobile": 916002684527,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "pradip das",
  "Mobile": 917635878921,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "pranab",
  "Mobile": 918822674108,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "pranab  pachani",
  "Mobile": 919954606076,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "prasanta gogoi",
  "Mobile": 919957022207,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "prosenjit gogoi",
  "Mobile": 916900535855,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "rabin pradhan",
  "Mobile": 917637891849,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "rafiqul islam",
  "Mobile": 918011253959,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "rahul hanse",
  "Mobile": 916900379928,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "rajeshkumarpaswan",
  "Mobile": 918486535130,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "rajib mech",
  "Mobile": 919678565132,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "ranjan acharjee",
  "Mobile": 919365957543,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "rashidul hoque",
  "Mobile": 919101780114,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "rijen bora",
  "Mobile": 916901395689,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rofikul",
  "Mobile": 919365466025,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "rupak acharjee",
  "Mobile": 919686538608,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sahadev sarkar",
  "Mobile": 919954645240,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "saidur rahman",
  "Mobile": 916382736196,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "samsouddin ahmed",
  "Mobile": 918638408042,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "DHEMAJI",
  "Name": "sanjib",
  "Mobile": 918473071018,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "sanjit",
  "Mobile": 918720946450,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "sanjit singha",
  "Mobile": 918471989793,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjoy das",
  "Mobile": 919101328608,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "shamu das",
  "Mobile": 917577959637,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "shehabur rehman choudhury",
  "Mobile": 6001737817,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "shikha swargiary teron",
  "Mobile": 918471912355,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sri jwngdaw brahma",
  "Mobile": 916002960890,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri runu kaibarta",
  "Mobile": 916001138817,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "sri rupjoy hasam",
  "Mobile": 918197474437,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sri sarat borah",
  "Mobile": 918812830321,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sri shurajit dutta",
  "Mobile": 918011084664,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "subhan rovi",
  "Mobile": 919101205235,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sujit chandra das",
  "Mobile": 919340354231,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "tapan talukdar",
  "Mobile": 918486464090,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "MARIGAON",
  "Name": "tarun deka",
  "Mobile": 917896338772,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ujjal dutta",
  "Mobile": 918638859206,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "uttam das",
  "Mobile": 918011146856,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "zahid ekbal",
  "Mobile": 917099731359,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "जीवन बोर्डलों",
  "Mobile": 918867207583,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "बिजन दे",
  "Mobile": 917085639691,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "मेरा नाम अनंता नियोग है",
  "Mobile": 919613847311,
  "State": "Assam",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Siwan",
  "Name": "devendr kumar",
  "Mobile": 917323836286,
  "State": "Bihar",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Samastipur",
  "Name": "s k sahani",
  "Mobile": 9614133079,
  "State": "Bihar",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "gaurav",
  "Mobile": 917742599553,
  "State": "Delhi",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "New Delhi",
  "Name": "ranjit sing",
  "Mobile": 9852274047,
  "State": "Delhi",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kheda",
  "Name": "kyo",
  "Mobile": 919601956323,
  "State": "Gujarat",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "NAVSARI",
  "Name": "nilesh",
  "Mobile": 918347555180,
  "State": "Gujarat",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Gir Somnath",
  "Name": "rohit joshi",
  "Mobile": 9773206944,
  "State": "Gujarat",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Fatehabad",
  "Name": "manoj kumar",
  "Mobile": 919991013681,
  "State": "Haryana",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Fatehabad",
  "Name": "suman",
  "Mobile": 8572870344,
  "State": "Haryana",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Mysuru",
  "Name": "ghanashyam",
  "Mobile": 916360572972,
  "State": "Karnataka",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kozhikode",
  "Name": "athul k",
  "Mobile": 919961138973,
  "State": "Kerala",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kollam",
  "Name": "lalkrishna m b",
  "Mobile": 918137925545,
  "State": "Kerala",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "shaji",
  "Mobile": 9746278300,
  "State": "Kerala",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Kollam",
  "Name": "shibu",
  "Mobile": 919539796840,
  "State": "Kerala",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Alappuzha",
  "Name": "vineeth",
  "Mobile": 8590263873,
  "State": "Kerala",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Mumbai",
  "Name": "akshay suresh gangan",
  "Mobile": 918898570315,
  "State": "Maharashtra",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Thane",
  "Name": "mithilesh kumar",
  "Mobile": 918969276327,
  "State": "Maharashtra",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "navinkumar brijeshkumar tiwari",
  "Mobile": 919158944839,
  "State": "Maharashtra",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Sindhudurg",
  "Name": "vrushal vijay indap",
  "Mobile": 919356963301,
  "State": "Maharashtra",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Gajapati",
  "Name": "dasari rahul",
  "Mobile": 919777258199,
  "State": "Odisha",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "ponnam saipranay",
  "Mobile": 919966199730,
  "State": "Telangana",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "tirupati rao",
  "Mobile": 918464029178,
  "State": "Telangana",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ajit dubay",
  "Mobile": 9415690595,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Arjun yadav",
  "Mobile": 8858091515,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ARVIND KUMAR",
  "Mobile": 9506511280,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Atma prakash Singh",
  "Mobile": 9335411320,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Bholashankar patel",
  "Mobile": 8423855264,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Chandrama Singh",
  "Mobile": 7525037146,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Devendra tiwari",
  "Mobile": 9919268328,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Divyanshu Keshari",
  "Mobile": 6649482202,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ghanshyam verma",
  "Mobile": 9794559729,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Bulandshahr",
  "Name": "kaushal",
  "Mobile": 919457616107,
  "State": "Uttar pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "kaushal sharma",
  "Mobile": 9698658525,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Kishor prasad",
  "Mobile": 9973306913,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Mahendra Singh",
  "Mobile": 9415861265,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Deoria",
  "Name": "mahesh thakur",
  "Mobile": 97455385993,
  "State": "Uttar pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "PANKAJ KUMAR SRIVASTAV",
  "Mobile": 9935185793,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJENDRA PRASAD ARYA",
  "Mobile": 9532961417,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAMCHANDRA YADAV",
  "Mobile": 9839045317,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rammurat maurya",
  "Mobile": 8808010537,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAVINDRA PRASAD",
  "Mobile": 7052770550,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY KUMAR",
  "Mobile": 6393706891,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY TRIPATHI",
  "Mobile": 6965323658,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Jaunpur",
  "Name": "santosh kumar patel",
  "Mobile": 918726101810,
  "State": "Uttar pradesh",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Santosh singh",
  "Mobile": 9616234111,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Sher md khan",
  "Mobile": 6388095389,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SHIV SHANKAR",
  "Mobile": 7380544348,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SHRINATH JI SHARMA",
  "Mobile": 9335533667,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SUNIL KUMAR SHARMA",
  "Mobile": 8795813734,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "suresh yadav",
  "Mobile": 9918404316,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Meerut",
  "Name": "talib",
  "Mobile": 917895102908,
  "State": "Uttar pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Umang Sharma",
  "Mobile": 8807642555,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Vinay Singh",
  "Mobile": 9721263563,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "VINEY KUMAR MAURYA",
  "Mobile": 8726554504,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "VINOD KUMAR",
  "Mobile": 9415989514,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "vinodi tiwari",
  "Mobile": 8857843548,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "VIVEK SHANKAR SINHA",
  "Mobile": 9450240790,
  "State": "Uttar Pradesh",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "24 Paraganas North",
  "Name": "ravi kant pathak",
  "Mobile": 917439503008,
  "State": "West bengal",
  "Skill": "Welders",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "krishna rao",
  "Mobile": 919010484033,
  "State": "Andhra Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "u rajeshkumar",
  "Mobile": 919177108496,
  "State": "Andhra Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Prakasam",
  "Name": "venkata kiran chikkudu",
  "Mobile": 917569773041,
  "State": "Andhra Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Upper Subansiri",
  "Name": "ranjan phukan",
  "Mobile": 919612667628,
  "State": "Arunachal Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abdul ajij",
  "Mobile": 916003292219,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul hannan hague",
  "Mobile": 917576823498,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdul sajid",
  "Mobile": 919738182242,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "abdul salam sk",
  "Mobile": 917002894796,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "abhijit rajbongshi",
  "Mobile": 916000473305,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abul hussain",
  "Mobile": 917002333320,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ajay borman",
  "Mobile": 916002006289,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "al imran sheikh",
  "Mobile": 918822658126,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "animesh haloi",
  "Mobile": 919577173169,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anowar ali",
  "Mobile": 916000807391,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "anowar hussain",
  "Mobile": 916026564765,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ansarul islam",
  "Mobile": 916300623495,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "ashadulla",
  "Mobile": 918638669435,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "ashibul hoque",
  "Mobile": 919101192094,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "asif jaman",
  "Mobile": 9957596702,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "atibar rahman",
  "Mobile": 916001062770,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "aynul mir",
  "Mobile": 919365067877,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "babul baishya",
  "Mobile": 916001632955,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bachan nayak",
  "Mobile": 918348885487,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "banobashi das",
  "Mobile": 916001562804,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "baten ali",
  "Mobile": 916001777631,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bidyut boruah",
  "Mobile": 7896219692,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "bipul senapoti",
  "Mobile": 917569997127,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bitu urang",
  "Mobile": 918825474790,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "dhanjit mandal",
  "Mobile": 919954595329,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "diganta boruah",
  "Mobile": 919085348930,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "dipankar gogoi",
  "Mobile": 918639055525,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "dudul moni deka",
  "Mobile": 916002490356,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "fulbar rahman",
  "Mobile": 9678792546,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "gaurab chakraborty",
  "Mobile": 916001890858,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "gautom laskar",
  "Mobile": 916002978430,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "gonesh chetry",
  "Mobile": 919366128431,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "guddu thakur",
  "Mobile": 919706239777,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "imran hussain laskar",
  "Mobile": 916002085572,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jagnnath kumar das",
  "Mobile": 916002181201,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jahurul islam",
  "Mobile": 919957584822,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "jiadur rahman khan",
  "Mobile": 916001825715,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jiaur islam",
  "Mobile": 919645685178,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "jibeswar das",
  "Mobile": 919707445892,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jinnat ali",
  "Mobile": 919101871355,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "NAGAON",
  "Name": "keshab ch sarma baruah",
  "Mobile": 919613063803,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Chirang",
  "Name": "khanjal nath",
  "Mobile": 918360068384,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "kushal kumar ray",
  "Mobile": 918474072247,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakshya gogoi",
  "Mobile": 916000839194,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "liton saha",
  "Mobile": 7086449693,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "manoj chakraborty",
  "Mobile": 917896047810,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "mayan ali",
  "Mobile": 917002042860,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "milan rajbongshi",
  "Mobile": 919613614805,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "mintu",
  "Mobile": 918134012755,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "mizanur rahman",
  "Mobile": 919678209826,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "mohibuddin ansari",
  "Mobile": 919072271009,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "mon mohan koch",
  "Mobile": 6900639302,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "muktar hussain",
  "Mobile": 918471899832,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mukunda das",
  "Mobile": 916000042013,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "my name is manir ali",
  "Mobile": 918822354304,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "nabiur rahman",
  "Mobile": 917002101425,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "nikunja sarkar",
  "Mobile": 919954435495,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "nur jamal",
  "Mobile": 918761803346,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "paban mandal",
  "Mobile": 919085565406,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "palash chetia",
  "Mobile": 918472915334,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "pijush kanti gogoi",
  "Mobile": 918136042408,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "pinku deka",
  "Mobile": 919101581669,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "piyanka",
  "Mobile": 918134993841,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta ch roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "prasanta chandra roy",
  "Mobile": 917896094330,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rafiqul islam",
  "Mobile": 9022483574,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "rahul gurung",
  "Mobile": 916001093491,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "rajib bordoloi",
  "Mobile": 916238793053,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "rajib mili",
  "Mobile": 919957029050,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "ram krishna malo",
  "Mobile": 918723990010,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ranjanjyoti gogoi",
  "Mobile": 918134977553,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ranjit kumar das",
  "Mobile": 916002877753,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ritu das",
  "Mobile": 918978076812,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rituparna borah",
  "Mobile": 918011700850,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rokibul hoque",
  "Mobile": 918134021904,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "sahar",
  "Mobile": 919707532505,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "saidur rahman",
  "Mobile": 916000499025,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saiful",
  "Mobile": 919707358015,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "samar chandra karmakar",
  "Mobile": 916003210372,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sanidul ahmed",
  "Mobile": 918876055420,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "shahibul hoque",
  "Mobile": 917896201650,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shukla biswas",
  "Mobile": 6901103517,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "simanta bora",
  "Mobile": 6000308174,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sir babul biswas",
  "Mobile": 918638669533,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sobidul hoque bilasipara",
  "Mobile": 918876209136,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sudhan",
  "Mobile": 916000082992,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sukla",
  "Mobile": 916901103517,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "surajit talukdar",
  "Mobile": 919101602087,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "surendra sharma",
  "Mobile": 919591098701,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "taizuddin",
  "Mobile": 919365453651,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "thunu gogoi",
  "Mobile": 919360027950,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tulan panging",
  "Mobile": 916002102802,
  "State": "Assam",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Begusarai",
  "Name": "bipin kumar sharma",
  "Mobile": 917280994871,
  "State": "Bihar",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "New Delhi",
  "Name": "akhilesh",
  "Mobile": 7289925395,
  "State": "Delhi",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "saddam saifi",
  "Mobile": 919927456292,
  "State": "Delhi",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Mandi",
  "Name": "tirth ram",
  "Mobile": 919816617658,
  "State": "Himachal pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Ballari",
  "Name": "ali",
  "Mobile": 918553778986,
  "State": "Karnataka",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Uttara Kannada",
  "Name": "eshwar",
  "Mobile": 916362071932,
  "State": "Karnataka",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "jugal das",
  "Mobile": 916362197168,
  "State": "Karnataka",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "ashil k r",
  "Mobile": 919744733785,
  "State": "Kerala",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Malappuram",
  "Name": "bharathan",
  "Mobile": 919747292875,
  "State": "Kerala",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "krishna",
  "Mobile": 918011867325,
  "State": "Kerala",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Washim",
  "Name": "shankarlal",
  "Mobile": 918407970978,
  "State": "Maharashtra",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Dhule",
  "Name": "किरण भाऊ लोहार",
  "Mobile": 917741885956,
  "State": "Maharashtra",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "महेंद्र देवासी",
  "Mobile": 919373185732,
  "State": "Maharashtra",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Churu",
  "Name": "anil kumar soni",
  "Mobile": 917737735697,
  "State": "Rajasthan",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KANYAKUMARI",
  "Name": "rajesh",
  "Mobile": 916369037584,
  "State": "Tamil Nadu",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "KANCHIPURAM",
  "Name": "saksham",
  "Mobile": 918883734639,
  "State": "Tamil Nadu",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Karimnagar",
  "Name": "prasad kotagiri",
  "Mobile": 918106102386,
  "State": "Telangana",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "tinku dutta",
  "Mobile": 918498842540,
  "State": "Telangana",
  "Skill": "Pipe Fitter",
  "Company": "Other"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "ajay",
  "Mobile": 919990250020,
  "State": "Uttar pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Azamgarh",
  "Name": "alpesh saroj",
  "Mobile": 918377810548,
  "State": "Uttar pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ANIL KUMAR SINGH",
  "Mobile": 8601315862,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ARJUN RAM",
  "Mobile": 9452268733,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "BASHEER HAIDAR",
  "Mobile": 9026619704,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Dhaneshwar gupta",
  "Mobile": 6393706891,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "mohd javed",
  "Mobile": 9451579719,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Mukund Sharma",
  "Mobile": 8188834521,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "PANNALAL YADAV",
  "Mobile": 9129226399,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rajendra prasad",
  "Mobile": 8601447391,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "raju rathore",
  "Mobile": 917060991075,
  "State": "Uttar pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAKESH GIRI",
  "Mobile": 9450816530,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh singh",
  "Mobile": 9935289443,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh tiwari",
  "Mobile": 9695326169,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramlakhan",
  "Mobile": 9580517757,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramnagina patel",
  "Mobile": 9305125780,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravi Shankar shukla",
  "Mobile": 7839142788,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAVINDRA NATH TIWARI",
  "Mobile": 9336908205,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ravish srivastav",
  "Mobile": 7651912751,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Sanjay Gupta",
  "Mobile": 8569865425,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY VISHWAKARMA",
  "Mobile": 9506383322,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SANJIV SINGH",
  "Mobile": 8115305484,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "shailesh",
  "Mobile": 919561439110,
  "State": "Uttar pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shailesh kumar mishra",
  "Mobile": 8756148070,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shubham Pandey",
  "Mobile": 9548675283,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Vineet",
  "Mobile": 9015291893,
  "State": "Uttar Pradesh",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Alipurduar",
  "Name": "bishnu",
  "Mobile": 917477738268,
  "State": "West bengal",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Jalpaiguri",
  "Name": "niranjan sarkar",
  "Mobile": 919064011649,
  "State": "West bengal",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Purulia",
  "Name": "pradip das mahanta",
  "Mobile": 918327005447,
  "State": "West bengal",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Coochbehar",
  "Name": "rajeswar barman",
  "Mobile": 9085841235,
  "State": "West bengal",
  "Skill": "Pipe Fitter",
  "Company": "Tata Project"
 },
 {
  "District": "Chittoor",
  "Name": "a narasimhulu",
  "Mobile": 919000656255,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Prakasam",
  "Name": "anand",
  "Mobile": 916305929645,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Krishna",
  "Name": "banavathu prasada rao",
  "Mobile": 919000968051,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Krishna",
  "Name": "bharothu ramesh",
  "Mobile": 9618044218,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Godavari",
  "Name": "golla naresh",
  "Mobile": 9490935344,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Guntur",
  "Name": "kale swapna",
  "Mobile": 917815883686,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "lakhan bhattarai",
  "Mobile": 918754117139,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Visakhapatanam",
  "Name": "madagala raju",
  "Mobile": 918186803793,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Srikakulam",
  "Name": "manmadha",
  "Mobile": 918790408532,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Guntur",
  "Name": "paparao",
  "Mobile": 918686281919,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kurnool",
  "Name": "raghavendra",
  "Mobile": 919603396090,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Krishna",
  "Name": "rajini sanaka",
  "Mobile": 919948023040,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Guntur",
  "Name": "ramavath bangaru babu naik",
  "Mobile": 918465864990,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "East Godavari",
  "Name": "roja",
  "Mobile": 918179607875,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Guntur",
  "Name": "sai kanaparthi",
  "Mobile": 919703076093,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Guntur",
  "Name": "sai kumar reddy",
  "Mobile": 919490266620,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Guntur",
  "Name": "sudhakar mallela",
  "Mobile": 917013764793,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Spsr Nellore",
  "Name": "suresh",
  "Mobile": 918919660230,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "East Godavari",
  "Name": "usha",
  "Mobile": 919848285352,
  "State": "Andhra Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Namsai",
  "Name": "gamphong singpho",
  "Mobile": 918974906048,
  "State": "Arunachal Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Papum Pare",
  "Name": "sapan mandal",
  "Mobile": 918794490710,
  "State": "Arunachal Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "à¤ªà¤¿à¤‚à¤•à¥‚ à¤¨à¤°à¥à¤œà¤¾à¤°à¥€",
  "Mobile": 918638721128,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "abbas ali",
  "Mobile": 916398121316,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KOKRAJHAR",
  "Name": "abbas ali khan",
  "Mobile": 919101048895,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abdan sanga",
  "Mobile": 916002498612,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "abdul gani",
  "Mobile": 916003347966,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "abdul hamid khan",
  "Mobile": 917429683265,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "abdul hannan ahmed",
  "Mobile": 918473029342,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "abdul hasim",
  "Mobile": 919678656274,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "abdul hnnan",
  "Mobile": 916909784983,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "abdul jolil",
  "Mobile": 916001562701,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "abdul kader",
  "Mobile": 919864821696,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "abdul kamal",
  "Mobile": 916001536945,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "abdul karim",
  "Mobile": 918638036040,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "abdul khaleque ali",
  "Mobile": 919707630446,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "HAILAKANDI",
  "Name": "abdul mannaf barbhuiya",
  "Mobile": 6000420362,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdul mannaf sheikh",
  "Mobile": 919678585065,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "abdul mannan",
  "Mobile": 919854713922,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abdul mutalib",
  "Mobile": 919577042151,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abdul rashid",
  "Mobile": 919370943067,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "abdul sahid",
  "Mobile": 917005063090,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abdur jubbar",
  "Mobile": 919101229755,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "abdur razzaque",
  "Mobile": 9101527383,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abdus  salam",
  "Mobile": 917086405785,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "abdus salam",
  "Mobile": 916002427328,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "abdus salam",
  "Mobile": 918099545187,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "abhijit",
  "Mobile": 917002898381,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "abhijit baishya",
  "Mobile": 917577810941,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abhijit gogoi",
  "Mobile": 916001560457,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "abhijit kalita",
  "Mobile": 919101347041,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "abhijit lahon",
  "Mobile": 9740385524,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "abhishek bori",
  "Mobile": 919101073874,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "abidur rahman",
  "Mobile": 917002981429,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "abika tudu",
  "Mobile": 919633562932,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "abinash patowary",
  "Mobile": 919365643548,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "JORHAT",
  "Name": "abinash sarma",
  "Mobile": 918822952056,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abu hanif",
  "Mobile": 918011963273,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "abu hayat montasir alam",
  "Mobile": 916001067575,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "abu kalam",
  "Mobile": 919365584062,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abu sufiyan",
  "Mobile": 918638357969,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "abu taleb",
  "Mobile": 919085750491,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "abu taleb sk",
  "Mobile": 916002327175,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "abul hussain",
  "Mobile": 917002924168,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abul hussain",
  "Mobile": 919126564696,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "abul kalam",
  "Mobile": 919365573633,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "abul kalam azad",
  "Mobile": 9957026911,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "abulkalam azad",
  "Mobile": 918472073735,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "MARIGAON",
  "Name": "adarsha sarma",
  "Mobile": 916901490741,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "aehsanul karim choudhury",
  "Mobile": 917099992647,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "afjalur rahman",
  "Mobile": 918129501805,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "afsar ali",
  "Mobile": 917002246764,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "ahashan habib",
  "Mobile": 918471816646,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "ahedul islam laskar",
  "Mobile": 917002071507,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "ahmed hussain mazumder",
  "Mobile": 916001213661,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "ajay bhuyan",
  "Mobile": 918134972055,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "ajay surikapali",
  "Mobile": 919957796196,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ajibul haque",
  "Mobile": 918011778588,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ajijul hoque",
  "Mobile": 918011297349,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "ajijur rahman",
  "Mobile": 916000059820,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "ajit kr nath",
  "Mobile": 916000706595,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "ajitabh pegu",
  "Mobile": 919373489710,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "ajitbiswas",
  "Mobile": 919972919710,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "ajodhya doley",
  "Mobile": 917002366137,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "ajoy gogoi",
  "Mobile": 917577098523,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "ajoy gorh",
  "Mobile": 918724917876,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "ajoy newar",
  "Mobile": 917002433501,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "ajoy rojok",
  "Mobile": 917002654906,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "SONITPUR",
  "Name": "akan das",
  "Mobile": 918861234759,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "akash",
  "Mobile": 918472978668,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "akash das",
  "Mobile": 919365176004,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "akash sonowal",
  "Mobile": 918134913911,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "HAILAKANDI",
  "Name": "akhil singha",
  "Mobile": 919315511214,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "akhil talukdar",
  "Mobile": 919365455670,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "akhim gogoi",
  "Mobile": 918822785784,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "akhim kalita",
  "Mobile": 918753918007,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "akhirul hoque",
  "Mobile": 919365474153,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "akibul islam",
  "Mobile": 916002587618,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "akimueddine ali",
  "Mobile": 919957398182,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "akramul hoque",
  "Mobile": 919954781549,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "akramul hussain",
  "Mobile": 916003906649,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "alauddin sheikh",
  "Mobile": 919101216377,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "alep ali",
  "Mobile": 917002236819,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "ali hussain",
  "Mobile": 9531298625,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "ali hussain",
  "Mobile": 918724934279,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "aliul islam",
  "Mobile": 919957785353,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "aliza khatun",
  "Mobile": 919954598437,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "almina khatun",
  "Mobile": 8486945668,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "alok nath",
  "Mobile": 917577867293,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "alom ali",
  "Mobile": 919365218537,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "alom nur sheikh",
  "Mobile": 919957055996,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "altab hussain",
  "Mobile": 916001597839,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "amal barman",
  "Mobile": 918129170010,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "amal kalita",
  "Mobile": 919678627948,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "amal saikia",
  "Mobile": 919101932186,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "amanur islam",
  "Mobile": 919101240941,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "amar jyoti duworah",
  "Mobile": 916003861541,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "amar khan",
  "Mobile": 916002273014,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "amarjit bordoloi",
  "Mobile": 9864997990,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "amarjyoti boruah",
  "Mobile": 916001768957,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "amarjyoti nath",
  "Mobile": 918011328849,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "amdadul hoque",
  "Mobile": 918876359906,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "aminul haque",
  "Mobile": 916000060337,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "aminul hasan",
  "Mobile": 919101826604,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "aminul hoque",
  "Mobile": 917002343790,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "aminul islam",
  "Mobile": 918761039597,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "aminur islam",
  "Mobile": 916003020885,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "aminur islam",
  "Mobile": 919864268408,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "aminur islam sheikh",
  "Mobile": 918876756862,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "amir hamja",
  "Mobile": 918638279936,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "amir hussain",
  "Mobile": 916901548432,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "amir hussain",
  "Mobile": 918296486147,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "amit basumatary",
  "Mobile": 918136047383,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "amit das",
  "Mobile": 916900360694,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "amit kurmi",
  "Mobile": 918486961164,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "amiya kumar bania",
  "Mobile": 919307660521,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "amiyo das",
  "Mobile": 916003979238,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "amjad ali barbhuiya",
  "Mobile": 917896551789,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "amor gowala",
  "Mobile": 917769011297,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "amrit boro",
  "Mobile": 917002488113,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "amrit chetry",
  "Mobile": 919101984956,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "amrit gurung",
  "Mobile": 9108672564,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "anamika boruah",
  "Mobile": 9954991741,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "ananda das",
  "Mobile": 916002651787,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "ananda das",
  "Mobile": 919957406137,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "anarul islam",
  "Mobile": 919678416668,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "anil das",
  "Mobile": 918876339435,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "anil munda",
  "Mobile": 919864927695,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "anima gowala",
  "Mobile": 919380802994,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "anip kaschap",
  "Mobile": 919101128129,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anisur rahman",
  "Mobile": 916003148178,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "anita sharma hai",
  "Mobile": 916001828430,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "anjali engtipi",
  "Mobile": 919101495197,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "anjana",
  "Mobile": 918638146934,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "anjani gogoi borah",
  "Mobile": 917086217397,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "anjoor hussain",
  "Mobile": 918402886208,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "ankita hazarika",
  "Mobile": 917086346755,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "ankur borah",
  "Mobile": 917099228079,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ankur moni bora",
  "Mobile": 916900972195,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "anowar hossain",
  "Mobile": 917002766008,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "anowar hossain",
  "Mobile": 918402813843,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "anowar hussain",
  "Mobile": 917002425228,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "anowar hussain",
  "Mobile": 917907185317,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "anowar islam",
  "Mobile": 8761088945,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "anowarhussain",
  "Mobile": 916002578963,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "anshuman borgohain",
  "Mobile": 916901045353,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "ansula basumatary",
  "Mobile": 918899396770,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "anukul dey sarkar",
  "Mobile": 918723846242,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "anupam dey",
  "Mobile": 917637879189,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "anupam nath",
  "Mobile": 916003715094,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "anuwar hussain",
  "Mobile": 918811998874,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "appa rishong",
  "Mobile": 918133069983,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "apurba gogoi",
  "Mobile": 919365210791,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "apurba rajbongshi",
  "Mobile": 918135811088,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "apurbo bora",
  "Mobile": 916374900498,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "arati sonowal",
  "Mobile": 916003391155,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "arbaz ahmed",
  "Mobile": 918822028235,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "archana das",
  "Mobile": 918811976253,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "arif ahmed siddike",
  "Mobile": 916002565625,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "arif ali",
  "Mobile": 919957634129,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "arif hussain",
  "Mobile": 917002710452,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "arif hussain",
  "Mobile": 919127855304,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "arindom gogoi",
  "Mobile": 919132830143,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "ariyon das",
  "Mobile": 916000947822,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "arjun barman",
  "Mobile": 918472924813,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "arjun deka",
  "Mobile": 919101723838,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "arobinda borah",
  "Mobile": 916001601815,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "arshad hussin",
  "Mobile": 9101065838,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "MARIGAON",
  "Name": "arshad mullah",
  "Mobile": 919707524478,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "arup",
  "Mobile": 919398558544,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "arup bora",
  "Mobile": 919854640517,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "arup borah",
  "Mobile": 916000289463,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "arup borah",
  "Mobile": 916003241866,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "arup dutta",
  "Mobile": 918403885103,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "arup jyoti nath",
  "Mobile": 916000046906,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "arup kr ray",
  "Mobile": 919101050326,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ashik",
  "Mobile": 919085300245,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ashim das",
  "Mobile": 918876823698,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "ashok chakravarty",
  "Mobile": 919879669259,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "ashok kumar nath",
  "Mobile": 919864714938,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "ashra ful islam",
  "Mobile": 917002777851,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "ashraful islam",
  "Mobile": 916000526842,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ashraful wahid",
  "Mobile": 919678285364,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "asiruddin",
  "Mobile": 918753888461,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "asma",
  "Mobile": 6900422578,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "asmina aktar",
  "Mobile": 918876780333,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "asor ali",
  "Mobile": 918822185080,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "asraful islam",
  "Mobile": 919101563818,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "assam",
  "Mobile": 9101875882,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "ataour rahman",
  "Mobile": 919321366529,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "atul basumatary",
  "Mobile": 917086863150,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "atul chutia",
  "Mobile": 8099617795,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "avinash dutta",
  "Mobile": 919854218408,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "ayen uddin",
  "Mobile": 7483620750,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BAKSA",
  "Name": "ayen uddin",
  "Mobile": 917483620750,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "azizur rahman",
  "Mobile": 917099126316,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "azmat ali barbhuiya",
  "Mobile": 918638295003,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "babendra deka",
  "Mobile": 916000814093,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "babimoon baruah",
  "Mobile": 916002072626,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "babita das",
  "Mobile": 918876668589,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "babu das",
  "Mobile": 916000816949,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "babul baro",
  "Mobile": 919864400395,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "babul borah",
  "Mobile": 916001258393,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "babul borah",
  "Mobile": 916002203156,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "babul dewan",
  "Mobile": 917099175335,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "babul hazarika",
  "Mobile": 919706575925,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "babul khakhlary",
  "Mobile": 917099357721,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "badsha sk",
  "Mobile": 919085180321,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "baharul islam",
  "Mobile": 917896881204,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bandeepa kalita",
  "Mobile": 6000388291,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "bapan",
  "Mobile": 918638781932,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "bapan das",
  "Mobile": 918200658575,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "bapan gowala",
  "Mobile": 916361149321,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "bapon biswas",
  "Mobile": 916003217500,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "bappa kar",
  "Mobile": 919863495208,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "bappa paul",
  "Mobile": 917664819314,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "bappi kurmi",
  "Mobile": 917002955103,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "barasha gogoi",
  "Mobile": 9365481485,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "barnali das",
  "Mobile": 9127323909,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "barnali das",
  "Mobile": 919365702691,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "barpeta road assam",
  "Mobile": 918486576599,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "basanta kutum",
  "Mobile": 916001705276,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "basapi senarpi",
  "Mobile": 7896633075,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "batadrava",
  "Mobile": 916282897329,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "beauty kalita",
  "Mobile": 8402865501,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "bhabajit ray",
  "Mobile": 916003505340,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bhaben boro",
  "Mobile": 918134067207,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "bhabesh deka",
  "Mobile": 919101260525,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "bhabesh saikia",
  "Mobile": 919365381512,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "bhadreswar boro",
  "Mobile": 917086124619,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "bhadreswar nath",
  "Mobile": 918486158033,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "bhagirath rabha",
  "Mobile": 919957774493,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "bhaijan ali",
  "Mobile": 918761951332,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "bhairab gogoi",
  "Mobile": 919365901191,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "bharat raw",
  "Mobile": 919613226049,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "bhaskar das",
  "Mobile": 918472850228,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "bhaskar jyoti das",
  "Mobile": 919132251427,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "bhaskar jyoti pegu",
  "Mobile": 916002854403,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "bhaskarjit boruah",
  "Mobile": 916363001215,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "bhaskarjit boruah",
  "Mobile": 919902033407,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "bhrigu kumar bhuyan",
  "Mobile": 917736585861,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bhrigu moni majumdar",
  "Mobile": 917086851370,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "bhuban lama",
  "Mobile": 919954831694,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "bhuban rabha",
  "Mobile": 919678113481,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "bhuban sharma",
  "Mobile": 916002843157,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bhugeswar deka",
  "Mobile": 919706447833,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bhulen",
  "Mobile": 919707738508,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "bhupen barman",
  "Mobile": 918822080566,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "bhupen tanti",
  "Mobile": 919101034319,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "bidhan chandra nath",
  "Mobile": 917002538461,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "bidhan rongphar",
  "Mobile": 919365250306,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "bidut bora",
  "Mobile": 916900594013,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "bijay rabha",
  "Mobile": 918217655678,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bijit bhuyan",
  "Mobile": 916003141986,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "bijoy rani borah",
  "Mobile": 919854041339,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "bijoy tanti",
  "Mobile": 916002559662,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "biju ray",
  "Mobile": 917896717082,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "bikash baishya",
  "Mobile": 916003235199,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "bikash borah",
  "Mobile": 916003777714,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "bikash boruah",
  "Mobile": 918135969844,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "bikash das",
  "Mobile": 916001563384,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bikash deka",
  "Mobile": 919365091866,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "bikash gogoi",
  "Mobile": 916001771160,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "bikash karmakar",
  "Mobile": 916900365036,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "bikash mandal",
  "Mobile": 919182559915,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "bikash sahu",
  "Mobile": 917896035068,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "bikash sarkar",
  "Mobile": 919832848167,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "bikash terang",
  "Mobile": 919365800028,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "bikram",
  "Mobile": 916003276608,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "bikram ghosh",
  "Mobile": 919435451119,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "bikramjeet bantho",
  "Mobile": 916000128773,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "bilasing doley",
  "Mobile": 917099594612,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "bimal",
  "Mobile": 918812956924,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "SONITPUR",
  "Name": "bimal gorh",
  "Mobile": 916002859674,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "biman paul",
  "Mobile": 919101645764,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "binanda",
  "Mobile": 919398241623,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "binay boro",
  "Mobile": 918753929206,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "binita buragohain",
  "Mobile": 918336987834,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "binita deori",
  "Mobile": 8811823384,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "binitnath",
  "Mobile": 919113517695,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "binjubikash borah",
  "Mobile": 919110831982,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "binod sahariah",
  "Mobile": 916001456538,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "binod teli",
  "Mobile": 918811973629,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "biplab bharali",
  "Mobile": 917399400922,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "biplab hossain",
  "Mobile": 919954916436,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "biplob boro",
  "Mobile": 918473001365,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "biplob buragohain",
  "Mobile": 916009567230,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "biplob chetia",
  "Mobile": 917636018940,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "biplu roy",
  "Mobile": 919854947199,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "biprajit bhattacharjee",
  "Mobile": 919880869366,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "bipul biswas",
  "Mobile": 919706217488,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "bipul kahar",
  "Mobile": 8590671530,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "biren bhoumik",
  "Mobile": 919678402378,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "birendra rabidas",
  "Mobile": 917736681861,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "bisadu ray",
  "Mobile": 919101919970,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "bishal boruah",
  "Mobile": 918134880017,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "bishnu borah",
  "Mobile": 916000607784,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "bishnu chetry",
  "Mobile": 918822171989,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "bishnu das",
  "Mobile": 916000627809,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "bishu das",
  "Mobile": 918811819721,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "biswajit baishya",
  "Mobile": 918812095553,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "biswajit borah",
  "Mobile": 919740795565,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "harjit thakuria",
  "Mobile": 919127143275,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "hasanuj jaman",
  "Mobile": 917483891813,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hasen ali",
  "Mobile": 919101875910,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "hawa hawa",
  "Mobile": 918402939866,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "hayatul islam",
  "Mobile": 919365736780,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "haydar rahman",
  "Mobile": 9365631500,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "hazrat ali",
  "Mobile": 6003598781,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "heart lamare",
  "Mobile": 9957061653,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "heman bayan",
  "Mobile": 8812815308,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hemanta das",
  "Mobile": 917577808517,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "hemanta gogoi",
  "Mobile": 918638234941,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "hemanta gorh",
  "Mobile": 916000980819,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hemanta kalita",
  "Mobile": 919101715319,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "hemanta sarmah",
  "Mobile": 916001039176,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karbi Anglong",
  "Name": "hemari engti",
  "Mobile": 6003897448,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "hemari engti",
  "Mobile": 6003897449,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "MARIGAON",
  "Name": "hii",
  "Mobile": 917994390207,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "hii",
  "Mobile": 917994721992,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "hii",
  "Mobile": 919365942651,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "hii",
  "Mobile": 919706399973,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "himangshu deka",
  "Mobile": 916002729697,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "himangsu boro",
  "Mobile": 916000206896,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "himanshu das",
  "Mobile": 917002014725,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "himansu baruah",
  "Mobile": 919365431114,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "hirak jyoti kalita",
  "Mobile": 916000808709,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "hirak jyoti patar",
  "Mobile": 916003485612,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "hiramoni haloi",
  "Mobile": 6900591206,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hiranya deka",
  "Mobile": 918638914062,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "hiren hazarika",
  "Mobile": 9613068649,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "hirod sora",
  "Mobile": 918721925122,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "hitesh barman",
  "Mobile": 919101961281,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "hitesh deka",
  "Mobile": 919101673209,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "honal ali",
  "Mobile": 9707456327,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "honal ali",
  "Mobile": 917099219627,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "horen barla",
  "Mobile": 919613929967,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "hriday adhikary",
  "Mobile": 918134954195,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "hridaya baruah",
  "Mobile": 918822007089,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "hs pass",
  "Mobile": 919101258580,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "humen patir",
  "Mobile": 918939659972,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "hun",
  "Mobile": 918822618824,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "ibrahim ali",
  "Mobile": 918811807404,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "ibrahim ali ahmad",
  "Mobile": 917636826958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ibrahim khalilullah",
  "Mobile": 917002866178,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "ibrahim mandal",
  "Mobile": 917399467513,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "ijaj ahmed",
  "Mobile": 919613016152,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "ikbal hussain mazumder",
  "Mobile": 916360201780,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "ikram hussain",
  "Mobile": 917002929520,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "ikramul hoque",
  "Mobile": 6000877521,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "imad uddin",
  "Mobile": 916381990586,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "iman ali",
  "Mobile": 919101199067,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "imran hussain",
  "Mobile": 918811903948,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "indra kanta das",
  "Mobile": 918811820043,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "indrajit sarkar",
  "Mobile": 918721969238,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "izajul ali",
  "Mobile": 916001748812,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "izajul ali",
  "Mobile": 918080920774,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "jabir uddin  barbhuiya",
  "Mobile": 918822140651,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "jadab hazarika",
  "Mobile": 919678129407,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "DHEMAJI",
  "Name": "jadu bhuyan",
  "Mobile": 918638765301,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jadu mani kalita",
  "Mobile": 919613257785,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "jadu roy",
  "Mobile": 918943549816,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "UDALGURI",
  "Name": "jafar ali",
  "Mobile": 919365526036,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jagadish das",
  "Mobile": 918721934176,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jagadish das",
  "Mobile": 919864916601,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "jagadish haloi",
  "Mobile": 918136012476,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jagadish haloi",
  "Mobile": 918638541547,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "jagannath dewri",
  "Mobile": 917906552114,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "jagannath ray",
  "Mobile": 918876264834,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "jahanara begum",
  "Mobile": 916000601473,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "jahanara begum",
  "Mobile": 919613360788,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jahangir alam",
  "Mobile": 918399927115,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jahangir alom",
  "Mobile": 919101837038,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "jahangir alom laskar",
  "Mobile": 917019098342,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "jahidul hoque",
  "Mobile": 918473862764,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "jahidul islam",
  "Mobile": 7002984830,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "jahidur rahman",
  "Mobile": 919365845452,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "jahirul islam",
  "Mobile": 916000653642,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jahirul islam",
  "Mobile": 916900014147,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "jahirul islam",
  "Mobile": 917994926310,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jahirul islam",
  "Mobile": 919365362102,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "jairul haque",
  "Mobile": 918638396109,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jakir hussain",
  "Mobile": 916900206117,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "jakir hussain",
  "Mobile": 918011391331,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jakir hussain",
  "Mobile": 919101113807,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "jakir hussain barbhuiya",
  "Mobile": 916001525386,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "jakirul islam",
  "Mobile": 916901939930,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "jamini saikia",
  "Mobile": 917896211297,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "jaminur hussain",
  "Mobile": 919707638096,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jamirul dewan",
  "Mobile": 919136505526,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "jan moni bora",
  "Mobile": 919365472568,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "janab ali",
  "Mobile": 919707826978,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "janardan",
  "Mobile": 918104199498,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "janmejoy das",
  "Mobile": 916238743356,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "january",
  "Mobile": 916002201151,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jashim ahmed",
  "Mobile": 918638738995,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jayanta sharma",
  "Mobile": 918099763673,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jaydev patgiri",
  "Mobile": 916360410260,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "jeeet pegu",
  "Mobile": 918134075243,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jeet pradhan",
  "Mobile": 916381891634,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jeherul haque",
  "Mobile": 918486243159,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "jeherul islam",
  "Mobile": 916001881056,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "jehirul islam",
  "Mobile": 919101339605,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "jerina gogoi",
  "Mobile": 916000706921,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "jeswal daimari",
  "Mobile": 916000502288,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "jewal ali ahmed faruquee",
  "Mobile": 916002821450,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "jhimly paul",
  "Mobile": 919678836003,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "jiabur rahman",
  "Mobile": 918133962585,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "jiban ghorsane",
  "Mobile": 919113914294,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jibiyar surin",
  "Mobile": 918486020981,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "jibon das",
  "Mobile": 916000874791,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "jikirul hakim",
  "Mobile": 919864962980,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "jintu gogoi",
  "Mobile": 918590456843,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "DHEMAJI",
  "Name": "jiten borah",
  "Mobile": 919168725153,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jiten das",
  "Mobile": 916003663502,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "jiten gondhia",
  "Mobile": 8011171224,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "jitesh sawashi",
  "Mobile": 916900931353,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "jitu",
  "Mobile": 916002563636,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "jitu barman",
  "Mobile": 919101184651,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jitu mahanta",
  "Mobile": 917099951751,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "jitul bhuyan",
  "Mobile": 916000170131,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "jitumani bhuyan",
  "Mobile": 918011825782,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "jitumoni chetia",
  "Mobile": 916003451432,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "jitumoni phukan",
  "Mobile": 917896736407,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "jiwan jyoti boruah",
  "Mobile": 919577322670,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "jiyarul hoque",
  "Mobile": 918876600623,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "jiyaul haque",
  "Mobile": 917628973068,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "UDALGURI",
  "Name": "job",
  "Mobile": 916002587569,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "job",
  "Mobile": 919663287005,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "job mil gaya",
  "Mobile": 919101630275,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "jogesh nath",
  "Mobile": 918135070615,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "jogeswarkutum",
  "Mobile": 919365065876,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "jogodish singha",
  "Mobile": 918876822245,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ratnagiri",
  "Name": "\nDeepak Malgawe",
  "Mobile": 9869556838,
  "State": "Maharashtra",
  "Skill": "Manufacturing",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "joharul islam",
  "Mobile": 918208835626,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "johirul lslam",
  "Mobile": 919854324991,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jon jyoti baruah",
  "Mobile": 918753915359,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "jorhat",
  "Mobile": 919954692760,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "jorir ahmed laskar",
  "Mobile": 919132097843,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "joshwa pohthmi",
  "Mobile": 919435605187,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "joynal",
  "Mobile": 918925163120,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "juli borah",
  "Mobile": 918403098973,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "juli das",
  "Mobile": 918638135808,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "juluram koiri",
  "Mobile": 919538599300,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karbi Anglong",
  "Name": "junaka rongpherpi",
  "Mobile": 9365184596,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SIBSAGAR",
  "Name": "june gogoi",
  "Mobile": 919365754632,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "junmoni saikia",
  "Mobile": 918135071368,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "junti laskar",
  "Mobile": 919678258448,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "juntu kurmi",
  "Mobile": 918474879627,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "jyoti kalita",
  "Mobile": 916000013800,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "jyoti kamal borah",
  "Mobile": 8638165011,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "jyoti prasad saharia",
  "Mobile": 919365641094,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "jyoti prasad saikia",
  "Mobile": 917569723481,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "jyotimoy laskar",
  "Mobile": 916001601076,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "jyotish roy",
  "Mobile": 918638941688,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "kabita bora",
  "Mobile": 919401136304,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "kabita gogoi",
  "Mobile": 916001380069,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "kachu basumatary",
  "Mobile": 918011309799,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "kailash das",
  "Mobile": 919365295003,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "kailash saikia",
  "Mobile": 918638463645,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "kaju moni dutta",
  "Mobile": 916001583529,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "kalia deka",
  "Mobile": 919365369328,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "kalpana begum",
  "Mobile": 916002566178,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "kalyan kumar gogoi",
  "Mobile": 919954755887,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "kamakhya ray",
  "Mobile": 9101319290,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "kamal",
  "Mobile": 918822752740,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "kamal",
  "Mobile": 918876803886,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "kamal basumatary",
  "Mobile": 916002628798,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "kamal chandra nath",
  "Mobile": 918486754938,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "kamal deka",
  "Mobile": 919365644785,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "kamal mahato",
  "Mobile": 917896999772,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "kamal mali",
  "Mobile": 918638674361,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "kamal ray",
  "Mobile": 918638558763,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "kamal sarmh",
  "Mobile": 916901889763,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "kamala kanta hazarika",
  "Mobile": 918638428031,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "kamit baro",
  "Mobile": 917896640087,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "kanak rabidas",
  "Mobile": 919101314743,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "kanak roy",
  "Mobile": 916901918826,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "kanchandebnath",
  "Mobile": 919678807669,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "kangkan sarania",
  "Mobile": 918638695969,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "kanika das",
  "Mobile": 7896517900,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "kanti biswas",
  "Mobile": 916002157521,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "SIBSAGAR",
  "Name": "kapil kanchan chetia",
  "Mobile": 919859253030,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "karabi barman",
  "Mobile": 919101685935,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "karabi kalila",
  "Mobile": 6001031162,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "karan prasad",
  "Mobile": 917086775130,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "karimganj",
  "Mobile": 9706438979,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "karishma das",
  "Mobile": 916001373421,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "karishma devi",
  "Mobile": 919957956741,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "kartick chandra roy",
  "Mobile": 918638342678,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "kaushik",
  "Mobile": 916000541838,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "kaushik nath",
  "Mobile": 918876761074,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "kazimuddin",
  "Mobile": 918812983901,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "keshab sarkar",
  "Mobile": 919707269434,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "khagen das",
  "Mobile": 6000405600,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "khanin das",
  "Mobile": 919101493719,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "khanin goyary",
  "Mobile": 918638766322,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "khirod gogoi",
  "Mobile": 918638914460,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "khogen deuri",
  "Mobile": 916002230881,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "khon sing bey",
  "Mobile": 917086050943,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "khorsing tisso",
  "Mobile": 919362356252,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "khushidul islam",
  "Mobile": 916000388380,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "NAGAON",
  "Name": "ki howa",
  "Mobile": 918849010994,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "kiran gogoi",
  "Mobile": 919957548663,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "TINSUKIA",
  "Name": "kiran nag",
  "Mobile": 917896245979,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "kishmat ali",
  "Mobile": 916002946797,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "kishor deka",
  "Mobile": 919126714672,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "kiya",
  "Mobile": 8011462809,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHEMAJI",
  "Name": "kiya hua",
  "Mobile": 918761949679,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "kopil gogoi",
  "Mobile": 919706685157,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "krishna kalita",
  "Mobile": 9476500399,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "krishna kalita",
  "Mobile": 918474064396,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "krishna moni borah",
  "Mobile": 919957571756,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "krishna roy",
  "Mobile": 919101823204,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "kuddus ali",
  "Mobile": 919101952011,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "kukil das",
  "Mobile": 919101052190,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "kushal das",
  "Mobile": 919864575747,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "kushal hazarika",
  "Mobile": 918876475361,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "kushal padi",
  "Mobile": 919365420184,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "kushal saikia",
  "Mobile": 916000895625,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "kusum",
  "Mobile": 916901925179,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "kutub uddin",
  "Mobile": 916238963198,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "lachit nath",
  "Mobile": 919859012607,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "lakheswor bora",
  "Mobile": 919101104847,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakhi",
  "Mobile": 918474058629,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakhicharan pramanik",
  "Mobile": 918812063158,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "lakhimoni gogoi senapoti",
  "Mobile": 918474069453,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "NAGAON",
  "Name": "lakhindra mahela",
  "Mobile": 918667634033,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakhiprasad karmakar",
  "Mobile": 919365791917,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "lakhya jyoti boruah",
  "Mobile": 919859355453,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "lakhya kumar das",
  "Mobile": 917099547014,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "lakhyajyoti sonowal hazarika",
  "Mobile": 7002597472,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "lakshinathrabha",
  "Mobile": 919365107545,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "lakshya deep kumar",
  "Mobile": 919613622246,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "lalchan",
  "Mobile": 919864464879,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "lalit gogoi",
  "Mobile": 916000596203,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "laskar ali",
  "Mobile": 919101578006,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "liza gogoi",
  "Mobile": 918011407578,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "lokhindor mazumdar",
  "Mobile": 916900218121,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "longkam teron",
  "Mobile": 916001559627,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "luhit bhuyan",
  "Mobile": 919678336154,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "m r barbhuiya",
  "Mobile": 6900341815,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "m r barbhuiya",
  "Mobile": 9127565972,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "m r barbhuiya",
  "Mobile": 916900341815,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "madan nath",
  "Mobile": 917604805956,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "madhab das",
  "Mobile": 7896851190,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "madhab das",
  "Mobile": 917896395822,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "madhab hazarika",
  "Mobile": 918729968958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "madhabi mandal",
  "Mobile": 916000822154,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "madhu sarkar",
  "Mobile": 918638536758,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "madhurjya bikash borah",
  "Mobile": 918921067962,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "mafidul islam",
  "Mobile": 916000963855,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "mafijul islam",
  "Mobile": 919678344359,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "mahadav roy",
  "Mobile": 916001397031,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "mahadev barman",
  "Mobile": 919707885958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "mahar ali",
  "Mobile": 918011958902,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "mahendra medhi",
  "Mobile": 919678547469,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "mahi",
  "Mobile": 918822087449,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "mahi bul hoque",
  "Mobile": 9365629920,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "mahibul ali",
  "Mobile": 916000944627,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "mahmadur hassan",
  "Mobile": 918822013006,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "mahmudul hassan",
  "Mobile": 917399804621,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "mahmudul islam",
  "Mobile": 919864619265,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "maidul islam",
  "Mobile": 917002657968,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "maina das",
  "Mobile": 919401594181,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "mainul haque",
  "Mobile": 917002794614,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "majahar ali",
  "Mobile": 7637887526,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "majuli",
  "Mobile": 6901976435,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "makibul ali",
  "Mobile": 918264288272,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "mal",
  "Mobile": 916000480327,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "malagogoi",
  "Mobile": 917099838155,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "malay sankar nath",
  "Mobile": 919085930985,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "maleka khatun",
  "Mobile": 6238629419,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "mamani kalita",
  "Mobile": 916000973558,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "mami thakuria",
  "Mobile": 919365773475,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "mamoni das",
  "Mobile": 918812875344,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "manabjyoti rajbongshi",
  "Mobile": 918822516358,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "manash bora",
  "Mobile": 918724864903,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "manash das",
  "Mobile": 919577592925,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "manash jyoti gogoi",
  "Mobile": 919707824977,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "manash pratim biswas",
  "Mobile": 917578972263,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "manash protem gogoi",
  "Mobile": 6002600989,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "mandika rabha",
  "Mobile": 916900487353,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "maneswar mazumdar",
  "Mobile": 8638693867,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "mangalsing kramsa",
  "Mobile": 918106234863,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "mangla boro",
  "Mobile": 917387463482,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "manindra",
  "Mobile": 916002054663,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "manjil borah",
  "Mobile": 919101301264,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOALPARA",
  "Name": "manjit kumar boro",
  "Mobile": 917002579511,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "manoj ku roy",
  "Mobile": 918474868542,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "manoj kumar sah",
  "Mobile": 919706184540,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "manoj kumar sarmah",
  "Mobile": 919365773903,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "manoj rabha",
  "Mobile": 919365850947,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "manoj ray",
  "Mobile": 919707676665,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "manoranjan kakati",
  "Mobile": 916026263350,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "manoranjan ray",
  "Mobile": 916361760235,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KAMRUP",
  "Name": "mantaj ali",
  "Mobile": 917662955873,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "mantu baruah",
  "Mobile": 916000677762,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "mantu nath",
  "Mobile": 918876817875,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "manu ram",
  "Mobile": 916001916113,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "manuj borah",
  "Mobile": 918822959198,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "manuj doley",
  "Mobile": 917002013016,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DIBRUGARH",
  "Name": "manuj gogoi",
  "Mobile": 916000057858,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "marajul islam",
  "Mobile": 916360063706,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "marami bhuyan",
  "Mobile": 916002176802,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "masum sazad",
  "Mobile": 918638354962,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "mathura chouhan",
  "Mobile": 919957719952,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "matiur hussain",
  "Mobile": 918778434526,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "mausam khakhlari",
  "Mobile": 919101376568,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "md amir ali",
  "Mobile": 916001373200,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "md atikur rahman",
  "Mobile": 918248641484,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "md atowar ali",
  "Mobile": 918433787720,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md eftikar hussain",
  "Mobile": 919678799548,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md fariz ali",
  "Mobile": 9678609345,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "md fariz ali",
  "Mobile": 919101224314,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "md imdadul hoque",
  "Mobile": 919954812652,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "md jiaul hoque",
  "Mobile": 919101073257,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "md kasim uddin",
  "Mobile": 919613873542,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "md komrul islam",
  "Mobile": 8011169848,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KOKRAJHAR",
  "Name": "md rofiqul eslam",
  "Mobile": 917002508345,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hailakandi",
  "Name": "mrinal kanti nath",
  "Mobile": 916001262362,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "mrinal kro",
  "Mobile": 919957431799,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "mrs deepali baruah",
  "Mobile": 919954629117,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "mrs nabanita dutta borah",
  "Mobile": 919365447216,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "mubarak ali",
  "Mobile": 919101288707,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "mufazzal khandakar",
  "Mobile": 9365372978,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "pranabjyoti das",
  "Mobile": 917498743061,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "pranay basumatary",
  "Mobile": 918011551169,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "pranjal baro",
  "Mobile": 919957602057,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "pranjal hazarika",
  "Mobile": 916002785759,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "pranjal kalita",
  "Mobile": 917995761178,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "pranjal pegu",
  "Mobile": 917896333243,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "pranjal pegu",
  "Mobile": 918761882070,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "pranjal sarmah",
  "Mobile": 917002374216,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "pranjal sharma",
  "Mobile": 919365545904,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "pranjit thakuria",
  "Mobile": 916000878252,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "pranjol",
  "Mobile": 919864209933,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "pranjol gogoi",
  "Mobile": 916001917344,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "prasanjit bty",
  "Mobile": 918431602922,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "prasanta",
  "Mobile": 919706442750,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "prasanta barman",
  "Mobile": 918133055914,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "prasanta borah",
  "Mobile": 916900115109,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "prasanta borah",
  "Mobile": 918876627405,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "prasanta das",
  "Mobile": 919678995646,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "prasanta gogoi",
  "Mobile": 919365926168,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "prasanta medhi",
  "Mobile": 919707875842,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "prem kt bora",
  "Mobile": 917896076324,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "prem prakash kongari",
  "Mobile": 919435064482,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "prema barman",
  "Mobile": 916238214765,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "pritijit kr dam",
  "Mobile": 916001375116,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "priyam chutia",
  "Mobile": 918822408246,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "JORHAT",
  "Name": "priyanka chutia kachari",
  "Mobile": 918472008440,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "probin mohan",
  "Mobile": 918812028632,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "probudha konwer",
  "Mobile": 919612614209,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "progya gogoi",
  "Mobile": 919101511861,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "promud phukon",
  "Mobile": 918812816572,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "pronoy paul",
  "Mobile": 919957129032,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "puja rani bora",
  "Mobile": 918721945610,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "pulak das",
  "Mobile": 918133848952,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "pulak hazarika",
  "Mobile": 919101988619,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "pulin bora",
  "Mobile": 917636883712,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "punya prova bonia",
  "Mobile": 916001711068,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "purna borman",
  "Mobile": 7896726823,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "purnima chetia gogoi",
  "Mobile": 918011178347,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "puskal chetry",
  "Mobile": 918638193617,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "puspa lal sharma",
  "Mobile": 916003246210,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "putu das",
  "Mobile": 919957868750,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "rabbul ikram",
  "Mobile": 917002082958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "rabichan mandal",
  "Mobile": 8011059858,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "rabindra",
  "Mobile": 918011777797,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "rabiul islam",
  "Mobile": 918822061891,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "rafikul islam",
  "Mobile": 919101801240,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rafiqul islam",
  "Mobile": 919659551715,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "raham ali",
  "Mobile": 917896113212,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "rahamat ali",
  "Mobile": 919365160630,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "rahul alam",
  "Mobile": 916000681607,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "rahul barman",
  "Mobile": 919864743022,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rahul chetry",
  "Mobile": 916901808248,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "rahul das",
  "Mobile": 916901435740,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "rahul doley",
  "Mobile": 917636088470,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "rahul gogoi",
  "Mobile": 919678962898,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "rahul konwar",
  "Mobile": 919864930167,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rahul kumar ray",
  "Mobile": 916001566074,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "rahul mahanta",
  "Mobile": 918876359074,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "rahul majum dar",
  "Mobile": 7002620366,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "rahul malakar",
  "Mobile": 919101644425,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "raj kiron kishan",
  "Mobile": 919954548783,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "raj kumar dewri",
  "Mobile": 919739292265,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "raj sonowal",
  "Mobile": 918133048673,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "raja",
  "Mobile": 919707120447,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "raja ali",
  "Mobile": 919101613792,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "raja moni nath",
  "Mobile": 918876317226,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "rajashri lokhmi saikia",
  "Mobile": 918404029589,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "rajdeep baghwar",
  "Mobile": 917086119983,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "rajdeep debnath",
  "Mobile": 918761998901,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "rajen das",
  "Mobile": 918638080317,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rajen medhi",
  "Mobile": 916900827383,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "rajen payeng",
  "Mobile": 917896457408,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "rajesh bin",
  "Mobile": 918812057022,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "rajesh boro",
  "Mobile": 918473021467,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "rajesh chauhan",
  "Mobile": 918638120563,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "rajeswar baskey",
  "Mobile": 916000938189,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rajib bharali",
  "Mobile": 917263069055,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rajib kumar dutta",
  "Mobile": 919402829503,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "rajib nath",
  "Mobile": 919101347838,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rajib phukan",
  "Mobile": 9854688997,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "rajib sangma",
  "Mobile": 916000455702,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "rajib teron",
  "Mobile": 919710462188,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rajid ghosh",
  "Mobile": 917429117472,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "rajip das",
  "Mobile": 919678563690,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rajita bhuyan",
  "Mobile": 917086128543,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "rajkumar boruah",
  "Mobile": 916000425952,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KAMRUP",
  "Name": "rajkumar das",
  "Mobile": 919101760013,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "rajkumar ray",
  "Mobile": 918073287619,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "rajkumar seal",
  "Mobile": 919678410379,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "raju",
  "Mobile": 916001940724,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "raju barman",
  "Mobile": 918638908952,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "raju deori",
  "Mobile": 916901592502,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "raju giri",
  "Mobile": 919738152174,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "raju mallah",
  "Mobile": 918638083962,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "raju mazumdar",
  "Mobile": 6901894379,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "raju sangrola",
  "Mobile": 919706719672,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rajual hoque",
  "Mobile": 917896873956,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "rakesh ray",
  "Mobile": 917086143178,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "rakib hussain",
  "Mobile": 919101053109,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "rakib islam",
  "Mobile": 8011007786,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "rakibul hussain",
  "Mobile": 919365476765,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "rakibul islam",
  "Mobile": 918486641383,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "rakibur",
  "Mobile": 918011122255,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "raktim hazarika",
  "Mobile": 918135926826,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ram",
  "Mobile": 917086015839,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "ram prasad mandal",
  "Mobile": 919613060041,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "ramdas shil",
  "Mobile": 917577898877,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "ramdhan das",
  "Mobile": 919101778722,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ramjan begum",
  "Mobile": 917002163628,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rana borah",
  "Mobile": 916901827622,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "randip chamuah",
  "Mobile": 918471836406,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "ranjan",
  "Mobile": 918761972560,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "ranjan boro",
  "Mobile": 918638058358,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "ranjan boruah",
  "Mobile": 916282840733,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "ranjan gogoi",
  "Mobile": 918721007977,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "ranjan kalita",
  "Mobile": 9085206693,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranjan nath",
  "Mobile": 919101857171,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "ranjanadas",
  "Mobile": 916900510940,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "ranjit",
  "Mobile": 919706504699,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "ranjit daimary",
  "Mobile": 916001091366,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranjit das",
  "Mobile": 917767856191,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "ranjit kolita",
  "Mobile": 917079286357,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ranjit kumar nath",
  "Mobile": 917578803667,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "ranjoydeb",
  "Mobile": 918971032782,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "ranju ramchiary",
  "Mobile": 916003914569,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "rantu moni das",
  "Mobile": 919126661423,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "rantu roy",
  "Mobile": 918197688559,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranuj patir",
  "Mobile": 918088404792,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "rashidul islam",
  "Mobile": 916000822906,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "rashidul islam",
  "Mobile": 917896384496,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "rashidur rahman",
  "Mobile": 918403004327,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "ratul",
  "Mobile": 919706390855,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "ratul baruah",
  "Mobile": 917086335479,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "ratul boro",
  "Mobile": 918472843602,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "ratul das",
  "Mobile": 919678772084,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ratul deka",
  "Mobile": 916352512762,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "ratul dihingia",
  "Mobile": 919707739970,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "ratul kamsena",
  "Mobile": 918088974520,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "ratul kumar",
  "Mobile": 919101633348,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "ratul mandal",
  "Mobile": 9613288130,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "ratul sarkar",
  "Mobile": 917637880442,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "ratul talukdar",
  "Mobile": 918638225292,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "rehena",
  "Mobile": 8638678562,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "rejabul islam",
  "Mobile": 918099326062,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "rejibul hoque",
  "Mobile": 918368647655,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "rekha das",
  "Mobile": 919706185259,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "rekha deori",
  "Mobile": 8135844595,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rekibul islam",
  "Mobile": 918811960544,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "renu newar",
  "Mobile": 916002859591,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "renuka koirala",
  "Mobile": 9957038457,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "reshma basumatary",
  "Mobile": 919365631125,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "HAILAKANDI",
  "Name": "reyajul islam laskar",
  "Mobile": 918553116846,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "rezaul karim khan",
  "Mobile": 7002086034,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "rezima begom mozumder",
  "Mobile": 916003617408,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "riazul hoque",
  "Mobile": 8329972748,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "DHEMAJI",
  "Name": "ridip gogoi",
  "Mobile": 918638548121,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "riju moni das deka",
  "Mobile": 917662801716,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "riju phukan",
  "Mobile": 916002855835,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "rijuan ahmed",
  "Mobile": 918822001231,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rijul ali",
  "Mobile": 917002404491,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "rimpi borah",
  "Mobile": 919365769148,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Chirang",
  "Name": "rimpi mani kakati ray",
  "Mobile": 6026961366,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "ringku rajbongshi",
  "Mobile": 916900123108,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "rini nath",
  "Mobile": 919613802768,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "rinku ali",
  "Mobile": 918876755287,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "rinku gogoi",
  "Mobile": 9678371982,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "rinkumoni bora",
  "Mobile": 916001753704,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "rinky khosla",
  "Mobile": 918011663356,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "rintu barman",
  "Mobile": 918721083614,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rintu saikia",
  "Mobile": 918399039202,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "rintu saikia",
  "Mobile": 919864142057,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "rintumoni barmon",
  "Mobile": 918134887249,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "rishikesh sinha",
  "Mobile": 918861792167,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "ritesh roy",
  "Mobile": 918553972785,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "MARIGAON",
  "Name": "ritu mani patar",
  "Mobile": 916001660436,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "ritu pon gogoi",
  "Mobile": 916901612810,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "ritul dutta",
  "Mobile": 918638973453,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "ritumani",
  "Mobile": 918474853418,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "ritumoni patar",
  "Mobile": 916003099810,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "ritupan bharalua",
  "Mobile": 916305552456,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "JORHAT",
  "Name": "ritupan saikia",
  "Mobile": 918011141005,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "riya",
  "Mobile": 916003261048,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "rizwan",
  "Mobile": 916383485059,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "robial hoque",
  "Mobile": 919854818855,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "robita khatun",
  "Mobile": 919678714576,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rofiqul islam",
  "Mobile": 6001186545,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "rohan sharma",
  "Mobile": 919101231710,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "rohim uddin",
  "Mobile": 916002418583,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rokibul islam",
  "Mobile": 917985138266,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "romes uddin",
  "Mobile": 918708643245,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "romit daimary",
  "Mobile": 6003372196,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "ronju ahmed",
  "Mobile": 919957622770,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "roshan bhengra",
  "Mobile": 916003561462,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "rubi arandhara",
  "Mobile": 919132864626,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "rubul hague",
  "Mobile": 917399694958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "rumi rajbongshi",
  "Mobile": 917676614932,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "rumikon roy choudhury",
  "Mobile": 918638360775,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "runali baruah",
  "Mobile": 7002136986,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "runjun saikia borah",
  "Mobile": 919365841703,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "rupa",
  "Mobile": 6363032016,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "rupak boruah",
  "Mobile": 916000122506,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "rupali deka",
  "Mobile": 918721882462,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "rupali gorh",
  "Mobile": 919101356582,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "rupam boruah",
  "Mobile": 9501944871,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "rupam roy",
  "Mobile": 918473052338,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Majuli",
  "Name": "rupanta  kalita",
  "Mobile": 919365199059,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "rupchan hussain",
  "Mobile": 917086942354,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rupjyoti das",
  "Mobile": 918638068739,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "rupom daimary",
  "Mobile": 918761989165,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "rutul ali",
  "Mobile": 918486423145,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "s kurmi",
  "Mobile": 919365667416,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "s m kamal pasha laskar",
  "Mobile": 919085485336,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sabir ali",
  "Mobile": 917896888474,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "sabita deka",
  "Mobile": 918822346182,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "sachin hondique",
  "Mobile": 916355957307,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sachin rabha",
  "Mobile": 918473063929,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "sad uddin",
  "Mobile": 916000919744,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sadanand boro",
  "Mobile": 9101616494,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "saddam hussain",
  "Mobile": 919957385039,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saddam hussein",
  "Mobile": 917664908593,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sadhuram tanti",
  "Mobile": 917896421529,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sagar baruah",
  "Mobile": 918486967252,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "MARIGAON",
  "Name": "sagar biswas",
  "Mobile": 919577621979,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "sahanur islam",
  "Mobile": 918876861507,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sahida ahmed tapadar",
  "Mobile": 919957804453,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "sahidul ali",
  "Mobile": 918761832077,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sahil hussain",
  "Mobile": 918822028093,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "saidul islam",
  "Mobile": 6901986046,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "saidul islam",
  "Mobile": 919101525136,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "saidur rahman",
  "Mobile": 6002232274,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "saidur rahman",
  "Mobile": 918486694523,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "saifuddin ahmed",
  "Mobile": 916003505807,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saiful hoque",
  "Mobile": 917994581123,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "saiful islam",
  "Mobile": 918099460229,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "saiful islam",
  "Mobile": 918474003167,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "saiful islam",
  "Mobile": 918812849418,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "saiful islam",
  "Mobile": 918822424357,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BONGAIGAON",
  "Name": "saiful islam",
  "Mobile": 919864202589,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "saksham",
  "Mobile": 919365229132,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "saksham",
  "Mobile": 919613913613,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "salganga",
  "Mobile": 916003053188,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sambho dutta",
  "Mobile": 916001534836,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "sambhu roy",
  "Mobile": 918638017540,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "samim ahmed mazarbhuiya",
  "Mobile": 916001101043,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "samima aktar laskar",
  "Mobile": 919101910097,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "samiran talukdar",
  "Mobile": 916003956364,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "samiron nisha",
  "Mobile": 919127519303,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "DHUBRI",
  "Name": "samson marak",
  "Mobile": 916003318048,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "samsul alam",
  "Mobile": 919854664617,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "CACHAR",
  "Name": "samsul uddin laskar",
  "Mobile": 919101448158,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "samudra boruah",
  "Mobile": 6238872954,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "samujjall bor gohain",
  "Mobile": 916900788741,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "san bahdur limbu",
  "Mobile": 918073291753,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "sanatan das",
  "Mobile": 918876263487,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "sandip nath",
  "Mobile": 918892830411,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sangita boruah chetia",
  "Mobile": 918099347052,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "saniara khatun",
  "Mobile": 919365066481,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "sanidul",
  "Mobile": 917356024537,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "sanidul islam",
  "Mobile": 916001520075,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sanja kr das",
  "Mobile": 919859035653,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sanjay",
  "Mobile": 918011462809,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sanjay biswas",
  "Mobile": 918486557824,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Chirang",
  "Name": "sanjay devnath",
  "Mobile": 918753900523,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "sanjay kumar rajak",
  "Mobile": 919380016706,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sanjay ozha",
  "Mobile": 8135952007,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "sanjay roy",
  "Mobile": 916281995801,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "sanjeeb kumar chetri",
  "Mobile": 916282001822,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sanjib bora",
  "Mobile": 918721927565,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sanjib boro",
  "Mobile": 918638496997,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjib boro",
  "Mobile": 919743197496,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sanjib das",
  "Mobile": 917638059495,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sanjib hazarika",
  "Mobile": 919678400638,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "sanjib paul",
  "Mobile": 919957725514,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BAKSA",
  "Name": "sanjib rabha",
  "Mobile": 917896631515,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "sanjib saikia",
  "Mobile": 919085656716,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjip chetry",
  "Mobile": 919501568777,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "sanjit baishya",
  "Mobile": 917896630905,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sanjit barman",
  "Mobile": 916003380141,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "sanjit biswas",
  "Mobile": 916003555341,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sanjit chetry",
  "Mobile": 918472018406,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sanjit das",
  "Mobile": 919864971618,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "sanjit sahu",
  "Mobile": 917002163360,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sanjoy basumatary",
  "Mobile": 917896260667,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sanjoy boro",
  "Mobile": 9558553121,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "sanjoy das",
  "Mobile": 918876391688,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kokrajhar",
  "Name": "sankar barman",
  "Mobile": 918876190845,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "sankar das",
  "Mobile": 917086868565,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "sankar das",
  "Mobile": 917896295952,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "sankar dutta",
  "Mobile": 919706545753,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "santala daimary",
  "Mobile": 919101071606,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "santanu kurmi",
  "Mobile": 918134853085,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "santosh gogoi",
  "Mobile": 918822476506,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hailakandi",
  "Name": "sany laskar",
  "Mobile": 919503347673,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sarat borah",
  "Mobile": 919864804714,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "sarat chutia",
  "Mobile": 919954359874,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sarat kalita",
  "Mobile": 917002971051,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sarat saikia",
  "Mobile": 918133942503,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sarifa begum",
  "Mobile": 918403986065,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sarjina",
  "Mobile": 919577079107,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sarphul iswary",
  "Mobile": 918811897390,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "sashibhushan ray",
  "Mobile": 917002476347,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "satya chutia",
  "Mobile": 918822455498,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "satyabhan saha",
  "Mobile": 919577156016,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "satyajit basumatary",
  "Mobile": 919957849427,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "satyajit hazarika",
  "Mobile": 919922715958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "satyajit moran",
  "Mobile": 916900229645,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "CACHAR",
  "Name": "satyandra roy",
  "Mobile": 919706526692,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "saurav borah",
  "Mobile": 919101946224,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "saurav mandal",
  "Mobile": 919864720870,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "sayed ali",
  "Mobile": 916000214257,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonitpur",
  "Name": "see bharat das",
  "Mobile": 919127098456,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sehidur rahman",
  "Mobile": 919073227258,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "selim uddin",
  "Mobile": 919577114157,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "sermir",
  "Mobile": 916002233219,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "shahadat hussain",
  "Mobile": 917578922726,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shahanur hoque",
  "Mobile": 918011362655,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "shaharul ali",
  "Mobile": 919862516476,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "NAGAON",
  "Name": "shahidul alam laskar",
  "Mobile": 918778489176,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "shahidul islam",
  "Mobile": 916001657054,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shahidul islam",
  "Mobile": 918638543994,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "shahil ahmed",
  "Mobile": 917896569169,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "shajahan mondal",
  "Mobile": 916002067901,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "shajamal  uddin",
  "Mobile": 919957392144,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shajamal hoque",
  "Mobile": 919101733699,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shaju khandakar",
  "Mobile": 918723961342,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "shaju miah",
  "Mobile": 917896732725,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "shankar sawra",
  "Mobile": 916901833925,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "BONGAIGAON",
  "Name": "shariful islam",
  "Mobile": 916901460068,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sharman",
  "Mobile": 917578922012,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "shibantar sutradhar",
  "Mobile": 917086865173,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "shima rabha",
  "Mobile": 916000309521,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "MARIGAON",
  "Name": "shirina khatun",
  "Mobile": 918099422715,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "shofiqul",
  "Mobile": 919864691108,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "shoriful islam",
  "Mobile": 917099189442,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "shri sapan gogoi",
  "Mobile": 918638383413,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "shyam",
  "Mobile": 917099427332,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "shyamal",
  "Mobile": 917099497434,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "shyamanta bania",
  "Mobile": 918011198667,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "siba timung",
  "Mobile": 919365108369,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sibraj sharma",
  "Mobile": 917002969748,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "siddikun nehar",
  "Mobile": 918822398908,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "siddique ali mollah",
  "Mobile": 919365595409,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sikha moni saikia",
  "Mobile": 919854560829,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sima kalita",
  "Mobile": 918723096084,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "simanta bora",
  "Mobile": 918720931074,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "simanta ray",
  "Mobile": 918638032829,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "simi bania",
  "Mobile": 919365257770,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "simonta das",
  "Mobile": 9101708699,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sir moon das",
  "Mobile": 916901891442,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sitaram kairee",
  "Mobile": 918011358031,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "sitaram sanyshi",
  "Mobile": 919327324439,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "smita kakati",
  "Mobile": 917002095375,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "sobahanali",
  "Mobile": 919101025153,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sobed ali",
  "Mobile": 917577876718,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "sofikul islam",
  "Mobile": 917086019087,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "sofior rahman",
  "Mobile": 919864833912,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "sofiqul islam",
  "Mobile": 916003881716,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "sofiur rahman",
  "Mobile": 918638599741,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "sohidul alom",
  "Mobile": 917896758445,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "soleman ali",
  "Mobile": 919365189970,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "soleman ali",
  "Mobile": 919365492142,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "soleman ansary",
  "Mobile": 918638060971,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "son kumar deori",
  "Mobile": 919380101198,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sona moni sonowal",
  "Mobile": 916001863721,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sonaram narzary",
  "Mobile": 917635959869,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bongaigaon",
  "Name": "sonatan mazumdar",
  "Mobile": 916000300080,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sonjoy bey",
  "Mobile": 919901495513,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "sonjoy senar",
  "Mobile": 917637812415,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sorifakhatun",
  "Mobile": 917892118775,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sr binanda gogoi",
  "Mobile": 919747803816,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "sri anupam rabha",
  "Mobile": 918822024157,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "sri biplob bhuyan",
  "Mobile": 918011431285,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri bitu das",
  "Mobile": 916000303655,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "sri bubu das",
  "Mobile": 919365705952,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri chintu kalita",
  "Mobile": 919864396245,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "sri dhan bahadur lakai",
  "Mobile": 918011476295,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "sri gajen saikia",
  "Mobile": 916001951432,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sri hemchandar gogoi",
  "Mobile": 918134918739,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sri hirak kardong",
  "Mobile": 918822954981,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "sri jgat paul",
  "Mobile": 919954717618,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri jitu mani kalita",
  "Mobile": 6000569209,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "sri kalyanjyoti bora",
  "Mobile": 916000050013,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "sri khiro kumar borah",
  "Mobile": 917736417958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "sri kripanath doley",
  "Mobile": 919344634677,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "sri lakhi bordoloi",
  "Mobile": 916003465903,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri milan bhuyan",
  "Mobile": 916001193229,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sri mrinal barman",
  "Mobile": 919085128958,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KAMRUP",
  "Name": "sri niren ch das",
  "Mobile": 918135958418,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "sri nirmol gowala",
  "Mobile": 919678935295,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri noren gogoi",
  "Mobile": 919127325919,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri paban bora",
  "Mobile": 917086867289,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "sri pankaj bharali",
  "Mobile": 916000981905,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "sri pankaj das",
  "Mobile": 918811949282,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri pankaj kumar",
  "Mobile": 919957350198,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sri pradip gohain",
  "Mobile": 918812052043,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Charaideo",
  "Name": "sri prafulla keot",
  "Mobile": 919957677138,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "sri prakash dalu",
  "Mobile": 917896931057,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KAMRUP",
  "Name": "sri pranab kalita",
  "Mobile": 916900015160,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sri pranjal kalita",
  "Mobile": 917002572550,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sri pritee rekha das",
  "Mobile": 917896130404,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sri prodip rao",
  "Mobile": 919957011413,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Majuli",
  "Name": "sri prodip y",
  "Mobile": 916001879886,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sri pronob rajkhowa",
  "Mobile": 918822704580,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "sri rup jyoti gogoi",
  "Mobile": 918822865761,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sri sachin das",
  "Mobile": 918486673307,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "sri sanu munda",
  "Mobile": 917086387110,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "sri swarup saikia",
  "Mobile": 919862378179,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "sribash das",
  "Mobile": 917002396752,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "sridam das",
  "Mobile": 917002367454,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "subash baro",
  "Mobile": 918247224992,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "subash miri",
  "Mobile": 919074768927,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "subha handique",
  "Mobile": 917636803373,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "subham dey",
  "Mobile": 918011215286,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "subhas chandra das",
  "Mobile": 917002730933,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "subhash gogoi",
  "Mobile": 917019603205,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "subhash pegu",
  "Mobile": 919101341400,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "subhojit paul",
  "Mobile": 918638601744,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "subhrajit debnath",
  "Mobile": 919954252901,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "subodh kumar deb",
  "Mobile": 917002472030,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "subrata acharjee",
  "Mobile": 919678335879,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "sudhir das",
  "Mobile": 918136013317,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "sudip nath",
  "Mobile": 916900398699,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KARIMGANJ",
  "Name": "sujata paul",
  "Mobile": 916002452198,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sujit mazumder",
  "Mobile": 7576912266,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sujit mazumder",
  "Mobile": 917576912266,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "sukanta",
  "Mobile": 918486615273,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sukkur ali",
  "Mobile": 919365067178,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "sukuram narzary",
  "Mobile": 918638177524,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "sultan mamud",
  "Mobile": 917896615151,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "sultan mamud",
  "Mobile": 919567050269,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "sumi hazarika sonowal",
  "Mobile": 918811974839,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "sumit kumar nath",
  "Mobile": 916001317481,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "sumit kurmi",
  "Mobile": 918638299576,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sumita das",
  "Mobile": 919365109890,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "sumu dutta",
  "Mobile": 918473065523,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "sun moni saikia",
  "Mobile": 916380687250,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "sundar boro",
  "Mobile": 6001344251,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sunil",
  "Mobile": 9101244824,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "sunil ahmed",
  "Mobile": 919101186934,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "sunil bora",
  "Mobile": 917638053998,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "sunil boro",
  "Mobile": 916901711530,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sunil engti",
  "Mobile": 916026421480,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunil gogoi",
  "Mobile": 919365055671,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunil saikia",
  "Mobile": 917397406458,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sunil timung",
  "Mobile": 7638045925,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "sunju daimari",
  "Mobile": 916001546558,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "sunny paul",
  "Mobile": 917578854704,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "suraj jyoti boruah",
  "Mobile": 917578820946,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "suraj rabha",
  "Mobile": 916289660189,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "suraj sharma",
  "Mobile": 917000698473,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "surajit das",
  "Mobile": 918660880717,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "surajit hajong",
  "Mobile": 919101354793,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "surajit roy",
  "Mobile": 918486696707,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "suresh timung",
  "Mobile": 919678397714,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "surya kanta chamlagai",
  "Mobile": 919127395113,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "sushanta gigoi",
  "Mobile": 919957110938,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "sushanta roy",
  "Mobile": 918618250256,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "swajal goswami",
  "Mobile": 917896888836,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "swapna biswas",
  "Mobile": 919365229511,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "taijuddin akanda",
  "Mobile": 919101971354,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baksa",
  "Name": "taijul ali",
  "Mobile": 918761907373,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "taleb ali",
  "Mobile": 918486674270,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "tanuram turi",
  "Mobile": 917483783180,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tapan borah",
  "Mobile": 918761906542,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "tapan chandra konwar",
  "Mobile": 916000457371,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "tapan changmai",
  "Mobile": 916001823769,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "tapash deb",
  "Mobile": 9365238385,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "taranidhi dhakal",
  "Mobile": 918011012525,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "tayeeb ahmed barbhuiya",
  "Mobile": 919101374714,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "BARPETA",
  "Name": "tazuddin",
  "Mobile": 919365392545,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "tellar bala",
  "Mobile": 8788796320,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barpeta",
  "Name": "tilak bharali",
  "Mobile": 919883307063,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "tilak das",
  "Mobile": 919365312871,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "tinamoni bora",
  "Mobile": 919864710367,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kokrajhar",
  "Name": "tinku mazumdar",
  "Mobile": 918822514015,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "tomson kutum",
  "Mobile": 916002946288,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "torun",
  "Mobile": 918472868622,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Biswanath",
  "Name": "tritha borah",
  "Mobile": 917719008187,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "tubul ali",
  "Mobile": 919954630132,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jorhat",
  "Name": "tuhin bora",
  "Mobile": 919101627573,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaon",
  "Name": "tuktuki",
  "Mobile": 918638105064,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "tulan das",
  "Mobile": 9954147431,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "tulen phukan",
  "Mobile": 919101223532,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "tulumoni phukan",
  "Mobile": 919957253306,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SONITPUR",
  "Name": "tutu",
  "Mobile": 916001971662,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Goalpara",
  "Name": "uday das",
  "Mobile": 919613121357,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "uday singh",
  "Mobile": 919127389027,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Charaideo",
  "Name": "uddhob tassa",
  "Mobile": 8011652724,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cachar",
  "Name": "udit narayan singh",
  "Mobile": 919707929498,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "ujjal nath",
  "Mobile": 916003312918,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chirang",
  "Name": "uma goyary",
  "Mobile": 919365175615,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "umananda rajbongshi",
  "Mobile": 8403068673,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup",
  "Name": "umesh rajbongshi",
  "Mobile": 6001733207,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Darrang",
  "Name": "ummehani begum",
  "Mobile": 916001215177,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "utpal bhuyan",
  "Mobile": 916002249932,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "utpal mahanta",
  "Mobile": 918011858089,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "uttam kumar giri",
  "Mobile": 917975742942,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hojai",
  "Name": "uttam nath",
  "Mobile": 919854171510,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karbi Anglong",
  "Name": "uttom kr devnath",
  "Mobile": 919365832924,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sivasagar",
  "Name": "villege",
  "Mobile": 8134075243,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "wahed ali",
  "Mobile": 919577061738,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "wasim akram",
  "Mobile": 919954532890,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "what",
  "Mobile": 917896247322,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "what is saksham",
  "Mobile": 916002832784,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BARPETA",
  "Name": "yt",
  "Mobile": 9613012207,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "zakir hossain",
  "Mobile": 916002886458,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "zakir hussain",
  "Mobile": 919957645427,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "ziadur rahman mondal",
  "Mobile": 916003414835,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "ziarul islam",
  "Mobile": 916397785673,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Karimganj",
  "Name": "ziaur rahman",
  "Mobile": 919954143486,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GOLAGHAT",
  "Name": "अभिलाष बरा",
  "Mobile": 916001271808,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "खगेन बस्मटारी",
  "Mobile": 919101820026,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kamrup Metro",
  "Name": "गीतांजलि बोरो",
  "Mobile": 918822953829,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "जनता बसूमतारी",
  "Mobile": 8248738216,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Chirang",
  "Name": "तवेन चन्दर रार",
  "Mobile": 919707738982,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "दिपन नाथ",
  "Mobile": 919613816962,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tinsukia",
  "Name": "परमोद यादव",
  "Mobile": 9954775855,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "प्रानजित",
  "Mobile": 917896942367,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Lakhimpur",
  "Name": "बिमल",
  "Mobile": 918638090184,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Marigaon",
  "Name": "मेरा नाम dhrubajyoti deka",
  "Mobile": 918486459442,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalbari",
  "Name": "मेरा नाम देबाजित तालुकदार हैं",
  "Mobile": 917099664427,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udalguri",
  "Name": "राकेश सरकार",
  "Mobile": 918135892612,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "लालचान",
  "Mobile": 917086448940,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Udalguri",
  "Name": "विनिता कलिता",
  "Mobile": 918135846336,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dibrugarh",
  "Name": "श्री कमल पाईक",
  "Mobile": 916000636541,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhemaji",
  "Name": "सबाश लामा",
  "Mobile": 917099565318,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "हिरं नंथ",
  "Mobile": 919101573840,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhubri",
  "Name": "গৌতম নাথ",
  "Mobile": 919864228935,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Golaghat",
  "Name": "থাঙ্কস তোমাকে",
  "Mobile": 919365077162,
  "State": "Assam",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Banka",
  "Name": "ahde ali",
  "Mobile": 918133971952,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nalanda",
  "Name": "akash kumar",
  "Mobile": 919801440861,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Araria",
  "Name": "akshay kumar",
  "Mobile": 9576283196,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Begusarai",
  "Name": "begusarai",
  "Mobile": 919798821885,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jamui",
  "Name": "dablu yadav",
  "Mobile": 919113052282,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Khagaria",
  "Name": "guddu kumar",
  "Mobile": 916299171080,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Muzaffarpur",
  "Name": "kiya huaw",
  "Mobile": 917352560025,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gopalganj",
  "Name": "laxman",
  "Mobile": 917352675074,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Samastipur",
  "Name": "md mustak",
  "Mobile": 919380725136,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Madhepura",
  "Name": "nitish kumar",
  "Mobile": 919940767812,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Supaul",
  "Name": "ram vijay ram",
  "Mobile": 919801126976,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sitamarhi",
  "Name": "ramvinay sah",
  "Mobile": 7352746575,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Patna",
  "Name": "sarvesh kumar",
  "Mobile": 7061813474,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gopalganj",
  "Name": "shambhu kumar",
  "Mobile": 919931219770,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Siwan",
  "Name": "sujit",
  "Mobile": 919801205713,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Buxar",
  "Name": "अरविंद मिश्र",
  "Mobile": 9546058765,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gopalganj",
  "Name": "बिकी कुमार साह",
  "Mobile": 916203275076,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bhojpur",
  "Name": "राकेश सिंह",
  "Mobile": 918409264974,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Patna",
  "Name": "विशाल कुमार",
  "Mobile": 918340218022,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gopalganj",
  "Name": "शंभू कुमार",
  "Mobile": 919931219770,
  "State": "Bihar",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chandigarh",
  "Name": "manish",
  "Mobile": 919779628894,
  "State": "Chandigarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chandigarh",
  "Name": "test a",
  "Mobile": 97086786091,
  "State": "Chandigarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "BALOD BAZER",
  "Name": "yash",
  "Mobile": 919179094219,
  "State": "Chattisgarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Surguja",
  "Name": "ambika",
  "Mobile": 916260809549,
  "State": "Chhattisgarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Korba",
  "Name": "khagendra",
  "Mobile": 917697672760,
  "State": "Chhattisgarh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Janjgir-Champa",
  "Name": "mukesh kumar",
  "Mobile": 916261437814,
  "State": "Chhattisgarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Korba",
  "Name": "prashant sinha",
  "Mobile": 917747005771,
  "State": "Chhattisgarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bemetara",
  "Name": "राजेश",
  "Mobile": 917999842457,
  "State": "Chhattisgarh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "New Delhi",
  "Name": "aarti",
  "Mobile": 917303308853,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "North West",
  "Name": "abhishek",
  "Mobile": 9572478484,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South West",
  "Name": "ajeet",
  "Mobile": 919278942296,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "New Delhi",
  "Name": "ankush",
  "Mobile": 7500030459,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Central",
  "Name": "imran",
  "Mobile": 919582133024,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South",
  "Name": "parvinder singh",
  "Mobile": 919643388673,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "South West",
  "Name": "sourab",
  "Mobile": 919599957424,
  "State": "Delhi",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Surendranagar",
  "Name": "amit makavana",
  "Mobile": 918000065088,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kheda",
  "Name": "jaswant dabhi",
  "Mobile": 918758185697,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Junagadh",
  "Name": "kamlesh bamaniya",
  "Mobile": 916352259657,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "JUNAGADH",
  "Name": "kanjibhai",
  "Mobile": 919714221842,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Junagadh",
  "Name": "monil",
  "Mobile": 917202046072,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Surat",
  "Name": "mothabhau",
  "Mobile": 918487823593,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Surat",
  "Name": "roshni",
  "Mobile": 9725518675,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Rajkot",
  "Name": "tejas",
  "Mobile": 9723492901,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mahesana",
  "Name": "vishal",
  "Mobile": 917878980032,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jamnagar",
  "Name": "करमटा देवा भाई",
  "Mobile": 919327175410,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Anand",
  "Name": "सिन्धा लालजी भाई मानसिह",
  "Mobile": 917096181946,
  "State": "Gujarat",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Yamunanagar",
  "Name": "babloo",
  "Mobile": 919671374076,
  "State": "Haryana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hisar",
  "Name": "bunty",
  "Mobile": 918307411362,
  "State": "Haryana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hisar",
  "Name": "ramsingh chandel",
  "Mobile": 919991272634,
  "State": "Haryana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karnal",
  "Name": "surender singj",
  "Mobile": 917071727887,
  "State": "Haryana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Shimla",
  "Name": "yuoi",
  "Mobile": 916001271648,
  "State": "Himachal pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jammu",
  "Name": "kartik ghatowar",
  "Mobile": 918486600787,
  "State": "Jammu And Kashmir",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Anantnag",
  "Name": "sumpi chetia",
  "Mobile": 8723088294,
  "State": "Jammu And Kashmir",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hazaribagh",
  "Name": "akash kumar",
  "Mobile": 917061039402,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Palamu",
  "Name": "dileep kumar",
  "Mobile": 916202764175,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ranchi",
  "Name": "jay narayan gop",
  "Mobile": 919905917909,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhanbad",
  "Name": "sachin kumar",
  "Mobile": 919939237382,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chatra",
  "Name": "sunil kumar",
  "Mobile": 917018337419,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "GARHWA",
  "Name": "sunil kumar",
  "Mobile": 918889649428,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ranchi",
  "Name": "vikas singh",
  "Mobile": 917033536633,
  "State": "Jharkhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kalaburagi",
  "Name": "abbasali",
  "Mobile": 919980167266,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hassan",
  "Name": "devaraja bm",
  "Mobile": 919071161224,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bengaluru Urban",
  "Name": "dore swamy n",
  "Mobile": 917259189746,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dharwad",
  "Name": "hanamantappa",
  "Mobile": 917353445326,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bagalkote",
  "Name": "hii",
  "Mobile": 9482855834,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dakshina Kannada",
  "Name": "i nalinakshi k m",
  "Mobile": 919686110842,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mandya",
  "Name": "jnanendra hs",
  "Mobile": 917338276516,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Yadgir",
  "Name": "jummappa",
  "Mobile": 919945816943,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "kavya shree g",
  "Mobile": 919380183207,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gadag",
  "Name": "kishor",
  "Mobile": 919019924307,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Raichur",
  "Name": "mahesh",
  "Mobile": 919964691793,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Yadgir",
  "Name": "mallappa duppalli",
  "Mobile": 916366079906,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Yadgir",
  "Name": "manisha das",
  "Mobile": 916001183133,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Davangere",
  "Name": "nagaraja",
  "Mobile": 919844827461,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mysuru",
  "Name": "nidha",
  "Mobile": 918970981576,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Vijayapura",
  "Name": "paramanand",
  "Mobile": 917760060298,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Belagavi",
  "Name": "raibag",
  "Mobile": 918105611381,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mysuru",
  "Name": "rama rm",
  "Mobile": 919019282940,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bidar",
  "Name": "ramshetty",
  "Mobile": 918125074312,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kodagu",
  "Name": "ramya madhu",
  "Mobile": 918197239098,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ramanagara",
  "Name": "shankaramurthy",
  "Mobile": 919535630012,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Belagavi",
  "Name": "siddappa",
  "Mobile": 919483821510,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ballari",
  "Name": "suresh kurugod",
  "Mobile": 918867510749,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bengaluru Rural",
  "Name": "tampudas",
  "Mobile": 916363176243,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ballari",
  "Name": "veeranna o",
  "Mobile": 919392015976,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Udupi",
  "Name": "vijeth kumar",
  "Mobile": 9164513623,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kodagu",
  "Name": "vikas p s",
  "Mobile": 917348938105,
  "State": "Karnataka",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "KOLLAM",
  "Name": "haiii",
  "Mobile": 917025507383,
  "State": "Kerala",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ernakulam",
  "Name": "hu",
  "Mobile": 919847674807,
  "State": "Kerala",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "prasannakumar k",
  "Mobile": 919895071077,
  "State": "Kerala",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "THIRUVANANTHAPURAM",
  "Name": "radhakrishnan k",
  "Mobile": 919605056823,
  "State": "Kerala",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kollam",
  "Name": "radhika",
  "Mobile": 919961924863,
  "State": "Kerala",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "rajeeb  ch das",
  "Mobile": 918921044524,
  "State": "Kerala",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "SEHORE",
  "Name": "arjun",
  "Mobile": 917879433808,
  "State": "Madhya Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sehore",
  "Name": "babita",
  "Mobile": 917471163425,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Indore",
  "Name": "deepak",
  "Mobile": 919174610156,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Neemuch",
  "Name": "kailash",
  "Mobile": 916261390535,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Gwalior",
  "Name": "khemraj",
  "Mobile": 917697219535,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sehore",
  "Name": "mangala",
  "Mobile": 918458803745,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Shivpuri",
  "Name": "manpreet singh",
  "Mobile": 917999726675,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Betul",
  "Name": "navalkishore",
  "Mobile": 917697406482,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "ANUPPUR",
  "Name": "omwati",
  "Mobile": 918224815095,
  "State": "Madhya Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Satna",
  "Name": "pradeep singh",
  "Mobile": 917984450177,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Balaghat",
  "Name": "rajat belji",
  "Mobile": 919039781557,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Morena",
  "Name": "ram avtar singh",
  "Mobile": 916261990248,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Raisen",
  "Name": "ravi kumar pal",
  "Mobile": 918435609413,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Datia",
  "Name": "satish yadav",
  "Mobile": 918103073845,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Tikamgarh",
  "Name": "shailendra sen",
  "Mobile": 916264320302,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Damoh",
  "Name": "suman",
  "Mobile": 7389357125,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Rewa",
  "Name": "vinod",
  "Mobile": 916268173706,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bhind",
  "Name": "vishnu singh",
  "Mobile": 918120205958,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Khargone",
  "Name": "गोविन्द बघेल",
  "Mobile": 919516061616,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "DINDORI",
  "Name": "थान सिंह मरकाम",
  "Mobile": 916264168626,
  "State": "Madhya Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Neemuch",
  "Name": "दीपक नागदा",
  "Mobile": 917878287913,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Rewa",
  "Name": "मनीष सिंह",
  "Mobile": 919893715555,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Indore",
  "Name": "मुकेश खण्डेलवाल",
  "Mobile": 918770112499,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "SIDHI",
  "Name": "रवि कुमार",
  "Mobile": 918103532287,
  "State": "Madhya Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gwalior",
  "Name": "रामस्वरूप",
  "Mobile": 919977978033,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ujjain",
  "Name": "रोहित चौधरी",
  "Mobile": 918889077403,
  "State": "Madhya pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nashik",
  "Name": "aditya jadhay",
  "Mobile": 919172840583,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nashik",
  "Name": "ajay khairnar",
  "Mobile": 918888429131,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "amir uddin",
  "Mobile": 917306985201,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Amravati",
  "Name": "ankush dongarwar",
  "Mobile": 917972262247,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Wardha",
  "Name": "arjun kinnake",
  "Mobile": 918999179872,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "ashiqur rahman",
  "Mobile": 919707017852,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Yavatmal",
  "Name": "avinash",
  "Mobile": 917744964756,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jalgaon",
  "Name": "avinash ashok patil",
  "Mobile": 919765563712,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Akola",
  "Name": "bhagwaan namadeo bahakar",
  "Mobile": 919561901555,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "bhosale gorakh",
  "Mobile": 919673837284,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Aurangabad",
  "Name": "devidas totaram mirge",
  "Mobile": 918975250589,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "jahidul",
  "Mobile": 919834331174,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai",
  "Name": "kalpesh kule",
  "Mobile": 918452924759,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "kishor",
  "Mobile": 917385298299,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nashik",
  "Name": "machhindra",
  "Mobile": 918390339517,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Washim",
  "Name": "mahadeo janardhan jayabhaye",
  "Mobile": 919623870108,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ahmednagar",
  "Name": "nanda shelke",
  "Mobile": 919371977981,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Amravati",
  "Name": "nikhil",
  "Mobile": 919503907343,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nashik",
  "Name": "nitin vijay bhurkud",
  "Mobile": 919689857730,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hingoli",
  "Name": "parmeshwar bagate",
  "Mobile": 918788443703,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Raigad",
  "Name": "pradip",
  "Mobile": 919702698333,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Satara",
  "Name": "pradip jadhav",
  "Mobile": 8208639544,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nashik",
  "Name": "pramod subhash raut",
  "Mobile": 919766961690,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bhandara",
  "Name": "prashant ramesh selokar",
  "Mobile": 918857886157,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Satara",
  "Name": "priyanka",
  "Mobile": 917875864073,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Latur",
  "Name": "ramakant janardhan pethkar",
  "Mobile": 917498100863,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Mumbai",
  "Name": "Shankar Singh",
  "Mobile": 9769440147,
  "State": "Maharashtra",
  "Skill": "Construction",
  "Company": "Other"
 },
 {
  "District": "Amravati",
  "Name": "ritesh",
  "Mobile": 919023440575,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Solapur",
  "Name": "rohidastukaram rathod",
  "Mobile": 918805841536,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Satara",
  "Name": "rohit",
  "Mobile": 919822070809,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nashik",
  "Name": "rushi",
  "Mobile": 919730136929,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Wardha",
  "Name": "sachin",
  "Mobile": 917038602258,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Solapur",
  "Name": "sagar anandkar",
  "Mobile": 917498118674,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Akola",
  "Name": "sandesh damodar",
  "Mobile": 918805307430,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chandrapur",
  "Name": "sapna bagde",
  "Mobile": 917385928416,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dhule",
  "Name": "satish",
  "Mobile": 919175375501,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kolhapur",
  "Name": "satish dashrath kudav",
  "Mobile": 919664983186,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Satara",
  "Name": "shailesh",
  "Mobile": 917350497145,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Ahmednagar",
  "Name": "shamal jadhav",
  "Mobile": 917796456177,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mumbai Suburban",
  "Name": "shital zanjale",
  "Mobile": 8208501900,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thane",
  "Name": "sunil",
  "Mobile": 919324671611,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ahmednagar",
  "Name": "swapnali haridas sumbe",
  "Mobile": 919529318797,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sangli",
  "Name": "tejas patil",
  "Mobile": 919284129197,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pune",
  "Name": "vishal gaikwad",
  "Mobile": 918888180963,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jalna",
  "Name": "vishnu",
  "Mobile": 919423926663,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Palghar",
  "Name": "yogesh jadhav",
  "Mobile": 918605743898,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Pune",
  "Name": "yogesh misal",
  "Mobile": 919766337605,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Beed",
  "Name": "yuvraj",
  "Mobile": 917722048737,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nashik",
  "Name": "अतुल",
  "Mobile": 917767873156,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Osmanabad",
  "Name": "कैलास पवार",
  "Mobile": 919921318535,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Solapur",
  "Name": "दिपक",
  "Mobile": 917387287181,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bhandara",
  "Name": "नविन",
  "Mobile": 919420255175,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Beed",
  "Name": "नितीन मोहन भवर",
  "Mobile": 919022408580,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Raigad",
  "Name": "रुपेश",
  "Mobile": 918779686307,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thane",
  "Name": "विजय शिदें",
  "Mobile": 918433863449,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thane",
  "Name": "विराज",
  "Mobile": 919372960948,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Solapur",
  "Name": "विशाल मिरजकर",
  "Mobile": 919175002746,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Beed",
  "Name": "संजय भास्कर तांबडे",
  "Mobile": 919637375402,
  "State": "Maharashtra",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "East Khasi Hills",
  "Name": "jiban barua",
  "Mobile": 916009440399,
  "State": "Megalaya",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Garo Hills",
  "Name": "azadur rahman",
  "Mobile": 919366438532,
  "State": "Meghalaya",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Garo Hills",
  "Name": "bidyut goon",
  "Mobile": 919774270620,
  "State": "Meghalaya",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "West Garo Hills",
  "Name": "dinondya rabha",
  "Mobile": 6001595069,
  "State": "Meghalaya",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "East Jaintia Hills",
  "Name": "leo dkhar",
  "Mobile": 919862600001,
  "State": "Meghalaya",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "North Garo Hills",
  "Name": "mukundo rabha",
  "Mobile": 9101365658,
  "State": "Meghalaya",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gajapati",
  "Name": "biyala santosh kumar",
  "Mobile": 918457063615,
  "State": "Odisha",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kalahandi",
  "Name": "prakash bemal",
  "Mobile": 6370504484,
  "State": "Odisha",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Patiala",
  "Name": "geetan kumar",
  "Mobile": 919435831215,
  "State": "Punjab",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Patiala",
  "Name": "neelam rani",
  "Mobile": 9780188475,
  "State": "Punjab",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "FATEHGARH SAHIB",
  "Name": "pushpinder kumar",
  "Mobile": 916280756055,
  "State": "Punjab",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "KAPURTHALA",
  "Name": "simranjeet singh",
  "Mobile": 918196035290,
  "State": "Punjab",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jaipur",
  "Name": "abdullah",
  "Mobile": 918079021041,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Ajmer",
  "Name": "arif",
  "Mobile": 917878562744,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jhunjhunu",
  "Name": "dalip singh shekhawat",
  "Mobile": 918955980120,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Alwar",
  "Name": "dheer singh choudhary",
  "Mobile": 919351843677,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagaur",
  "Name": "dungar ram",
  "Mobile": 916367017802,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Alwar",
  "Name": "jangeer",
  "Mobile": 917737594118,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Churu",
  "Name": "jaysingh poonia",
  "Mobile": 919588040484,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bharatpur",
  "Name": "komal singh",
  "Mobile": 919664292699,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jhunjhunu",
  "Name": "krishna kumar",
  "Mobile": 917300336828,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Dholpur",
  "Name": "manish kumar",
  "Mobile": 919887630271,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ajmer",
  "Name": "mohit kathat",
  "Mobile": 916350435383,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Ganganagar",
  "Name": "namita",
  "Mobile": 6001471954,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Bikaner",
  "Name": "omsingh",
  "Mobile": 919680992381,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jodhpur",
  "Name": "prem vishnoi",
  "Mobile": 917096585583,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Alwar",
  "Name": "rajendra singh",
  "Mobile": 918003987875,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jhunjhunu",
  "Name": "ravindar",
  "Mobile": 919509119875,
  "State": "Rajasthan",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Ranipet",
  "Name": "balamurugan",
  "Mobile": 918870198093,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Virudhunagar",
  "Name": "jeyaganesh",
  "Mobile": 918122647255,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Chennai",
  "Name": "kajal das",
  "Mobile": 916383137174,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Theni",
  "Name": "karthik",
  "Mobile": 918825488173,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dindigul",
  "Name": "kesavadhanabalan",
  "Mobile": 918438160094,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Salem",
  "Name": "lakshmanraj",
  "Mobile": 918925647709,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvallur",
  "Name": "m vinoth",
  "Mobile": 916379501242,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ramanathapuram",
  "Name": "marimuthu",
  "Mobile": 919442956584,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "VELLORE",
  "Name": "mohaideen peeran s f",
  "Mobile": 919750506939,
  "State": "Tamil Nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Madurai",
  "Name": "mohamed ilyas",
  "Mobile": 918778954359,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chennai",
  "Name": "muruganandam",
  "Mobile": 919841143137,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Vellore",
  "Name": "poo",
  "Mobile": 918122497641,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thanjavur",
  "Name": "prabhu",
  "Mobile": 918678935167,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chennai",
  "Name": "prakash",
  "Mobile": 918056932572,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Dharmapuri",
  "Name": "rajesh",
  "Mobile": 919092923738,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Cuddalore",
  "Name": "rajkumar",
  "Mobile": 917708035602,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Erode",
  "Name": "ramesh",
  "Mobile": 918778284469,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tiruppur",
  "Name": "ritik",
  "Mobile": 916003377544,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Krishnagiri",
  "Name": "sankar c",
  "Mobile": 919123501693,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Virudhunagar",
  "Name": "santhanamari",
  "Mobile": 919384505290,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tuticorin",
  "Name": "sathya s",
  "Mobile": 917010892852,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Nagapattinam",
  "Name": "sivanesan",
  "Mobile": 919500961235,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Chennai",
  "Name": "sreekanth",
  "Mobile": 919895124835,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagapattinam",
  "Name": "suresh",
  "Mobile": 919751230715,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Theni",
  "Name": "thijakanthan billa",
  "Mobile": 8056564016,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kanchipuram",
  "Name": "vignesh m",
  "Mobile": 917867076586,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Madurai",
  "Name": "vigneshkumar",
  "Mobile": 919487889986,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Tiruchirappalli",
  "Name": "vijayakumar",
  "Mobile": 9842329271,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagapattinam",
  "Name": "vikram",
  "Mobile": 7812841498,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Thiruvallur",
  "Name": "yuvaraj",
  "Mobile": 919940255434,
  "State": "Tamil nadu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Khammam",
  "Name": "anil kumar",
  "Mobile": 916281514620,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kumuram Bheem Asifabad",
  "Name": "b jalapathi",
  "Mobile": 9966886152,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kumuram Bheem Asifabad",
  "Name": "b jalapathi",
  "Mobile": 919966886152,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Suryapet",
  "Name": "bollikondaujvini",
  "Mobile": 919010886610,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Medak",
  "Name": "ganesh",
  "Mobile": 16026096749,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bhadradri Kothagudem",
  "Name": "gollapalli rambabu",
  "Mobile": 917075027304,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Wanaparthy",
  "Name": "gouragalla mahabub",
  "Mobile": 919440305280,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Suryapet",
  "Name": "j mounika",
  "Mobile": 918309360235,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nirmal",
  "Name": "jadi mahesh",
  "Mobile": 8186089243,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mulugu",
  "Name": "k vinod",
  "Mobile": 919014395293,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Khammam",
  "Name": "kadari usha",
  "Mobile": 917659934007,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nirmal",
  "Name": "kademma sathish",
  "Mobile": 917680848745,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Warangal Urban",
  "Name": "khathamanjula",
  "Mobile": 919154470552,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Mahabubabad",
  "Name": "kishan tejavath",
  "Mobile": 9491532471,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "kuree sadhana",
  "Mobile": 918019552278,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Adilabad",
  "Name": "m roja",
  "Mobile": 917569780874,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sangareddy",
  "Name": "madiga yadaiah",
  "Mobile": 6304547619,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Suryapet",
  "Name": "malsur",
  "Mobile": 918125247728,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Karimnagar",
  "Name": "mavunuri srinivas",
  "Mobile": 919490494658,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nalgonda",
  "Name": "mdniranjan",
  "Mobile": 918096493834,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Medak",
  "Name": "mohammad arif",
  "Mobile": 917729822197,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Warangal Urban",
  "Name": "mohan",
  "Mobile": 919502481562,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mancherial",
  "Name": "my name is shiva",
  "Mobile": 919494757812,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Siddipet",
  "Name": "p sai",
  "Mobile": 919391211098,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mahabubnagar",
  "Name": "s anjilaiah",
  "Mobile": 919963697713,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "sadhana kuree",
  "Mobile": 8019552278,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nizamabad",
  "Name": "saikumar",
  "Mobile": 971563007746,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Peddapalli",
  "Name": "samala anwesh",
  "Mobile": 919492398479,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Medchal Malkajgiri",
  "Name": "sanjay newar",
  "Mobile": 917981950381,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Khammam",
  "Name": "sattupalli",
  "Mobile": 919381858415,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Khammam",
  "Name": "shaik bodulu",
  "Mobile": 919121616147,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nagarkurnool",
  "Name": "shekarsabavath",
  "Mobile": 917815863142,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hyderabad",
  "Name": "swathi",
  "Mobile": 918309090701,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Rajanna Sircilla",
  "Name": "tharre ganesh",
  "Mobile": 917569257898,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Warangal Rural",
  "Name": "varanasibixapathi",
  "Mobile": 9701296479,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Mancherial",
  "Name": "vennampalli manoher",
  "Mobile": 918096507508,
  "State": "Telangana",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Daman",
  "Name": "kishor newar",
  "Mobile": 918761034774,
  "State": "The Dadra And Nagar Haveli And Daman And Diu",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Etawah",
  "Name": "abhishek singh",
  "Mobile": 918530118819,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Prayagraj",
  "Name": "amit",
  "Mobile": 919695429517,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Ballia",
  "Name": "anchal kashyap",
  "Mobile": 917388508531,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ANIL KUMAR AGRAHARI",
  "Mobile": 7668353940,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Amroha",
  "Name": "arun kumar",
  "Mobile": 919622447594,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Banda",
  "Name": "arvind kumar",
  "Mobile": 919005065137,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "BAHAUDDIN SIDDIQUI",
  "Mobile": 9118251495,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "BHANU PATEL",
  "Mobile": 7376344386,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "chahat tiwari",
  "Mobile": 917589279887,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pilibhit",
  "Name": "chandra dev",
  "Mobile": 919457431205,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Chandrabhan singh",
  "Mobile": 8318993764,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "chandrkesh",
  "Mobile": 918247046209,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Shahjahanpur",
  "Name": "devnandan",
  "Mobile": 918115855728,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Barabanki",
  "Name": "dhananjai singh",
  "Mobile": 917007239835,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Amroha",
  "Name": "dheeraj kumar",
  "Mobile": 918700932421,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Auraiya",
  "Name": "dheeraj singh",
  "Mobile": 918218389853,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "DHIRENDRA DUTT SINGH",
  "Mobile": 9839182341,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "GHANSHYAM DAS JAISWAL",
  "Mobile": 9919976363,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ghurau Singh",
  "Mobile": 7268047116,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Gokul Rastogi",
  "Mobile": 8183665202,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "GOVIND GUPTA",
  "Mobile": 8175908623,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Gurpreet Singh",
  "Mobile": 9739173023,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Haridas jaiswal",
  "Mobile": 9451609946,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "hoshiyar singh",
  "Mobile": 919410476085,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Azamgarh",
  "Name": "indrajeet ram",
  "Mobile": 919569443669,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Mirzapur",
  "Name": "jabbar ali",
  "Mobile": 919076153725,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Barabanki",
  "Name": "jai",
  "Mobile": 918318676308,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Sambhal",
  "Name": "jaikrit",
  "Mobile": 917830454150,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "JITENDRA KUMAR BHARTI",
  "Mobile": 7376457834,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Jivacch lal",
  "Mobile": 9771424625,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Kailash yadav",
  "Mobile": 9005617773,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Kanhaiya jaiswal",
  "Mobile": 9935355505,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "kishan yadav",
  "Mobile": 7369188544,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "KRISHNA KANT DUBEY",
  "Mobile": 8004554504,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Kuldeep singh",
  "Mobile": 9264977484,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Lallan Prasad",
  "Mobile": 8250499393,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "MANOJ KUMAR RAJAK",
  "Mobile": 9430866127,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Prayagraj",
  "Name": "manorama patel",
  "Mobile": 919569971138,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Md. Alam",
  "Mobile": 9492424471,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "nandini gupta",
  "Mobile": 919670233383,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Hardoi",
  "Name": "narendra kumar",
  "Mobile": 917392012634,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bulandshahr",
  "Name": "narendra kumar kathera",
  "Mobile": 919675052714,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Nitin Kumar",
  "Mobile": 9837983320,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "PARAS NATH PATEL",
  "Mobile": 9889969154,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Shamli",
  "Name": "parveen kumar",
  "Mobile": 7895565656,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "peer mohammad",
  "Mobile": 917302153109,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Lalitpur",
  "Name": "pradumn",
  "Mobile": 917523998360,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Mau",
  "Name": "prakash bhushan",
  "Mobile": 919598458546,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Pratapgarh",
  "Name": "prakash singh",
  "Mobile": 919372412135,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Aligarh",
  "Name": "puja",
  "Mobile": 917668513688,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Rampur",
  "Name": "pushpendra singh",
  "Mobile": 917830725233,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bareilly",
  "Name": "pushpendra singh",
  "Mobile": 919555748906,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Baghpat",
  "Name": "rahul",
  "Mobile": 917536054553,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Rahul Vishwakarma",
  "Mobile": 9696553525,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJ KUMAR YADAV",
  "Mobile": 9506410411,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "raja das",
  "Mobile": 917607807366,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "RAJEAH KALIA",
  "Mobile": 9792119000,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Etawah",
  "Name": "rajeev kumar",
  "Mobile": 918171101061,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "RAJESH KUMAR",
  "Mobile": 7992161812,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rajesh Kumar",
  "Mobile": 8861896150,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gonda",
  "Name": "rajesh kumar",
  "Mobile": 918081477757,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rajesh Kumar shah",
  "Mobile": 8789427650,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Farrukhabad",
  "Name": "rajiv kumar",
  "Mobile": 919936291075,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Gorakhpur",
  "Name": "raju",
  "Mobile": 918447999288,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh keshri",
  "Mobile": 9336071326,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ram aashre sonkar",
  "Mobile": 9554143423,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hardoi",
  "Name": "ram jee",
  "Mobile": 9721372144,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ram Karan",
  "Mobile": 9839153907,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ram preet yadav",
  "Mobile": 919993396631,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ramesh chaurasia",
  "Mobile": 9821723806,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "ramesh Shah",
  "Mobile": 8585310040,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Rameshwar yadav",
  "Mobile": 7379535455,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Unnao",
  "Name": "ramji",
  "Mobile": 9588107493,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Ramlal singh",
  "Mobile": 9415980984,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SACHIN GUPTA",
  "Mobile": 9005814165,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Etah",
  "Name": "sandeep kumar",
  "Mobile": 918527566020,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "sanjay kumar",
  "Mobile": 8809294360,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Kheri",
  "Name": "sanjay verma",
  "Mobile": 919336289736,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "santosh sonkar",
  "Mobile": 9935876490,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bareilly",
  "Name": "satya dev",
  "Mobile": 9639983248,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Pratapgarh",
  "Name": "saurabh pandey",
  "Mobile": 918840645027,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sonbhadra",
  "Name": "shadab alam",
  "Mobile": 916393823760,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Shahansha",
  "Mobile": 8840332679,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Shahanshah husain",
  "Mobile": 9415293987,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Agra",
  "Name": "shashi kumar",
  "Mobile": 917668425941,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "shiv prakash",
  "Mobile": 7071652721,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SHOBH NATH YADAV",
  "Mobile": 9793089902,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Jalaun",
  "Name": "shyamkishor rathaur",
  "Mobile": 9685646598,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "SUBEDAR RAO",
  "Mobile": 9935039211,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Muzaffarnagar",
  "Name": "sudhir kumar",
  "Mobile": 917088464676,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "suman",
  "Mobile": 918448177930,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Jaunpur",
  "Name": "suraj",
  "Mobile": 917408702034,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Sureah  chandra  trivedi",
  "Mobile": 9532734098,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Ghaziabad",
  "Name": "swati sharma",
  "Mobile": 918630318223,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "TAUQEERUDDIN",
  "Mobile": 8115691914,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Prayagraj",
  "Name": "udayraj ramkishun",
  "Mobile": 919554559783,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Uttpal Ujjain Singh",
  "Mobile": 9653185940,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Shahjahanpur",
  "Name": "vaseem khan",
  "Mobile": 919305869255,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "vidit narayan gupta",
  "Mobile": 9935549190,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "VIKASH DUBEY",
  "Mobile": 9616434548,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Vinay Kumar Dubey",
  "Mobile": 9794901027,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "VINOD KUMAR JAISWAL",
  "Mobile": 9307926775,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "Virendra kumar",
  "Mobile": 9415355755,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Virendra kumar",
  "Mobile": 9450024839,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Hardoi",
  "Name": "अतुल कुमार भारती",
  "Mobile": 919455723629,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Saharanpur",
  "Name": "जयवीर",
  "Mobile": 916397329613,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Varanasi",
  "Name": "दशरथ मौर्या",
  "Mobile": 919699804014,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Shahjahanpur",
  "Name": "नमाजुद्दीन",
  "Mobile": 919557293365,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Bulandshahr",
  "Name": "नितिन कुमार",
  "Mobile": 919634392091,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Saharanpur",
  "Name": "नीरज कुमार",
  "Mobile": 916397743720,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Budaun",
  "Name": "राहुल",
  "Mobile": 919389976536,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Sultanpur",
  "Name": "विजय कुमार",
  "Mobile": 918707820649,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Prayagraj",
  "Name": "विद्या काँत द्विवेदी",
  "Mobile": 919821320124,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Saharanpur",
  "Name": "विनित कुमार",
  "Mobile": 918859657924,
  "State": "Uttar pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Firozabad",
  "Name": "समीर",
  "Mobile": 918755326317,
  "State": "Uttar Pradesh",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Rudra Prayag",
  "Name": "himanshu chamola",
  "Mobile": 917247013008,
  "State": "Uttarakhand",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "NAINITAL",
  "Name": "rohit",
  "Mobile": 919568015435,
  "State": "Uttarakhand",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Alipurduar",
  "Name": "binoy roy",
  "Mobile": 919883897373,
  "State": "West bengal",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Kolkata",
  "Name": "chandrajyoti chakma",
  "Mobile": 917628058600,
  "State": "West bengal",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "24 Paraganas North",
  "Name": "jd",
  "Mobile": 917439570695,
  "State": "West bengal",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "24 Paraganas South",
  "Name": "sabyosachi halder",
  "Mobile": 917980875692,
  "State": "West bengal",
  "Skill": "Helper",
  "Company": "Tata Project"
 },
 {
  "District": "Nadia",
  "Name": "sahid ali mallick",
  "Mobile": 917063662404,
  "State": "West bengal",
  "Skill": "Helper",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "sahil kureshi",
  "Mobile": 8786491669,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "shashi bhusan singh",
  "Mobile": 9621730017,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Sant Kumar Yadav",
  "Mobile": 8934923101,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "gulab Chandra tiwari",
  "Mobile": 9320424423,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "brajesh singh",
  "Mobile": 9795009561,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "AJIT SINGH",
  "Mobile": 9552206586,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "shiv Shankar yadav",
  "Mobile": 8052808542,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "MUNNA LAL BIND",
  "Mobile": 9935954470,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "shayam narayan singh",
  "Mobile": 9795180055,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "satyendra narayan tiwari",
  "Mobile": 8738824415,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Narendra Singh",
  "Mobile": 8299089971,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Naveen shukla",
  "Mobile": 7007632154,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "vinod yadav",
  "Mobile": 9956102575,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Shiv dutt jha",
  "Mobile": 9450528279,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Sachin gupta",
  "Mobile": 9935298421,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ajay seth",
  "Mobile": 9554502046,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Indrapal yadav",
  "Mobile": 8005238475,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "ANJANI KUMAR VERMA",
  "Mobile": 9839184645,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Basuda nand tiwari",
  "Mobile": 7408589571,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Basuda nand tiwari",
  "Mobile": 7408589571,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Mansoor Ahmed",
  "Mobile": 7460855249,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Awadhes shukla",
  "Mobile": 8563256425,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Ansar Ahmed",
  "Mobile": 6985656985,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Barkat ali",
  "Mobile": 6989562356,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "TUFANI LAL SONKAR",
  "Mobile": 7309311725,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Shobhnath yadav",
  "Mobile": 8858458470,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Santosh singh",
  "Mobile": 9621228794,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY SRIVASTAV",
  "Mobile": 9451252393,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "TARUN RUPANI",
  "Mobile": 8687222801,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Awadhesh mishra",
  "Mobile": 9453058002,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Chandraka yadav",
  "Mobile": 7618905504,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "IRFAN ALI",
  "Mobile": 8423284401,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Ram ji jha",
  "Mobile": 9792667824,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Hemant Kumar Gupta",
  "Mobile": 9415684558,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "vijay sonkar",
  "Mobile": 9839325665,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "ANIL SINGH",
  "Mobile": 7237007602,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rajendra jaiswal",
  "Mobile": 7607376539,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Banarsi Rajbhar",
  "Mobile": 9839620264,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Krishna duvedi",
  "Mobile": 9415265350,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "RAJEAH KUMAR YADAV",
  "Mobile": 9415619854,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "SHYAM JI SINGH",
  "Mobile": 9044144167,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Santosh upadhya",
  "Mobile": 9161738695,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Raj kumar pal",
  "Mobile": 9005079859,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Shankar pandit",
  "Mobile": 9968377611,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SATYENDRA PRATAP SINGH",
  "Mobile": 9005413524,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Kamlesh panday",
  "Mobile": 8887999752,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SUJEET KUMAR DUBEY",
  "Mobile": 9044504242,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Shelendra singh",
  "Mobile": 9451726435,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Shukhdev mishra",
  "Mobile": 9839098690,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "ABDUL KALAM",
  "Mobile": 9794836275,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "MAHESH KUMAR",
  "Mobile": 8299563812,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "RAMSEWAK YADAV",
  "Mobile": 9532791719,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Babulal pal",
  "Mobile": 7071228177,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Awadhes verma",
  "Mobile": 9455342443,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Shripati Narayanan Vaidya",
  "Mobile": 9807537909,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "AWADHESH PRASAD",
  "Mobile": 9793187334,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ramachand pal",
  "Mobile": 8858831733,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Manohar verma",
  "Mobile": 9695387055,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Arvind kumar",
  "Mobile": 7524016821,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "saroj Singh",
  "Mobile": 9484848849,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "RAJ NATH YADAV",
  "Mobile": 7355927727,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Manoj kumar",
  "Mobile": 9934481641,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "NARSINGH PANDEY",
  "Mobile": 9415909772,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "UDEY PRATAP SINGH",
  "Mobile": 8210567276,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Mayank mishra",
  "Mobile": 9335213613,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Shah junaid Ahmed",
  "Mobile": 7499129656,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh chaubey",
  "Mobile": 9936440625,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Arun vishwakarma",
  "Mobile": 8318410045,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ramakant Singh",
  "Mobile": 8299725203,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "prakash Singh",
  "Mobile": 9452844500,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "PAWAN KUMAR TIWARI",
  "Mobile": 9919264064,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "RAKESH KUMAR SINGH",
  "Mobile": 9453309865,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rammilan Yadav",
  "Mobile": 9452439692,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Vinod vishkarma",
  "Mobile": 9323536187,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY KUMAR SINHA",
  "Mobile": 9264967934,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Ashok tripathi is",
  "Mobile": 9838878173,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "ASHISH KUMAR SINGH",
  "Mobile": 9935354000,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "JAI PRAKASH YADAV",
  "Mobile": 9984577444,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Bharat Sonkar",
  "Mobile": 8543891267,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "ABULAISH AHMAD",
  "Mobile": 9415863179,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "PRAMODE GUPTA",
  "Mobile": 9369233219,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Dharmendra gupta",
  "Mobile": 9839502541,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "RAKEAH KUMAR SINGH YADAV",
  "Mobile": 9415686211,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Krishna singh",
  "Mobile": 6386111150,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "DHARMENDRA KUMAR SINGH",
  "Mobile": 9452792930,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "RITESH SRIVASTAV",
  "Mobile": 9198572583,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SATYENDRA PRATAP SINGH",
  "Mobile": 9005413524,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Shelendra singh",
  "Mobile": 9451726435,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Rajesh Singh",
  "Mobile": 9450162878,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Suresh pal",
  "Mobile": 8739087938,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Virendra tiwari",
  "Mobile": 7510024945,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "mohd imroz anwar",
  "Mobile": 9838488623,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Brij mohan tiwari",
  "Mobile": 9935942707,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Dilip prajapati",
  "Mobile": 7398702273,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Gopi nath",
  "Mobile": 9532109442,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Surendra bahadur",
  "Mobile": 7084539674,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Ajay yadav",
  "Mobile": 9026069455,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Ganga dayal Singh",
  "Mobile": 8002055066,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Arvind kumar",
  "Mobile": 7524016821,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "INDU PRAKASH",
  "Mobile": 9919322533,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Manoj kumar",
  "Mobile": 9934481641,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "NARSINGH PANDEY",
  "Mobile": 9415909772,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "NAND LAL",
  "Mobile": 8726554503,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Anil kumar",
  "Mobile": 9793055100,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "VINOD KUMAR SINGH",
  "Mobile": 9451887034,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rammilan Yadav",
  "Mobile": 9452439692,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Vinod vishkarma",
  "Mobile": 9323536187,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "SANJAY KUMAR SINHA",
  "Mobile": 9264967934,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "ASHISH KUMAR SINGH",
  "Mobile": 9935354000,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Sanjay Singh",
  "Mobile": 9140048442,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Dhirendra mishra",
  "Mobile": 9335134782,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "ABULAISH AHMAD",
  "Mobile": 9415863179,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Sanjay Singh",
  "Mobile": 9140666711,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "ARVIND KUMAR UPADHYAY",
  "Mobile": 8858013395,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "AKHILESH KUMAR",
  "Mobile": 7379714147,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Vinod singh",
  "Mobile": 9532859572,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Ramakant Singh",
  "Mobile": 8299725203,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Ashwani rai",
  "Mobile": 8554517503,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Satish Gupta",
  "Mobile": 9415202593,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rajesh jaiswal",
  "Mobile": 7347717773,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "Arun Singh",
  "Mobile": 9415450707,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Jaynath Singh",
  "Mobile": 8005431630,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rajesh mishra",
  "Mobile": 9450183082,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "SHIV PRASAD",
  "Mobile": 9794464138,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "SHUKHRAM",
  "Mobile": 7752801383,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Nandlal",
  "Mobile": 9838020780,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "yogendra kumar",
  "Mobile": 7753995835,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "BIRJEAH KUMAR DUBEY",
  "Mobile": 8115138942,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Ramashre yadav",
  "Mobile": 8381843238,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Satyendra Pratap Singh",
  "Mobile": 9005413524,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Kamlesh Pandey",
  "Mobile": 8887999752,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Rajesh kumar",
  "Mobile": 9794862547,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "S B YADAV",
  "Mobile": 6306186492,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Rajendra pal",
  "Mobile": 8299811676,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Nageshwar panday",
  "Mobile": 9453985370,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "RAJEAH KUMAR",
  "Mobile": 9415371159,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Dinesh Singh",
  "Mobile": 9559101656,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "Umashankar Prasad",
  "Mobile": 9839304198,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "ANUP KUMAR SINGH",
  "Mobile": 9936656676,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "Rakesh Yadav",
  "Mobile": 7985462765,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Varanasi",
  "Name": "SHASHIKANT SINGH",
  "Mobile": 9451963759,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "SAMEER SINGH",
  "Mobile": 9161111173,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Chennai",
  "Name": "tharani",
  "Mobile": 919789945454,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup Metro",
  "Name": "srikanta sarma",
  "Mobile": 918133882248,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Mumbai",
  "Name": "baban kokare",
  "Mobile": 919702263677,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "mohidul islam mojumder",
  "Mobile": 966571684176,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "rajesh  m sharma",
  "Mobile": 917038621340,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "pranjol",
  "Mobile": 917210401742,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Tinsukia",
  "Name": "partha borphukan",
  "Mobile": 917002884054,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Bengaluru Urban",
  "Name": "sunil rai",
  "Mobile": 918951704282,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "New Delhi",
  "Name": "rakesh kumar",
  "Mobile": 919773736566,
  "State": "Delhi",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hazaribagh",
  "Name": "rahul kumar mishra",
  "Mobile": 918651700715,
  "State": "Jharkhand",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Jhalawar",
  "Name": "sunil sharma",
  "Mobile": 919770373033,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Sivasagar",
  "Name": "sir arun nath",
  "Mobile": 917399355530,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup Metro",
  "Name": "srikanta sarma",
  "Mobile": 918133882248,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Bhilwara",
  "Name": "‡§¶‡•Å‡§∞‡•ç‡§ó‡•á‡§∂ ‡§ï‡•Å‡§Æ‡•ç‡§π‡§æ‡§∞",
  "Mobile": 916367388365,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "mridul basumatary",
  "Mobile": 918072596615,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "mohidul islam mojumder",
  "Mobile": 966571684176,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Varanasi",
  "Name": "rajesh  m sharma",
  "Mobile": 917038621340,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Rae Bareli",
  "Name": "vinod kumar",
  "Mobile": 919319563694,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Darrang",
  "Name": "nitu kalita",
  "Mobile": 919101784316,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "roki thousen",
  "Mobile": 918638824472,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Sivasagar",
  "Name": "mukul moni kalita",
  "Mobile": 917002294230,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Tirunelveli",
  "Name": "ramesh",
  "Mobile": 601112362102,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Baksa",
  "Name": "arpan roy",
  "Mobile": 917578029833,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Mathura",
  "Name": "my name is satendra singh",
  "Mobile": 917906082003,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Darrang",
  "Name": "nitu kalita",
  "Mobile": 919101784316,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "roki thousen",
  "Mobile": 918638824472,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Jaipur",
  "Name": "‡§ï‡§æ‡§≤‡•Å ‡§∞‡§æ‡§Æ ‡§ú‡§æ‡§ü",
  "Mobile": 919352010033,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "jahangir hussain kabir",
  "Mobile": 916282131281,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Goalpara",
  "Name": "monmohan nath",
  "Mobile": 916002393435,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Shahjahanpur",
  "Name": "‡§ï‡§™‡§ø‡§≤ ‡§∂‡§∞‡•ç‡§Æ‡§æ",
  "Mobile": 918957376003,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Jorhat",
  "Name": "babu saikia",
  "Mobile": 917569265125,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Baksa",
  "Name": "arpan roy",
  "Mobile": 917578029833,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup Metro",
  "Name": "debeswar kalita",
  "Mobile": 917002829911,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "baharul islam",
  "Mobile": 9101063610,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Rewa",
  "Name": "samar",
  "Mobile": 919717998735,
  "State": "Madhya pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Lakhimpur",
  "Name": "pulin doley",
  "Mobile": 919365634844,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "mustofa nessar ahmed",
  "Mobile": 918486160776,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Jhunjhunu",
  "Name": "dileep singh",
  "Mobile": 918432328725,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Sonitpur",
  "Name": "goutam tamang",
  "Mobile": 916901897181,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nalbari",
  "Name": "diganta",
  "Mobile": 917989218829,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Karbi Anglong",
  "Name": "kumud basumatary",
  "Mobile": 919101435062,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "shibu biswakarma",
  "Mobile": 916003631081,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Biswanath",
  "Name": "monuj phukon",
  "Mobile": 9365024544,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Udalguri",
  "Name": "pranjoy baro",
  "Mobile": 918136083183,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "samir sinha",
  "Mobile": 919101166243,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nagaon",
  "Name": "krishna kanta boro",
  "Mobile": 918638610313,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup Metro",
  "Name": "sanjay mahanta",
  "Mobile": 919957672141,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "saurav kumar yadav",
  "Mobile": 919365805672,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "debanjan nag",
  "Mobile": 916901973219,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Maharajganj",
  "Name": "ashwani yadav",
  "Mobile": 918009192640,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dhemaji",
  "Name": "tulan panyang",
  "Mobile": 919864812925,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "goutam tamang",
  "Mobile": 916901897181,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "samsuddin khan",
  "Mobile": 919957653781,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Pratapgarh",
  "Name": "gopal kumar",
  "Mobile": 919131021348,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup",
  "Name": "biplab jyoti das",
  "Mobile": 919101937074,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "mahesh sassa",
  "Mobile": 917896941952,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dibrugarh",
  "Name": "pronab bor saikia",
  "Mobile": 919101255649,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Karimganj",
  "Name": "samir sinha",
  "Mobile": 919101166243,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Barpeta",
  "Name": "pranab baishya",
  "Mobile": 919864203620,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Jorhat",
  "Name": "nomal pegu",
  "Mobile": 917002938476,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nalbari",
  "Name": "rupam deka",
  "Mobile": 919508732635,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nagaon",
  "Name": "krishna kanta boro",
  "Mobile": 918638610313,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Marigaon",
  "Name": "jubair  ahmed",
  "Mobile": 919365795019,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Bongaigaon",
  "Name": "gautam roy",
  "Mobile": 917002284002,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Ludhiana",
  "Name": "karan",
  "Mobile": 916284386638,
  "State": "Punjab",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Muzaffarnagar",
  "Name": "‡§µ‡§ø‡§µ‡•á‡§ï ‡§ï‡•Å‡§Æ‡§æ‡§∞",
  "Mobile": 918273909299,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "ratneswar deka",
  "Mobile": 918011330303,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "sujoy paul",
  "Mobile": 917002014772,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dibrugarh",
  "Name": "anup kumbhakar",
  "Mobile": 919101353673,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Dhubri",
  "Name": "tomijuddi islam",
  "Mobile": 918590731966,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Tiruppur",
  "Name": "arunkumar k",
  "Mobile": 918344053152,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Sangli",
  "Name": "‡§µ‡§ø‡§ú‡§Ø ‡§∂‡§ø‡§Ç‡§¶‡•á",
  "Mobile": 919921524499,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Yavatmal",
  "Name": "nitesh madhav chattekar",
  "Mobile": 919503702001,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Darrang",
  "Name": "wajid ali",
  "Mobile": 919365165718,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup Metro",
  "Name": "ashu",
  "Mobile": 916900375808,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Hailakandi",
  "Name": "suvamangal malakar",
  "Mobile": 919577736304,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Vaishali",
  "Name": "kanchan kumari",
  "Mobile": 7482806018,
  "State": "Bihar",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "mainil haque",
  "Mobile": 918589812159,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "hemanta kumar nath",
  "Mobile": 919365692393,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Hojai",
  "Name": "arif uddin",
  "Mobile": 919327799072,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Ghaziabad",
  "Name": "vipin kumar",
  "Mobile": 917290801768,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "ripon nath",
  "Mobile": 917005039882,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "asar  uddin",
  "Mobile": 919629346360,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Jorhat",
  "Name": "ajit bora",
  "Mobile": 9707804550,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Tiruppur",
  "Name": "kumar",
  "Mobile": 917010147378,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "abdul  hannan  laskar",
  "Mobile": 916001842882,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "SONITPUR",
  "Name": "ardhendu das",
  "Mobile": 918133932773,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Tiruppur",
  "Name": "arunkumar k",
  "Mobile": 918344053152,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Sagar",
  "Name": "shubham kori",
  "Mobile": 918959018605,
  "State": "Madhya pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Marigaon",
  "Name": "sri dharmendra patar",
  "Mobile": 918099253719,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dharmapuri",
  "Name": "elagovan",
  "Mobile": 919092807899,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Darrang",
  "Name": "wajid ali",
  "Mobile": 919365165718,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "bapan",
  "Mobile": 916000078568,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Tinsukia",
  "Name": "banti deb",
  "Mobile": 918011275748,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "mainil haque",
  "Mobile": 918589812159,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Tumakuru",
  "Name": "hemanth",
  "Mobile": 919731142007,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "hemanta kumar nath",
  "Mobile": 919365692393,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hojai",
  "Name": "arif uddin",
  "Mobile": 919327799072,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Tirunelveli",
  "Name": "shankar",
  "Mobile": 919940039765,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "asar  uddin",
  "Mobile": 919629346360,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dhemaji",
  "Name": "sri bijit handique",
  "Mobile": 916002022969,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Pune",
  "Name": "akash patil",
  "Mobile": 917709563109,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "asad alom barbhuiya",
  "Mobile": 916000186860,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dibrugarh",
  "Name": "bikram",
  "Mobile": 919365687701,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Hojai",
  "Name": "sri sangit thapa",
  "Mobile": 919957911975,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dhubri",
  "Name": "manaj kumar roy",
  "Mobile": 916002034760,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Darrang",
  "Name": "bipul",
  "Mobile": 917498333157,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "alta hussain barbhuiya",
  "Mobile": 7849063513,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Hojai",
  "Name": "reshab das",
  "Mobile": 919594075593,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranjan saikia",
  "Mobile": 918011433467,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup Metro",
  "Name": "lokendra sharma",
  "Mobile": 919706837385,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Lakhimpur",
  "Name": "purnananda hazarika",
  "Mobile": 916001833255,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "hiren chandra das",
  "Mobile": 919859442078,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dhemaji",
  "Name": "subhash borgohain",
  "Mobile": 918638319933,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Bongaigaon",
  "Name": "nur alom",
  "Mobile": 7002670491,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "anil saikia",
  "Mobile": 918876196536,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sivasagar",
  "Name": "mohammad ali",
  "Mobile": 918011034381,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kohima",
  "Name": "mani mohan singhmahat",
  "Mobile": 919774276406,
  "State": "Nagaland",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dibrugarh",
  "Name": "bikram",
  "Mobile": 919365687701,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "alta hussain barbhuiya",
  "Mobile": 7849063513,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Hojai",
  "Name": "reshab das",
  "Mobile": 919594075593,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Marigaon",
  "Name": "jawahar lal nath",
  "Mobile": 919365416630,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Lakhimpur",
  "Name": "ranjan saikia",
  "Mobile": 918011433467,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Lakhimpur",
  "Name": "purnananda hazarika",
  "Mobile": 916001833255,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "dhananjoy konwar",
  "Mobile": 919476875330,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Golaghat",
  "Name": "anil saikia",
  "Mobile": 918876196536,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dibrugarh",
  "Name": "yam dorjee",
  "Mobile": 917575906677,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "NAGAON",
  "Name": "nabajyoti bora",
  "Mobile": 916000059936,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "KAMRUP",
  "Name": "swmdwn boro",
  "Mobile": 916003353498,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "NAGAON",
  "Name": "nabajyoti bora",
  "Mobile": 916000059936,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "swmdwn boro",
  "Mobile": 916003353498,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "SONITPUR",
  "Name": "kk",
  "Mobile": 918134946613,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Dhemaji",
  "Name": "tulan panyang",
  "Mobile": 919864812925,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "nobin chandra singja",
  "Mobile": 918812881245,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "baharul islam mazumder",
  "Mobile": 919954968596,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Suryapet",
  "Name": "gugulothu rambabu",
  "Mobile": 919666359290,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Marigaon",
  "Name": "tanjimul anchari",
  "Mobile": 916002789687,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "rukan uddin mazumder",
  "Mobile": 919706382846,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "sirajul islam mazumder",
  "Mobile": 917002965418,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Mirzapur",
  "Name": "manoj kumar",
  "Mobile": 917398720942,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nagaon",
  "Name": "dhameswr bora",
  "Mobile": 917575903531,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Gandhinagar",
  "Name": "avinash thakor",
  "Mobile": 918160498689,
  "State": "Gujarat",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "sahidul islam mazumder",
  "Mobile": 918133844837,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Golaghat",
  "Name": "pronob rajkhowa",
  "Mobile": 919101280448,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Dhemaji",
  "Name": "jitul chutia",
  "Mobile": 916002162930,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Charaideo",
  "Name": "lakshyajit boruah",
  "Mobile": 919859519342,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "tek bahadur rana",
  "Mobile": 918011498451,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Cachar",
  "Name": "baharul islam mazumder",
  "Mobile": 919954968596,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karbi Anglong",
  "Name": "sri sanjib daimary",
  "Mobile": 919101717901,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Baksa",
  "Name": "abangsha khungur boro",
  "Mobile": 919508125895,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "ajoy sharma",
  "Mobile": 919707879116,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Suryapet",
  "Name": "gugulothu rambabu",
  "Mobile": 919666359290,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup Metro",
  "Name": "ripun baruah",
  "Mobile": 919401375059,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "rukan uddin mazumder",
  "Mobile": 919706382846,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "sirajul islam mazumder",
  "Mobile": 917002965418,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nashik",
  "Name": "om",
  "Mobile": 919604366614,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kottayam",
  "Name": "nikhil mon kc",
  "Mobile": 8949593214,
  "State": "Kerala",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Nagaon",
  "Name": "dhameswr bora",
  "Mobile": 917575903531,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Alwar",
  "Name": "devender",
  "Mobile": 918708262635,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "pronob rajkhowa",
  "Mobile": 919101280448,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "dipangkar kalita",
  "Mobile": 6003828704,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "miton lal das",
  "Mobile": 918473892330,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Hyderabad",
  "Name": "g krishna",
  "Mobile": 919652766554,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Karimganj",
  "Name": "jamilhussain",
  "Mobile": 919435759358,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Karimganj",
  "Name": "neaj uddin",
  "Mobile": 917005902583,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Golaghat",
  "Name": "jubed ali",
  "Mobile": 919101413218,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nizamabad",
  "Name": "g krishna",
  "Mobile": 919652766554,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Nagaon",
  "Name": "sohidul haque",
  "Mobile": 918822661397,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Malappuram",
  "Name": "hafeel",
  "Mobile": 919995584696,
  "State": "Kerala",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Lakhimpur",
  "Name": "gobin  konwar",
  "Mobile": 8011509170,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Varanasi",
  "Name": "mahanta kumar",
  "Mobile": 916307312273,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "nur mehbur rahman",
  "Mobile": 919957074909,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "garjn",
  "Mobile": 916003575552,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Dibrugarh",
  "Name": "chintu borah",
  "Mobile": 916002008621,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "DARRANG",
  "Name": "sasanka deka",
  "Mobile": 919365270421,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Barpeta",
  "Name": "utpal roy",
  "Mobile": 917002484534,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Ariyalur",
  "Name": "prabhaharan",
  "Mobile": 919043542797,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "West Karbi Anglong",
  "Name": "garjn",
  "Mobile": 916003575552,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Ariyalur",
  "Name": "prabhaharan",
  "Mobile": 919043542797,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "GOALPARA",
  "Name": "minhar uddin",
  "Mobile": 916000569154,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "GOALPARA",
  "Name": "minhar uddin",
  "Mobile": 916000569154,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "sanjib barman",
  "Mobile": 919954314170,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Barpeta",
  "Name": "‡§™‡•Å‡§≤‡§ï ‡§¶‡§æ‡§∏",
  "Mobile": 916379373978,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Tuticorin",
  "Name": "muthu kumar",
  "Mobile": 918608769747,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Karimganj",
  "Name": "bibhas roy",
  "Mobile": 917576871399,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Nalbari",
  "Name": "sanjib barman",
  "Mobile": 919954314170,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Mumbai",
  "Name": "sushant gangan",
  "Mobile": 918652351409,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Tuticorin",
  "Name": "muthu kumar",
  "Mobile": 918608769747,
  "State": "Tamil nadu",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "West",
  "Name": "devender",
  "Mobile": 9716304528,
  "State": "Delhi",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Golaghat",
  "Name": "pranab saikia",
  "Mobile": 919864368985,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Chirang",
  "Name": "kanteswar basumatary",
  "Mobile": 916001822607,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "abu tarak laskar",
  "Mobile": 918638501382,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Mysuru",
  "Name": "raghavendra",
  "Mobile": 919845520216,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Krishna",
  "Name": "devi",
  "Mobile": 919989461414,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup",
  "Name": "santanu",
  "Mobile": 919706339768,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "CACHAR",
  "Name": "alok nath",
  "Mobile": 917577867293,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "North West",
  "Name": "moolchand",
  "Mobile": 8826410877,
  "State": "Delhi",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "CACHAR",
  "Name": "hakim hussain laskar",
  "Mobile": 918638955475,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "CACHAR",
  "Name": "hakim hussain laskar",
  "Mobile": 918638955475,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Nagaon",
  "Name": "nazir hussain",
  "Mobile": 919954905119,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "parimal chutia",
  "Mobile": 918011296215,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Mahabubabad",
  "Name": "paindla dinesh",
  "Mobile": 919848443940,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Karimganj",
  "Name": "parimal chutia",
  "Mobile": 918011296215,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Pune",
  "Name": "‡§∂‡§æ‡§π‡§∞‡•Å‡§ñ",
  "Mobile": 918208503686,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Pune",
  "Name": "‡§∂‡§æ‡§π‡§∞‡•Å‡§ñ",
  "Mobile": 918208503686,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Kamrup",
  "Name": "gagan saloi",
  "Mobile": 8876679398,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup",
  "Name": "biraj kumar saloi",
  "Mobile": 917002085143,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup",
  "Name": "gagan saloi",
  "Mobile": 8876679398,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "BOKARO",
  "Name": "ajay",
  "Mobile": 916205855276,
  "State": "Jharkhand",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Bengaluru Urban",
  "Name": "sunil rai",
  "Mobile": 918951704282,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Srikakulam",
  "Name": "balakrishna komarapu",
  "Mobile": 9182754655,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Bongaigaon",
  "Name": "mizanur rahman",
  "Mobile": 8750605085,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Baksa",
  "Name": "nayan das",
  "Mobile": 919365409594,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "nayanmoni",
  "Mobile": 916001323633,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dibrugarh",
  "Name": "ashish das",
  "Mobile": 919678456259,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "rabijul hoque barbhuiya",
  "Mobile": 919476951244,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dibrugarh",
  "Name": "ashish das",
  "Mobile": 919678456259,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "rabijul hoque barbhuiya",
  "Mobile": 919476951244,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "snreddy",
  "Mobile": 919640010001,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "asad alom barbhuiya",
  "Mobile": 916000186860,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Amritsar",
  "Name": "army sarbjit singh",
  "Mobile": 916280809018,
  "State": "Punjab",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Visakhapatanam",
  "Name": "ankam ganesh",
  "Mobile": 917396997787,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup Metro",
  "Name": "hii",
  "Mobile": 919101706915,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "snreddy",
  "Mobile": 919640010001,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Visakhapatanam",
  "Name": "ankam ganesh",
  "Mobile": 917396997787,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Udalguri",
  "Name": "dipak baro",
  "Mobile": 918011881410,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Mahabubnagar",
  "Name": "g.vijrnder",
  "Mobile": 917659044081,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Adilabad",
  "Name": "srikanth",
  "Mobile": 919949409969,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "imran hussain mazumder",
  "Mobile": 966508981540,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup",
  "Name": "simanta das",
  "Mobile": 919365274957,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Aligarh",
  "Name": "alok pundhir",
  "Mobile": 919927809928,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nalbari",
  "Name": "dharmendra talukdar",
  "Mobile": 918638492669,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Cachar",
  "Name": "parimal roy",
  "Mobile": 917636029569,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "PATAN",
  "Name": "rahul kumar jayantilal solanki",
  "Mobile": 918758618021,
  "State": "Gujarat",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "nageswara phani kumar yanamandra",
  "Mobile": 918978068728,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nalbari",
  "Name": "dharmendra talukdar",
  "Mobile": 918638492669,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Araria",
  "Name": "md dilshad",
  "Mobile": 919354184027,
  "State": "Bihar",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "tepuram saikia",
  "Mobile": 8133942503,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Araria",
  "Name": "md dilshad",
  "Mobile": 919354184027,
  "State": "Bihar",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Nagaon",
  "Name": "sanjib",
  "Mobile": 918638366454,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Marigaon",
  "Name": "pankaj deka",
  "Mobile": 919074014985,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kamrup Metro",
  "Name": "rubul",
  "Mobile": 918474826789,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nagaon",
  "Name": "sanjib",
  "Mobile": 918638366454,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Marigaon",
  "Name": "pankaj deka",
  "Mobile": 919074014985,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Goalpara",
  "Name": "rahul rabha",
  "Mobile": 916001816371,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Indore",
  "Name": "abhay kumar patel",
  "Mobile": 919109444254,
  "State": "Madhya pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Goalpara",
  "Name": "rahul rabha",
  "Mobile": 916001816371,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Hyderabad",
  "Name": "konduru baburao",
  "Mobile": 919492634833,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "kadir ali",
  "Mobile": 916282224614,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamrup Metro",
  "Name": "himanga panging",
  "Mobile": 917086456601,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Darrang",
  "Name": "sri rajen talukdar",
  "Mobile": 916001817857,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamareddy",
  "Name": "g krishna",
  "Mobile": 919642364758,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Nalbari",
  "Name": "rahul ali",
  "Mobile": 916901989921,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Udalguri",
  "Name": "muzamil hoque",
  "Mobile": 918473835939,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "MARIGAON",
  "Name": "rajib ali",
  "Mobile": 918876081013,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "GOLAGHAT",
  "Name": "sajal mandal",
  "Mobile": 918134945992,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "DIMA HASSO - NORTH CACHAR HILL",
  "Name": "p das",
  "Mobile": 917635886243,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Mahabubnagar",
  "Name": "g.vijrnder",
  "Mobile": 917659044081,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Goalpara",
  "Name": "saiful islam",
  "Mobile": 8723002962,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "mukul nath",
  "Mobile": 919476661308,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Ernakulam",
  "Name": "shefin a k",
  "Mobile": 919633421724,
  "State": "Kerala",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Pune",
  "Name": "akash lashkare",
  "Mobile": 919834419502,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Mysuru",
  "Name": "raghavendra",
  "Mobile": 919845520216,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "tanoy",
  "Mobile": 916281995801,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Thiruvananthapuram",
  "Name": "muhammad sameer a",
  "Mobile": 918921399853,
  "State": "Kerala",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "saurav sharma",
  "Mobile": 917289990183,
  "State": "Uttar pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Hojai",
  "Name": "mohibul islam",
  "Mobile": 917099140721,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Jorhat",
  "Name": "roctim jyoti kashyap",
  "Mobile": 6002563923,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Anantapur",
  "Name": "r pratap reddy",
  "Mobile": 919986421222,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Nalgonda",
  "Name": "rajashekar",
  "Mobile": 7981617794,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Biswanath",
  "Name": "pankaj rajbanshi",
  "Mobile": 918723052773,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "South Salmara Mancachar",
  "Name": "abdullah sheikh",
  "Mobile": 918011715651,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Biswanath",
  "Name": "pankaj rajbanshi",
  "Mobile": 918723052773,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "amir khan ali",
  "Mobile": 919101688878,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Ranga Reddy",
  "Name": "vijayakumar",
  "Mobile": 919703463474,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nagaon",
  "Name": "rahul mahanta",
  "Mobile": 918876359074,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "promud gogoi",
  "Mobile": 919101518531,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Jorhat",
  "Name": "biswa",
  "Mobile": 917002149323,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Golaghat",
  "Name": "mridul ghosh",
  "Mobile": 917002689447,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Tinsukia",
  "Name": "bikash baruah",
  "Mobile": 917002266984,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kalaburagi",
  "Name": "sharanakumar a patil",
  "Mobile": 919686931312,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Kamrup Metro",
  "Name": "numol bordoloi",
  "Mobile": 919706653802,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Amravati",
  "Name": "ajay",
  "Mobile": 919067180486,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Bokaro",
  "Name": "‡§∏‡§Ç‡§ú‡§Ø ‡§ï‡•Å‡§Æ‡§æ‡§∞",
  "Mobile": 918877890890,
  "State": "Jharkhand",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Golaghat",
  "Name": "debojit",
  "Mobile": 917550142595,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "DHEMAJI",
  "Name": "pulak boruah",
  "Mobile": 916900320342,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sonitpur",
  "Name": "mainul haque",
  "Mobile": 916238724881,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Sonitpur",
  "Name": "mainul haque",
  "Mobile": 916238724881,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kaithal",
  "Name": "karan singh",
  "Mobile": 9518155716,
  "State": "Haryana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "DHUBRI",
  "Name": "rejadul islam",
  "Mobile": 919126204771,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "TINSUKIA",
  "Name": "sahid hussain",
  "Mobile": 916001814867,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "parveen",
  "Mobile": 919560948442,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Nalbari",
  "Name": "dhruwjit rajabangshi",
  "Mobile": 918099005503,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kalaburagi",
  "Name": "veeranna",
  "Mobile": 919945400318,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "mahender reddy",
  "Mobile": 9553503324,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "UDALGURI",
  "Name": "rajen katowal",
  "Mobile": 918329408110,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "ghanasyam sahu",
  "Mobile": 916000811143,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "BARPETA",
  "Name": "sri jagadish rabha",
  "Mobile": 919979980124,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Nizamabad",
  "Name": "d hanuman nayak",
  "Mobile": 919912391789,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kaithal",
  "Name": "karan singh",
  "Mobile": 9518155716,
  "State": "Haryana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "LUDHIANA",
  "Name": "anil kumar",
  "Mobile": 917837712952,
  "State": "Punjab",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Cachar",
  "Name": "mampu das",
  "Mobile": 918471806422,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Mathura",
  "Name": "ajit gautam",
  "Mobile": 918958499194,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kalaburagi",
  "Name": "veeranna",
  "Mobile": 919945400318,
  "State": "Karnataka",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "vallamala matsyagiri",
  "Mobile": 8985380327,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "mahender reddy",
  "Mobile": 9553503324,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "BONGAIGAON",
  "Name": "sankar barman",
  "Mobile": 919864686009,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "HOOGHLY",
  "Name": "sandip singh",
  "Mobile": 919732111856,
  "State": "West Bengal",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "KARIMGANJ",
  "Name": "‡¶Ü‡¶∂‡ßÅ‡¶§‡ßã‡¶∑ ‡¶¶‡ßá‡¶¨‡¶®‡¶æ‡¶•",
  "Mobile": 6002668266,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Dhubri",
  "Name": "‡¶Ü‡¶ó‡¶Æ‡¶®‡ßÄ",
  "Mobile": 916900494970,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Bhilwara",
  "Name": "sudarshan",
  "Mobile": 918949518671,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "CHENNAI",
  "Name": "elumalai",
  "Mobile": 919789986620,
  "State": "Tamil Nadu",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Karimnagar",
  "Name": "tangellapelli murali mohan",
  "Mobile": 9032422860,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "KAMRUP",
  "Name": "biswajit sarma",
  "Mobile": 917086672110,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "TINSUKIA",
  "Name": "bikash baruah",
  "Mobile": 918471890709,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sultanpur",
  "Name": "nafeesurrahman khan",
  "Mobile": 966571964363,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Wanaparthy",
  "Name": "md jani",
  "Mobile": 919949111393,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Nalgonda",
  "Name": "kasula  maheshgoud",
  "Mobile": 917702220316,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "KARBI ANGLONG",
  "Name": "dharam tisso",
  "Mobile": 919744910138,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "LUDHIANA",
  "Name": "anil kumar verma",
  "Mobile": 917837712952,
  "State": "Punjab",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Dimapur",
  "Name": "james sema",
  "Mobile": 918131041080,
  "State": "Nagaland",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "TINSUKIA",
  "Name": "rohit mazumder",
  "Mobile": 916900357587,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Pune",
  "Name": "‡§∂‡§Ç‡§ï‡§∞",
  "Mobile": 919822134108,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "SONITPUR",
  "Name": "joy das",
  "Mobile": 918721920790,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "SONITPUR",
  "Name": "joi das",
  "Mobile": 918721920790,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "KRISHNA",
  "Name": "venkateswara rao",
  "Mobile": 919963656070,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Sangareddy",
  "Name": "suresh",
  "Mobile": 919912536690,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "bishnu kumar bora",
  "Mobile": 916900606074,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Visakhapatanam",
  "Name": "meesala satyam",
  "Mobile": 918555051449,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "UDALGURI",
  "Name": "jonash",
  "Mobile": 916002609964,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Visakhapatanam",
  "Name": "meesala satyam",
  "Mobile": 918555051449,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "s venkatesh",
  "Mobile": 9014078298,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Mahabubabad",
  "Name": "panuganti venkatesh",
  "Mobile": 919849288746,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "KAMRUP METROPOLITAN",
  "Name": "pranjal jyoti sarmah",
  "Mobile": 918135927314,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Mahabubabad",
  "Name": "panuganti venkatesh",
  "Mobile": 919849288746,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Pratapgarh",
  "Name": "sudheer yadav",
  "Mobile": 916392101518,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Pratapgarh",
  "Name": "my name is sudheer yadav",
  "Mobile": 916392101518,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "AHMEDABAD",
  "Name": "visabhai j desai",
  "Mobile": 918141637014,
  "State": "Gujarat",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "FARIDABAD",
  "Name": "mohit",
  "Mobile": 919718015448,
  "State": "Haryana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "UDALGURI",
  "Name": "sri jyotiprasad deka",
  "Mobile": 917035916473,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "durvesh",
  "Mobile": 919759024588,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Bharatpur",
  "Name": "naresh chand",
  "Mobile": 919672263626,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "narayan borah",
  "Mobile": 919678057662,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Kamareddy",
  "Name": "saptagiri",
  "Mobile": 9849743448,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "LAKHIMPUR",
  "Name": "narayan borah",
  "Mobile": 919678057662,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "ANANTHAPUR",
  "Name": "b rama krishna",
  "Mobile": 919642181201,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Mumbai",
  "Name": "‡§™‡•ç‡§∞‡§Æ‡•ã‡§¶ ‡§™‡•ç‡§∞‡§ï‡§æ‡§∂ ‡§ú‡§æ‡§ß‡§µ",
  "Mobile": "null",
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Bhojpur",
  "Name": "akash kumar singh",
  "Mobile": 918210472095,
  "State": "Bihar",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "North East",
  "Name": "manveer singh",
  "Mobile": 918860089239,
  "State": "Delhi",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "South",
  "Name": "bhoopal singh",
  "Mobile": 919953459414,
  "State": "Delhi",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "SONITPUR",
  "Name": "dimba saikia",
  "Mobile": 919854306665,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "VISAKHAPATNAM",
  "Name": "ch ramana",
  "Mobile": 919701975305,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "VISAKHAPATNAM",
  "Name": "ch ramana",
  "Mobile": 919701975305,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "VIZIANAGARAM",
  "Name": "chandaka srinu",
  "Mobile": 919346398611,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "KARIMGANJ",
  "Name": "priotosh namasudra",
  "Mobile": 916002474235,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "GUNTUR",
  "Name": "ramana",
  "Mobile": 918341414283,
  "State": "Andhra Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Kolhapur",
  "Name": "jayprakas patil",
  "Mobile": 8698915559,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Kolhapur",
  "Name": "jayprakas patil",
  "Mobile": 8698915559,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Ayodhya",
  "Name": "kashan",
  "Mobile": 918483922808,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "NAGAON",
  "Name": "nitai debnath",
  "Mobile": 919137028479,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "NAGAON",
  "Name": "nitai debnath",
  "Mobile": 919137028479,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karimganj",
  "Name": "shankar malakar",
  "Mobile": 917628887700,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "HAILAKANDI",
  "Name": "debangshu paul",
  "Mobile": 919365374070,
  "State": "Assam",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Hyderabad",
  "Name": "sampath",
  "Mobile": 917680034534,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Other"
 },
 {
  "District": "Karauli",
  "Name": "shokat ali khan",
  "Mobile": 917690098624,
  "State": "Rajasthan",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Gautam Buddha Nagar",
  "Name": "ajay chauhan",
  "Mobile": 919910978005,
  "State": "Uttar Pradesh",
  "Skill": "Driver",
  "Company": "Sodexo"
 },
 {
  "District": "Pune",
  "Name": "vaibhav vijay punekar",
  "Mobile": 917387637174,
  "State": "Maharashtra",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Sitamarhi",
  "Name": "amit kumar",
  "Mobile": 918700044157,
  "State": "Bihar",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 },
 {
  "District": "Warangal Urban",
  "Name": "sampath kumar",
  "Mobile": 918897835809,
  "State": "Telangana",
  "Skill": "Driver",
  "Company": "Fast Despatch logistics"
 }
]