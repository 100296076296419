import React from "react";
import "react-dropdown/style.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionSummary, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import "./SideDrawer.css";

const SideDrawer = (props) => {
  const history = useHistory();

  let links = [
    "mis",
    "shramik",
    "industry",
    "skillgap",
    "skilldevelopment",
    // 'job-postings'
    "shramiks-connected",
  ];

  return (
    <div className="nav2-main">
      <nav className="nav2-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flexStart",
            width: "11vw",
          }}
        >
          <div className="lockdown-input">
            {links.map((route, idx) => (
              <Accordion
                key={idx}
                className="hoverStyle"
                onClick={() => history.push(`/${route}`)}
                style={{
                  backgroundColor:
                    history.location.pathname === `/${route}`
                      ? "whitesmoke"
                      : "#20314d",
                  color:
                    history.location.pathname === `/${route}`
                      ? "#20314d"
                      : "whitesmoke",
                  borderRadius: 0,
                }}
              >
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="typo-content">
                    {
                      route === "skilldevelopment"
                        ? "SKILL DEVELOPMENT"
                        : route === "skillgap"
                        ? "SKILL GAP"
                        : route === "shramiks-connected"
                        ? "SHRAMIKS CONNECTED"
                        : route
                      // route === 'job-postings'
                      // ? 'JOB POSTING'
                      // : route
                    }
                  </Typography>
                </AccordionSummary>
              </Accordion>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flexEnd",
            width: "11vw",
            marginBottom: "15px",
          }}
        >
          <span
            style={{
              marginBottom: "10px",
              color: "whitesmoke",
              fontWeight: "bold",
            }}
          >
            Follow us
          </span>
          <div className="social-media">
            <span className="social-media_icon">
              <a
                href="https://www.facebook.com/SakshamTIFAC/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FacebookIcon
                  style={{
                    fill: "whitesmoke",
                    fontSize: "2em",
                  }}
                />{" "}
              </a>
            </span>
            <span className="social-media_icon">
              <a
                href="https://twitter.com/tifac4"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <TwitterIcon
                  style={{
                    fill: "whitesmoke",
                    fontSize: "2em",
                  }}
                />{" "}
              </a>
            </span>
          </div>

          <div className="email-icon">
            <EmailIcon style={{ fill: "whitesmoke", fontSize: "2em" }} />
            <span className="email-id">admin@sakshamtifac.org</span>
          </div>
        </div>
      </nav>
      <div className="nav2-main__contents">{props.children}</div>
    </div>
  );
};

const mapDispatchToprops = (dispatch) => ({});

export default React.memo(connect(null, mapDispatchToprops)(SideDrawer));
