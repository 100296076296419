export const setCountry = (value) => ({
  type: "SET_COUNTRY",
  payload: value,
});

export const setNavbarState = (value) => ({
  type: "SET_STATE",
  payload: value,
});

export const setNavbarCity = (value) => ({
  type: "SET_CITY",
  payload: value,
});

export const setCityList = (value) => ({
  type: "SET_CITYLIST",
  payload: value,
});

export const removeNavbarState = () => ({
  type: "REMOVE_STATE",
});

export const removeNavbarCity = () => ({
  type: "REMOVE_CITY",
});

export const removeCityList = () => ({
  type: "REMOVE_CITYLIST",
});

export const removeNavbarAll = () => ({
  type: "REMOVE_ALL",
});
