export const loginForm = {
  loginForm: {
    email: {
      name: "email",
      elementType: "input",
      elementConfig: {
        placeholder: "User Name",
        type: "email",
      },
      value: "",
      error: "Email Field is required",
      validation: {
        required: true,
        minLength: 2,
      },
      valid: false,
      touched: false,
    },
    password: {
      name: "password",
      elementType: "input",
      elementConfig: {
        placeholder: "Password",
        type: "password",
      },
      value: "",
      error: "",
      validation: {
        required: true,
        minLength: 2,
      },
      valid: false,
      touched: false,
    },
  },
};
