import axios from "axios";

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  if (sessionStorage.getItem("accessToken")) {
    var started = sessionStorage.getItem("started");
    var diff = Date.now() - started;

    if (diff >= 1000 * 60 * 40) {
      //var accessToken = sessionStorage.getItem("accessToken");
      // axios({
      //   method: "post",
      //   url: `${process.env.REACT_APP_BACKEND_URL}/refreshToken`,
      //   data: {
      //     refresh: refreshToken,
      //   },
      // })
      //   .then((response) => {
      //     //console.log(response.data.access);
      //     sessionStorage.setItem("token", response.data.access);
      //     sessionStorage.setItem("started", Date.now());
      //   })
      //   .catch((error) => {
      //     console.log("Something went wrong. Please try again later.");
      //   });
      return sessionStorage.getItem("accessToken") || null;
    } else {
      return sessionStorage.getItem("accessToken") || null;
    }
  } else {
    return;
  }
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  //sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("started");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  //sessionStorage.setItem("token", token);
  sessionStorage.setItem("accessToken", token.access);
  sessionStorage.setItem("started", Date.now());
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const checkGeoJson = async (selectedLevel) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_GIS_URL}/geojson`, {
      level: selectedLevel === "India" ? 1 : 2,
      thislevel: selectedLevel,
    });
    return res.data;
  } catch (err) {
    if (err.response.status === 404) {
      return Promise.reject(err);
    }
  }
};
