import React, { useMemo, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector, connect } from "react-redux";
import { InputLabel, MenuItem, Menu, Tooltip } from "@material-ui/core";
import AccountCircleRounded from "@material-ui/icons/AccountCircleRounded";
import { useLocation, useHistory } from "react-router-dom";
import logo from "../../../assets/img/Vector.svg";
import { getUser, removeUserSession } from "../../utils/Common";
import { getToken } from "../../utils/Common";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  setCountry,
  setNavbarState,
  setNavbarCity,
  setCityList,
  removeNavbarAll,
  removeNavbarCity,
  removeCityList,
} from "../../../redux/Navbar-Selection/navbarSelection.actions";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import "./Navbar.css";
import { customStyles } from "../../../dropdownConst";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxHeight: "120px",
    height: "120px",
  },
  appBar: {
    backgroundColor: "#20314d",
    padding: 0,
    // height: "100%",
    height: "90%",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  customTooltip: {
    color: "whitesmoke",
    maxWidth: 220,
    height: 20,
  },
}));

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [states, setStates] = useState(null);
  const classes = useStyles();
  const token = getToken();
  const {
    setCountry,
    setNavbarState,
    setNavbarCity,
    setCityList,
    removeNavbarCity,
    removeCityList,
  } = props;
  const { selectedState, selectedCity, selectedCountry, cityList } =
    useSelector((state) => state.navbarSelection);
  const location = useLocation();
  const history = useHistory();
  let checkPath = location.pathname;
  let path = checkPath === "/";
  const isIndustryOrShramik =
    checkPath === "/shramik"
      ? "Shramik"
      : checkPath === "/industry"
      ? "Industry"
      : null;
  const name = getUser();
  const userName = name ? name.charAt(0).toUpperCase() + name.slice(1) : "User";
  const [openToast, setOpenToast] = useState(false);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/State`,
          {
            level: 1,
            thislevel: selectedCountry,
            locale: "en",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // let stateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          // console.log(res.data);
          setStates(res.data);
          //console.log("State", res.data[0]);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    token && fetchStateList();
  }, [token, selectedCountry]);

  useEffect(() => {
    const fetchCity = () => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/District`,
          {
            level: 2,
            thislevel: selectedState,
            locale: "en",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // let cityList = res.data[0].map(function (item) {
          //   return { label: item, value: item };
          // });
          setCityList(res.data);
          //console.log("District", res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    token && selectedState && fetchCity();
  }, [selectedState, setCityList, token]);

  const handleLogout = () => {
    setAnchorEl(null);
    removeUserSession();
    props.removeNavbarAll();
    history.push("/");
  };

  const download = (data, name, type, extension) => {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${name}.${extension}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const downloadIndustryData = () => {
    setOpenToast(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/industry-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        if (res.status === 200) {
          // download(res.data, "Industry Data",`application/octet-stream`, 'xlsx');
          let date = new Date();
          download(
            res.data,
            "Industry Data " +
              date.toLocaleDateString() +
              " " +
              date.toLocaleTimeString(),
            `application/octet-stream`,
            "xlsx"
          );

          setOpenToast(false);
        } else if (res.status === 204) {
          setOpenToast(false);
          Swal.fire({
            icon: "info",
            text: "No Content Available",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      })
      .catch((err) => {
        setOpenToast(false);
        console.log(err.response);
        Swal.fire({
          icon: "info",
          text: "Something went wrong \n Please try again later",
          showConfirmButton: false,
          timer: 5000,
        });
      });
  };

  const downloadShramikData = () => {
    setOpenToast(true);
    axios
      .get(`${process.env.REACT_APP_SHRAMIK_DATA_URL}/shramik-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // download(res.data, "Shramik Data",`text/csv`,'csv')
          let date = new Date();
          download(
            res.data,
            "Shramik Data " +
              date.toLocaleDateString() +
              " " +
              date.toLocaleTimeString(),
            `text/csv`,
            "csv"
          );

          setOpenToast(false);
        } else if (res.status === 204) {
          setOpenToast(false);
          Swal.fire({
            icon: "info",
            text: "No Content Available",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      })
      .catch((err) => {
        setOpenToast(false);
        console.log(err.response);
        Swal.fire({
          icon: "info",
          text: "Something went wrong \n Please try again later",
          showConfirmButton: false,
          timer: 5000,
        });
      });
  };

  const handleCityChange = useCallback(
    (data) => {
      data ? setNavbarCity(data.value) : setNavbarCity("");
    },
    [setNavbarCity]
  );

  const handleStateChange = useCallback(
    (data) => {
      data ? setNavbarState(data.value) : setNavbarState("");
      removeNavbarCity();
      removeCityList();
    },
    [setNavbarState, removeNavbarCity, removeCityList]
  );

  const inputLabelStyle = useMemo(
    () => ({
      color: "white",
      marginTop: "10px",
      marginBottom: "2px",
      textAlign: "center",
      paddingRight: "20px",
      fontSize: "1rem",
    }),
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenToast(false);
  };
  // console.log(checkPath);
  return !path ? (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar className={classes.appBar}>
          <div
            className={classes.drawer}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="Sapio Analytics"
              style={{
                height: "80px",
                width: "11vw",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => {
                history.push("/mis");
              }}
            />
          </div>

          {!(checkPath === "/shramiks-connected" || checkPath === "/mis") && (
            <div className="navbar-items">
              <div className="navbar__selection">
                <div className="navbar-input-box">
                  <InputLabel id="select-state-label" style={inputLabelStyle}>
                    State/UT
                  </InputLabel>
                  <Select
                    name="select-state"
                    placeholder="Please Select"
                    options={states}
                    styles={customStyles}
                    value={
                      selectedState === ""
                        ? null
                        : { label: selectedState, value: selectedState }
                    }
                    // width={`${Math.max(150, 8 * selectedState.length + 200)}px`}
                    width="250px"
                    onChange={handleStateChange}
                    isClearable={selectedState !== "" ? true : false}
                    isDisabled={checkPath === "/mis" ? true : false}
                  />
                </div>
              </div>

              <div className="navbar__selection">
                <div className="navbar-input-box">
                  <InputLabel id="select-city-label" style={inputLabelStyle}>
                    District
                  </InputLabel>
                  <Select
                    name="select-city"
                    placeholder="Please Select"
                    value={
                      selectedCity === ""
                        ? null
                        : { value: selectedCity, label: selectedCity }
                    }
                    options={cityList}
                    onChange={handleCityChange}
                    isClearable={selectedCity ? true : false}
                    styles={customStyles}
                    width="250px"
                    // isDisabled={checkPath === "/mis" ? true : false}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="navbar-items-right">
            <div style={{ display: "inline-flex" }}>
              {isIndustryOrShramik && (
                <div>
                  <div
                    className="flex-col-center pointer"
                    style={{
                      height: "30px",
                      borderBottom: "2px solid whitesmoke",
                      marginRight: "10px",
                    }}
                    onClick={
                      isIndustryOrShramik === "Shramik"
                        ? downloadShramikData
                        : downloadIndustryData
                    }
                  >
                    <span style={{ display: "inline-flex" }}>
                      <span className="ws-nowrap">{`${isIndustryOrShramik} Data`}</span>

                      <Tooltip
                        classes={{ tooltip: classes.customTooltip }}
                        title={`Download ${isIndustryOrShramik} Data`}
                      >
                        <span className="cursor" style={{ marginLeft: "5px" }}>
                          <ArrowDownwardIcon
                            style={{ fill: "whitesmoke", fontSize: "1.5em" }}
                          />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={openToast}
                    onClose={handleClose}
                  >
                    <MuiAlert severity="info" variant="filled">
                      Download is in progress . . .
                    </MuiAlert>
                  </Snackbar>
                </div>
              )}
              {/* {isIndustryOrShramik === "Shramik" && (
                <div>
                  <div
                    className="flex-col-center pointer"
                    style={{
                      height: "30px",
                      borderBottom: "2px solid whitesmoke",
                    }}
                    // onClick={
                    //   DownloadShramikRawData
                    // }
                  >
                    <span style={{ display: "inline-flex" }}>
                      <span className="ws-nowrap">Shramik Raw Data</span>

                      <Tooltip
                        classes={{ tooltip: classes.customTooltip }}
                        title="Download Shramik Raw Data"
                      >
                        <span className="cursor" style={{ marginLeft: "5px" }}>
                          
                          <ArrowDownwardIcon style={{ fill: "whitesmoke" }} />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={openToast}
                    onClose={handleClose}
                    // autoHideDuration={6000}
                  >
                    <MuiAlert severity="info" variant="filled">
                      Download is in progress . . .
                    </MuiAlert>
                  </Snackbar>
                </div>
              )} */}
            </div>
            <div className="user_profile flex-col-center">
              <AccountCircleRounded
                className="user_logo"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{
                  height: "40px",
                }}
              />
              <span className="username">{userName}</span>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                /*style={{ top: "5vh" }}*/
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                getContentAnchorEl={null}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push("/mis");
                  }}
                  style={{ boxShadow: "none" }}
                >
                  Home
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>

        <div className="navbar-label">
          A project by TIFAC (an autonomous body of Department of Science &
          Technology, Govt. of India) in collaboration with M/s. Sapio Analytica
          Pvt. Ltd., Mumbai
        </div>
      </AppBar>
    </div>
  ) : null;
};

const mapDispatchToProps = (dispatch) => ({
  setCountry: (value) => dispatch(setCountry(value)),
  setNavbarState: (value) => dispatch(setNavbarState(value)),
  setNavbarCity: (value) => dispatch(setNavbarCity(value)),
  setCityList: (value) => dispatch(setCityList(value)),
  removeNavbarAll: () => dispatch(removeNavbarAll()),
  removeNavbarCity: () => dispatch(removeNavbarCity()),
  removeCityList: () => dispatch(removeCityList()),
});

export default React.memo(connect(null, mapDispatchToProps)(Navbar));
